
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import * as actions from '../actions';
import { Page, Card, ResourceList, Button, TextStyle, Avatar } from '@shopify/polaris';

class Promotions extends Component {

    state = { displayCustomerTags: false, displayOrderTags: false, redirect: false }

    componentWillMount() {
        this.props.fetchShop();
        this.props.fetchPromos();
    }

    handleRemovePromotion(id) {
        this.props.deletePromo(id);
        this.props.fetchPromos();
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={`/home${window.location.search}`} />)
        }
        return (
            <Page title="Promotions"
                breadcrumbs={[{ content: 'Configuration', url: `/configuration${window.location.search}` }]}>
                <Card sectioned>
                    <ResourceList
                        resourceName={{ singular: 'promotion', plural: 'promotions' }}
                        items={this.props.promos}
                        renderItem={(item) => {
                            const { id, name, code } = item;
                            const media = <Avatar customer size="medium" name={name} />;
                            const shortcutActions = id
                                ? [{ content: 'Remove', onAction: () => this.handleRemovePromotion(id) }]
                                : null;

                            return (
                                <ResourceList.Item
                                    id={id}
                                    url={`/promotion/${id}${window.location.search}`}
                                    media={media}
                                    accessibilityLabel={`View details for ${name}`}
                                    shortcutActions={shortcutActions}
                                    persistActions>
                                    <h3>
                                        <TextStyle variation="strong">{name}</TextStyle>
                                    </h3>
                                    <div>{code}</div>
                                </ResourceList.Item>
                            );
                        }}
                    />
                    <br />
                    <Button url={`/promotion${window.location.search}`}>Add new promotion</Button>
                </Card>
            </Page>
        );
    }
}

const mapStateToProps = ({ data, promos }) => {
    return {
        promos
    };
};

export default connect(mapStateToProps, actions)(Promotions);
