import { combineReducers } from "redux";

import preferences from "./preferencesReducer";
import syncCompleted from "./syncReducer";
import promos from "./promosReducer";
import shop from "./shopReducer";
import shops from "./shopsReducer";
import pick from "./pickReducer";
import packs from "./packsReducer";
import bundles from "./bundleReducer";
import error from "./errorReducer";
import subscriptionUrl from "./accountReducer";
import plan from "./planReducer";
import scope from "./scopeReducer";
import customLists from "./customListsReducer";
import colorSkuReducer from "./ColorSku.reducer";

export default combineReducers({
    colorSkuList: colorSkuReducer,
    error,
    preferences,
    syncCompleted,
    shop,
    shops,
    promos,
    pick,
    packs,
    bundles,
    subscriptionUrl,
    plan,
    scope,
    customLists,
});
