import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal, Stack, TextField } from '@shopify/polaris';
import { ListMajorMonotone } from '@shopify/polaris-icons';
import { addCustomList } from '../actions/custom-list.actions';

const PopupCustomList = ({ bulkSelected, updateCustomList, plan, fetchCustomLists, handleBulkSelecting }) => {
    const [customListName, setCustomListName] = useState('New Custom List');
    const [active, setActive] = useState(false);
    const button = useRef();
    const handleChange = useCallback(newValue => setCustomListName(newValue), []);
    const toggleModal = useCallback(() => setActive((active) => !active), []);
    const handleSave = async () => {
        setActive(!active);
        if (customListName === '') setCustomListName('New Custom List');
        const customListID = await addCustomList({
            content: customListName,
            disabled: false,
            keepInPickList: false,
            manual: true,
            custom: false,
        })();
        if (customListID) {
            bulkSelected.forEach(id => { updateCustomList(id, customListID) })
            handleBulkSelecting();
            fetchCustomLists(plan);
        }
    }

    return (
        <div>
            <div ref={button}>
                <Button icon={ListMajorMonotone} onClick={toggleModal}>Create Custom List</Button>
            </div>
            <Modal
                open={active}
                onClose={toggleModal}
            >
                <Modal.Section>
                    <Stack vertical>
                        <Stack.Item fill>
                            <TextField
                                label="List name"
                                value={customListName}
                                onChange={handleChange}
                                connectedRight={
                                    <Button primary onClick={handleSave}>Save</Button>
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default PopupCustomList;
