
import React, { Component } from 'react';
import { Redirect } from 'react-router'
import { PageActions, Page, RadioButton, Card, Checkbox, FormLayout, Stack } from '@shopify/polaris';
import { fetchPreferences, updatePreferences } from '../../actions';
import { SetupWizard } from './SetupWizard'
import { connect } from 'react-redux'

export class Exports extends Component {

    constructor(props) {
        super(props);
        this.props.fetchPreferences();
    }

    update() {

    }

    save() {
        this.props.updatePreferences({ exports: this.getExports() });
        this.setState({ redirect: true });
    }

    getExports() {
        return {
            ...(this.props.preferences != null && this.props.preferences.exports != null
                ? this.props.preferences.exports
                : SetupWizard.exportConfigurationPreset),
            ...(this.state || {})
        };
    }


    handleOrdersExportModeChange = (checked, ordersExportMode) => this.setState({ ordersExportMode }, this.update);
    handleDisplayCompanyChange = displayCompany => this.setState({ displayCompany }, this.update);
    handleDisplayContactChange = displayContact => {
        if (displayContact)
            this.setState({ displayContact, displayFirstName: false,displayLastName: false }, this.update)
        else this.setState({ displayContact }, this.update)
    }
    handleDisplayFirstNameChange = displayFirstName => this.setState({ displayFirstName }, this.update);
    handleDisplayLastNameChange = displayLastName => this.setState({ displayLastName }, this.update);
    handleDisplayCustomerEmailChange = displayCustomerEmail => this.setState({ displayCustomerEmail }, this.update);
    handleDisplayCustomerTagsChange = displayCustomerTags => this.setState({ displayCustomerTags }, this.update);
    handleDisplayOrderTagsChange = displayOrderTags => this.setState({ displayOrderTags }, this.update);

    handleDisplayProductPropertiesChange = displayProductProperties => this.setState({ displayProductProperties }, this.update);
    handleDisplayOrderPropertiesChange = displayOrderProperties => this.setState({ displayOrderProperties }, this.update);
    handleDisplayNotesChange = displayOrderNotes => this.setState({ displayOrderNotes }, this.update);
    handleDisplayShippingDetailsChange = displayShippingDetails => this.setState({ displayShippingDetails }, this.update);
    handleDisplayShippingDescriptionChange = displayShippingDescription => this.setState({ displayShippingDescription }, this.update);
    handleDisplayShippingPhoneChange = displayShippingPhone => this.setState({ displayShippingPhone }, this.update);

    handleDisplayAddressChange = displayAddress => {
        if (displayAddress)
            this.setState({ displayAddress, displayAddressCountry: false, displayAddressCity: false, displayAddressProvince: false, displayAddressZip: false, displayAddressStreet2: false }, this.update);
        else
            this.setState({ displayAddress }, this.update);
    }
    handleDisplayAddressCityChange = displayAddressCity => this.setState({ displayAddressCity }, this.update);
    handleDisplayAddressZipChange = displayAddressZip => this.setState({ displayAddressZip }, this.update);
    handleDisplayAddressStreet1Change = displayAddressStreet1 => this.setState({ displayAddressStreet1 }, this.update);
    handleDisplayAddressStreet2Change = displayAddressStreet2 => this.setState({ displayAddressStreet2 }, this.update);
    handleDisplayAddressProvinceChange = displayAddressProvince => this.setState({ displayAddressProvince }, this.update);
    handleDisplayAddressCountryChange = displayAddressCountry => this.setState({ displayAddressCountry }, this.update);

    handleDisplayItemChargeChange = displayItemCharge => this.setState({ displayItemCharge }, this.update);

    handleDisplayShippingAndTaxesChange = displayShippingAndTaxes => this.setState({ displayShippingAndTaxes }, this.update);
    handleDisplayTotalWeight = displayTotalWeight => this.setState({ displayTotalWeight }, this.update);

    handleDisplayOrderTotalChange = displayOrderTotal => this.setState({ displayOrderTotal }, this.update);
    handleDisplayOrderDateChange = displayOrderDate => this.setState({ displayOrderDate }, this.update);
    handleDisplayDiscountCode = displayDiscountCode => this.setState({ displayDiscountCode }, this.update);


    handleDisplaySummarySKUChange = displaySummarySKU => this.setState({ displaySummarySKU }, this.update);
    handleDisplaySummaryProductNameChange = displaySummaryProductName => this.setState({ displaySummaryProductName }, this.update);
    handleDisplaySummaryQuantityChange = displaySummaryQuantity => this.setState({ displaySummaryQuantity }, this.update);
    handleDisplaySummaryItemChargeChange = displaySummaryItemCharge => this.setState({ displaySummaryItemCharge }, this.update);


    render() {

        const exports = this.getExports();

        if (this.state && this.state.redirect) {
            return <Redirect to={`/configuration${window.location.search}`} />
        }

        return (
            <Page title="Export configuration"
                breadcrumbs={[{ content: 'Configuration', url: `/configuration${window.location.search}` }]}>
                <PageActions
                    primaryAction={{
                        content: 'Save',
                        onAction: () => this.save()
                    }}
                />
                <Card title="Choose how you want orders to be exported" sectioned>
                    <Stack vertical>
                        <RadioButton
                            label="One row per order"
                            helpText="One column added per line item (SKU or product name), with the value being the quantity."
                            checked={exports.ordersExportMode === 'order'}
                            id="order"
                            name="order"
                            onChange={this.handleOrdersExportModeChange}
                        />
                        <RadioButton
                            label="One row per line item"
                            helpText="Multiple rows per order, if properties enabled one column added per property name, with the value being the value of the property."
                            checked={exports.ordersExportMode === 'line_item'}
                            id="line_item"
                            name="line_item"
                            onChange={this.handleOrdersExportModeChange}
                        />
                    </Stack>
                </Card>
                <Card title="Order export display options" sectioned>
                    <FormLayout>
                        <FormLayout.Group>
                            <Checkbox
                                checked={true}
                                disabled={true}
                                label="Order number"
                            />
                            <Checkbox
                                checked={exports.displayContact}
                                onChange={this.handleDisplayContactChange}
                                label="Name"
                            />
                            <Checkbox
                                checked={exports.displayFirstName}
                                onChange={this.handleDisplayFirstNameChange}
                                label="First Name"
                                disabled={exports.displayContact ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayLastName}
                                onChange={this.handleDisplayLastNameChange}
                                label="Last Name"
                                disabled={exports.displayContact ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayCompany}
                                onChange={this.handleDisplayCompanyChange}
                                label="Company"
                            />
                            <Checkbox
                                checked={exports.displayCustomerEmail}
                                onChange={this.handleDisplayCustomerEmailChange}
                                label="Customer email"
                            />
                            <Checkbox
                                checked={exports.displayCustomerTags}
                                onChange={this.handleDisplayCustomerTagsChange}
                                label="Customer tags"
                            />
                            <Checkbox
                                checked={exports.displayOrderDate}
                                onChange={this.handleDisplayOrderDateChange}
                                label="Order date"
                            />
                            <Checkbox
                                checked={exports.displayOrderTags}
                                onChange={this.handleDisplayOrderTagsChange}
                                label="Order tags"
                            />
                            <Checkbox
                                checked={exports.displayProductProperties && exports.pickDisplayMode !== 'line_item'}
                                onChange={this.handleDisplayProductPropertiesChange}
                                label="Line item properties"
                                disabled={exports.ordersExportMode !== 'line_item'}
                            />
                            <Checkbox
                                checked={exports.displayOrderProperties}
                                onChange={this.handleDisplayOrderPropertiesChange}
                                label="Note attributes (order properties)"
                            />
                            <Checkbox
                                checked={exports.displayOrderNotes}
                                onChange={this.handleDisplayNotesChange}
                                label="Notes"
                            />
                            <Checkbox
                                checked={exports.displayShippingDetails}
                                onChange={this.handleDisplayShippingDetailsChange}
                                label="Shipping code"
                            />
                            <Checkbox
                                checked={exports.displayShippingDescription}
                                onChange={this.handleDisplayShippingDescriptionChange}
                                label="Shipping description"
                            />
                            <Checkbox
                                checked={exports.displayShippingPhone}
                                onChange={this.handleDisplayShippingPhoneChange}
                                label="Shipping Phone"
                            />
                            <Checkbox
                                checked={exports.displayAddress}
                                onChange={this.handleDisplayAddressChange}
                                label="Delivery address"
                            />
                            <Checkbox
                                checked={exports.displayAddressStreet1}
                                onChange={this.handleDisplayAddressStreet1Change}
                                label="Delivery Address Street 1"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayAddressStreet2}
                                onChange={this.handleDisplayAddressStreet2Change}
                                label="Delivery Address Street 2"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayAddressCity}
                                onChange={this.handleDisplayAddressCityChange}
                                label="Delivery city"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayAddressZip}
                                onChange={this.handleDisplayAddressZipChange}
                                label="Delivery zip code"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayAddressProvince}
                                onChange={this.handleDisplayAddressProvinceChange}
                                label="Delivery province"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayAddressCountry}
                                onChange={this.handleDisplayAddressCountryChange}
                                label="Delivery country"
                                disabled={exports.displayAddress ? true : false}
                            />
                            <Checkbox
                                checked={exports.displayItemCharge}
                                onChange={this.handleDisplayItemChargeChange}
                                label="Item charges"
                            />
                            <Checkbox
                                checked={exports.displayShippingAndTaxes}
                                onChange={this.handleDisplayShippingAndTaxesChange}
                                label="Shipping & taxes"
                            />
                            <Checkbox
                                checked={exports.displayOrderTotal}
                                onChange={this.handleDisplayOrderTotalChange}
                                label="Order total"
                            />
                            <Checkbox
                                checked={exports.displayTotalWeight}
                                onChange={this.handleDisplayTotalWeight}
                                label="Total weight"
                            />
                            <Checkbox
                                checked={exports.displayDiscountCode}
                                onChange={this.handleDisplayDiscountCode}
                                label="Discount code"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card>
                <Card title="Summary export options" sectioned>
                    <FormLayout>
                        <FormLayout.Group>
                            <Checkbox
                                checked={exports.displaySummarySKU}
                                onChange={this.handleDisplaySummarySKUChange}
                                label="SKU"
                            />
                            <Checkbox
                                checked={exports.displaySummaryProductName}
                                onChange={this.handleDisplaySummaryProductNameChange}
                                label="Product name"
                            />
                            <Checkbox
                                checked={exports.displaySummaryQuantity}
                                onChange={this.handleDisplaySummaryQuantityChange}
                                label="Quantity"
                            />
                            <Checkbox
                                checked={exports.displaySummaryItemCharge}
                                onChange={this.handleDisplaySummaryItemChargeChange}
                                label="Item charges"
                            />
                        </FormLayout.Group>
                    </FormLayout>
                </Card>
            </Page>
        )
    }
}

const mapStateToProps = ({ preferences }) => {
    return {
        preferences
    };
};

export default connect(mapStateToProps, { fetchPreferences, updatePreferences })(Exports);
