
import {
    FETCH_PICK,
    FETCH_ERROR,
    FETCH_PARAMETERS_ERROR,
    UPDATE_PICKED
} from "./types";

import * as request from 'request'

export const fetchPickList = () => async dispatch => {
    let headers = (process.env.NODE_ENV === 'development') ? {
        'test': 'true',
        'shop': sessionStorage.shopOrigin
    } : {
            'hmac': sessionStorage.hmac,
            'query': sessionStorage.query,
            'shop': sessionStorage.shopOrigin
        }

    request.get(`${process.env.REACT_APP_API_URL}/ppp/orders`, { headers }, (error, response, body) => {
        if (error) {
            console.error(error);
            dispatch({
                type: FETCH_ERROR,
                payload: { message: error }
            });
            return
        }
        if (response.statusCode !== 200) {
            if (response.statusCode === 401) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "No shop parameter provided", status: "no-shop" }
                });
                return
            }
            if (response.statusCode === 403) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "App must be loaded from within Shopify", status: 'failed-validation' }
                });
                return
            }
        }

        dispatch({
            type: FETCH_PICK,
            payload: JSON.parse(body)
        });

    })
};

export const updatePickedStatus = picked => async dispatch => {

    let headers = (process.env.NODE_ENV === 'development') ? {
        'test': 'true'
    } : {
            'hmac': sessionStorage.hmac,
            'query': sessionStorage.query,
            'shop': sessionStorage.shopOrigin
        }

    request.get(`${process.env.REACT_APP_API_URL}/ppp/order/${picked.id}/picked/${picked.status}`, { headers }, (error, response, body) => {

        if (error) {
            console.error(error);
            dispatch({
                type: FETCH_ERROR,
                payload: { message: error }
            });
            return
        }
        if (response.statusCode !== 200) {
            if (response.statusCode === 401) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "No shop parameter provided", status: "no-shop" }
                });
                return
            }
            if (response.statusCode === 403) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "App must be loaded from within Shopify", status: 'failed-validation' }
                });
                return
            }
        }
        dispatch({
            type: UPDATE_PICKED,
            payload: true
        });
    })
}
