import React, { Component } from 'react';
import { Page, Card, TextField, PageActions, FormLayout, Button, DataTable, Link, Banner, Layout, Checkbox } from '@shopify/polaris';
import { helpers } from '../helpers';
import { connect } from 'react-redux';
import { updateBundle, addBundle, fetchBundle } from '../actions';

export class Bundle extends Component {
	state = {
		id: '',
		name: '',
		code: '',
		includeBundleInPick: false,
		hideBundleItems: false,
		productValue: '',
        descriptionValue: '',
		quantityValue: '',
		products: [],
		bundleByProperty: false,
		properties: [],
		invalid: false,
		conditionInvalid: false,
		bundlePropertyName: '',
		bundlePropertyValue: '',
	};

	constructor(props) {
		super(props);
		this.addSubProduct = this.addSubProduct.bind(this);
		this.removeCondition = this.removeCondition.bind(this);
		this.addBundleProperty = this.addBundleProperty.bind(this);
	}

	UNSAFE_componentWillMount() {
		let pathParts = this.props.location.pathname.split('/');
		if (pathParts.length === 3) {
			let id = pathParts[2];
			this.props.fetchBundle(id);
			this.setState({ id });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.bundles !== nextProps.bundles) {
			this.setState(nextProps.bundles);
		}
	}

	handleProductChange = productValue => this.setState({ productValue });
	handleProductDescriptionChange = descriptionValue => this.setState({ descriptionValue });
	handleQuantityChange = quantityValue => this.setState({ quantityValue });
	handleNameChange = name => this.setState({ name });
	handleCodeChange = code => this.setState({ code });
	handleBundlePropertyNameChange = bundlePropertyName => this.setState({ bundlePropertyName });
	handleBundlePropertyValueChange = bundlePropertyValue => this.setState({ bundlePropertyValue });
	handleIncludeBundleInPickChange = includeBundleInPick => this.setState({ includeBundleInPick, hideBundleItems: false });
	handleHideBundleItemsChange = hideBundleItems => this.setState({ hideBundleItems });
	handleBundleByPropertyChange = bundleByProperty => this.setState({ bundleByProperty });

	productIsValid() {
		return this.state.quantityValue !== '' && this.state.productValue !== '';
	}

	addSubProduct() {
		if (!this.productIsValid()) {
			this.setState({ conditionInvalid: true });
			return;
		}
		this.setState(state => {
			const products = state.products.concat({
				id: helpers.generateId(),
				product: state.productValue,
                description: state.descriptionValue,
				quantity: state.quantityValue,
			});
			return { products };
		});
		this.setState({ conditionInvalid: false, productValue: '', quantityValue: '', descriptionValue: '' });
	}

	addBundleProperty() {
		const properties = this.state.properties;
		properties.push({ id: helpers.generateId(), name: this.state.bundlePropertyName, value: this.state.bundlePropertyValue });
		this.setState({ properties, bundlePropertyName: '', bundlePropertyValue: '' });
	}

	removeCondition(id) {
		let products = this.state.products.filter(c => c.id !== id);
		this.setState({ products });
	}

	getSubProductsForDisplay() {
		return this.state.products.map(product => [
			product.product.toString(),
			product.quantity.toString(),
			<Link onClick={() => this.removeCondition(product.id)}>Remove</Link>,
		]);
	}

	getPropertiesForDisplay() {
		return this.state.properties.map(property => [
			property.name.toString(),
			property.value.toString(),
			<Link onClick={() => this.removeProperty(property.id)}>Remove</Link>,
		]);
	}

	removeProperty(id) {
		const properties = this.state.properties.filter(c => c.id !== id);
		this.setState({ properties });
	}

	isValid() {
		return this.state.name !== '' && this.state.code !== '';
	}

	async save() {
		if (!this.isValid()) {
			this.setState({ invalid: true });
			return;
		}
		// Save
		if (this.state.id === '') {
			let id = await this.props.addBundle({
				name: this.state.name,
				code: this.state.code,
				includeBundleInPick: this.state.includeBundleInPick,
				bundleByProperty: this.state.bundleByProperty,
				properties: this.state.properties,
				products: this.state.products,
				hideBundleItems: this.state.hideBundleItems,
			});
			this.setState({ id });
			this.props.history.push(`/bundle/${id}`);
		} else {
			await this.props.updateBundle({
				id: this.state.id,
				name: this.state.name,
				code: this.state.code,
				includeBundleInPick: this.state.includeBundleInPick,
				bundleByProperty: this.state.bundleByProperty,
				properties: this.state.properties,
				products: this.state.products,
				hideBundleItems: this.state.hideBundleItems,
			});
			this.props.history.push(`/packs`);
		}
	}

	render() {
		let dataTable;
		let propertiesDataTable;
		let conditionError;
		let error;
		let productRows = this.getSubProductsForDisplay();

		if (productRows.length > 0) {
			dataTable = (
				<DataTable
					columnContentTypes={['text', 'numeric', 'text']}
					headings={['Product', 'Quantity', '']}
					rows={productRows}
				/>
			);
		}
		if (this.state.properties.length) {
			const propertyRows = this.getPropertiesForDisplay();
			propertiesDataTable = (
				<DataTable columnContentTypes={['text', 'numeric', 'text']} headings={['Name', 'Value', '']} rows={propertyRows} />
			);
		}
		if (this.state.conditionInvalid) {
			conditionError = (
				<Banner title="Select product values" status="critical">
					<p>You must select a sku code and quantity to add a sub product to the bundle</p>
				</Banner>
			);
		}

		if (this.state.invalid) {
			error = (
				<Banner title="Bundle must have a name and a code" status="critical">
					<p>You must provide a name to identify the promotion and a SKU code for tagging and pick lists.</p>
				</Banner>
			);
		}
		const renderSubProducts =
			this.state.id === '' ? (
				''
			) : (
				<Layout.AnnotatedSection
					title="Sub-Products"
					description="These are the sub products that will be added to the pick when this bundle is purchased"
				>
					<Card sectioned>
						<FormLayout>
							{dataTable}
							{conditionError}
							<FormLayout.Group condensed>
								<TextField
									label="Product"
									id="product"
									onChange={this.handleProductChange}
									value={this.state.productValue}
									helpText="This doesn't need to be a real Shopify product, just something to identify what to pick."
								/>
								<TextField
									label="Description"
									id="description"
									onChange={this.handleProductDescriptionChange}
									value={this.state.descriptionValue}
									helpText="Optional name of the product to display in the pick list &amp; summary."
								/>
								<TextField
									label="Quantity"
									id="quantity"
									onChange={this.handleQuantityChange}
									value={this.state.quantityValue}
								/>
							</FormLayout.Group>
							<Button onClick={this.addSubProduct}>Add Product to Bundle</Button>
						</FormLayout>
					</Card>
				</Layout.AnnotatedSection>
			);
		return (
			<Page
				title="Bundle"
				breadcrumbs={[
					{ content: 'Pick', url: `/pick${window.location.search}` },
					{ content: 'Packs', url: `/packs${window.location.search}` },
				]}
			>
				<PageActions
					primaryAction={{
						content: 'Save',
						onClick: () => {
							this.save();
						},
					}}
				/>
				{error}
				<Layout>
					<Layout.AnnotatedSection title="Bundle settings" description="">
						<Card sectioned>
							<FormLayout>
								<TextField
									label="Bundle name"
									type="text"
									value={this.state.name}
									onChange={this.handleNameChange}
									helpText="This is used to identify the bundle in case you need to edit it later."
								/>
								<TextField
									label="Bundle SKU code"
									type="text"
									value={this.state.code}
									onChange={this.handleCodeChange}
									helpText="This is a SKU that a customer may purchase, a product/variant MUST have a SKU code set to be a bundle."
								/>
								<Checkbox
									checked={this.state.includeBundleInPick}
									onChange={this.handleIncludeBundleInPickChange}
									label="Include Bundle SKU in pick"
									id="includeBundleInPick"
									helpText="This is useful if the bundle rule is for 'bonus products' such as buy this, get this free"
								/>
								{this.state.includeBundleInPick ? (
									<Checkbox
										checked={this.state.hideBundleItems}
										onChange={this.handleHideBundleItemsChange}
										label="Hide Bundle items in the initial pick"
										id="hideBundleItems"
										helpText="Bundle items will be visible after clicking on the parent line"
									/>
								) : (
									''
								)}
							</FormLayout>
						</Card>
					</Layout.AnnotatedSection>

					<Layout.AnnotatedSection title="Properties settings" description="">
						<Card sectioned>
							<FormLayout>
								<Checkbox
									checked={this.state.bundleByProperty}
									onChange={this.handleBundleByPropertyChange}
									label="Trigger bundle based on item properties"
									id="bundleByProperty"
								/>
							</FormLayout>
							{this.state.bundleByProperty ? (
								<FormLayout>
									{propertiesDataTable}
									<FormLayout.Group condensed>
										<TextField
											label="Name"
											id="bundlePropertyName"
											onChange={this.handleBundlePropertyNameChange}
											value={this.state.bundlePropertyName}
										/>
										<TextField
											label="Value"
											id="bundlePropertyValue"
											onChange={this.handleBundlePropertyValueChange}
											value={this.state.bundlePropertyValue}
										/>
									</FormLayout.Group>
									<Button onClick={this.addBundleProperty}>Add Property Rule</Button>
								</FormLayout>
							) : (
								''
							)}
						</Card>
					</Layout.AnnotatedSection>
					{renderSubProducts}
				</Layout>
			</Page>
		);
	}
}

const mapStateToProps = ({ bundles }) => {
	return {
		bundles: bundles,
	};
};

export default connect(mapStateToProps, { updateBundle, addBundle, fetchBundle })(Bundle);
