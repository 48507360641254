import { bundlesRef } from "../config/firebase";
import {
    DELETE_BUNDLE,
    FETCH_BUNDLES,
    FETCH_BUNDLE
} from "./types";
import { helpers } from '../helpers'

export const deleteBundle = id => async dispatch => {
    bundlesRef().doc(id).delete().then(() => {
        dispatch({
            type: DELETE_BUNDLE
        });
    });
};

export const fetchBundles = () => async dispatch => {
    helpers.dispatchAllFromCollection(bundlesRef, FETCH_BUNDLES, dispatch)
};

export const fetchBundle = (id) => async dispatch => {
    bundlesRef().doc(id).get().then(snapshot => {
        dispatch({
            type: FETCH_BUNDLE,
            payload: snapshot.data()
        });
    })
};

export const addBundle = newBundle => async dispatch => {
    const snap = await bundlesRef().add(newBundle)
    return snap.id
};

export const updateBundle = bundle => async dispatch => {
    bundlesRef().doc(bundle.id).set(bundle)
};
