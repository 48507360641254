import React, { Component } from 'react';
import { Card, Subheading, Stack, Modal, Layout } from '@shopify/polaris';
import { PickCard } from '../pick/PickCard';
import { PickLine } from '../pick/PickLine';
import { PickLabel } from '../pick/PickLabel';
import { helpers } from '../../helpers';

export class Preview extends Component {
	state = {
		active: true,
		order: undefined,
		checked: false,
		quantity: 1,
	};

	fakeProductVariant = {
		tags: 'test tag, another test tag',
		image: {
			src: 'https://cdn.shopify.com/s/files/1/0551/3602/6789/products/Bottle_large_89be2713-fe0b-4299-93d4-985002398377.png?v=1617141442',
			alt: null,
			created_at: '2021-03-31T10:57:22+13:00',
			variant_ids: [30379791220791],
			updated_at: '2021-03-31T10:57:22+13:00',
			product_id: 6613787803813,
			admin_graphql_api_id: 'gid://shopify/ProductImage/28629912125605',
			width: 320,
			id: 28629912125605,
			position: 1,
			height: 480,
		},
		metafields: [],
		product_id: 6613787803813,
		variant_id: 30379791220791,
	};

	fakeOrder = {
		id: '171626345272342327',
		order_number: 1001,
		order_name: '#1001',
		name: 'Josh Bondy',
		company: 'CodeKiwi Limited',
		phone: '+1 334 234 2342342',
		pick_status: 'unpicked',
		financial_status: 'Paid',
		address: '12a sheffield st Coburg, Victoria',
		email: 'my-email@codekiwi.com',
		tags: 'This is an order tag',
		customerTags: 'This is a customer tag',
		count: 3,
		note: 'Customer wrote this note',
		note_attributes: [{ name: 'Custom Order attribute', value: 'Test' }],
		pick_list: [
			{
				variant_id: 30379791220791,
				quantity: 1,
				fulfillable_quantity: 1,
				sku: 'FAKESKU',
				properties: [{ name: "fake property", value: "value"}],
				price: '5.00',
				display: 'temp2',
				assigned_location: 'fake location',
				parent: '',
				title: {
					title_id: 30379791220791,
					sku: 'FAKESKU',
					title: 'My fake product',
					variant_title: '',
					barcode: 'FAKEBARCODE',
				},
			},
			{
				variant_id: 30379791220791,
				quantity: 1,
				fulfillable_quantity: 1,
				sku: 'AWESOMEPROD',
				properties: [],
				price: '15.00',
				display: 'temp',
				parent: '',
				title: {
					title_id: 303797912207912,
					sku: 'AWESOMEPROD',
					title: 'My fake product',
					variant_title: 'my variant',
					barcode: 'FAKEBARCODE2',
				},
			},
			{
				variant_id: 30379791220791,
				quantity: 1,
				fulfillable_quantity: 1,
				sku: '',
				properties: [],
				price: '15.00',
				display: 'temp',
				parent: '',
				assigned_location: 'fake location',
				title: {
					title_id: 303797912207912,
					sku: '',
					title: 'My missing SKU & BC',
					variant_title: '',
					barcode: '',
				},
			},
		],
		promotions: [],
		prepacked: [],
		date: '2019-10-09T13:59:08.000Z',
		shipping_address: {
			longitude: 144.9506052,
			country: 'Australia',
			country_code: 'AU',
			phone: null,
			province_code: 'VIC',
			zip: '3056',
			last_name: 'Bondy',
			address2: '',
			latitude: -37.7603256,
			name: 'Bondy',
			address1: '12a sheffield st',
			first_name: '',
			company: null,
			city: 'brunswick',
			province: 'Victoria',
		},
		shipping_code: 'Standard',
		total_price: '11.00',
		total_tax: '1.00',
		total_shipping: '5.00',
		locale: 'en',
		currency: 'AUD',
		custom_list: '',
		custom_lists: [],
	};

	constructor(props) {
		super(props);
		this.selectRow = this.selectRow.bind(this);
		if (props.preferences.metafields) {
			for (const metafield of props.preferences.metafields) {
				this.fakeProductVariant.metafields.push({
					...metafield, value: "TEST VALUE"
				})
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		console.log(this.props);
		if (this.props.preferences !== nextProps.preferences) {
			this.setState(nextProps.preferences);
			if (nextProps.preferences.metafields) {
				for (const metafield of nextProps.preferences.metafields) {
					this.fakeProductVariant.metafields.push({
						...metafield, value: "TEST VALUE"
					})
				}
				console.log(this.fakeProductVariant);
			}
		}
	}

	getFakeOrder() {
		this.fakeOrder.pick_list.forEach(item => {
			if (this.props.preferences) {
				item.display = helpers.getProductDisplayTitle(this.props.preferences, item);
			}
		});
		return this.fakeOrder;
	}

	getFakeProductVariant() {
		return this.fakeProductVariant;
	}

	selectRow(id) {
		this.setState({ selectedOrder: id });
	}

	handleChange = () => {
		this.setState(({ active }) => ({ active: false, showPreview: false }));
		this.props.onClose();
	};

	render() {
		const { active } = this.state;
		const renderPickList =
			this.props.preferences.pickDisplayMode === 'card' ? (
				<Layout>
					<Layout.Section>
						<PickCard
							id="cardpreview"
							updatePickedStatus={_ => {}}
							selectRow={this.selectRow}
							orders={[this.getFakeOrder()]}
							variants={[this.getFakeProductVariant()]}
							preferences={this.props.preferences}
							selectedOrder={this.state.selectedOrder}
							appliedFilters={[]}
							searchTerm={''}
							applyFilters={appliedFilters => {
								this.setState({ appliedFilters });
							}}
							setSearchTerm={searchTerm => {
								this.setState({ searchTerm });
							}}
							colorSkuList={{ colorSku: [] }}
						/>
					</Layout.Section>
				</Layout>
			) : this.props.preferences.pickDisplayMode === 'list' ? (
				<Card title="Pick" id="pickList">
					<PickLine
						id="listpreview"
						colorSkuList={{ colorSku: [] }}
						updatePickedStatus={_ => {}}
						selectRow={this.selectRow}
						orders={[this.getFakeOrder()]}
						variants={[this.getFakeProductVariant()]}
						preferences={this.props.preferences}
						selectedOrder={this.state.selectedOrder}
						appliedFilters={[]}
						searchTerm={''}
						applyFilters={appliedFilters => {
							this.setState({ appliedFilters });
						}}
						setSearchTerm={searchTerm => {
							this.setState({ searchTerm });
						}}
					/>
				</Card>
			) : (
				<Layout>
					<Layout.Section>
						<PickLabel
							id="cardpreview"
							updatePickedStatus={_ => {}}
							selectRow={this.selectRow}
							orders={[this.getFakeOrder()]}
							variants={[this.getFakeProductVariant()]}
							preferences={this.props.preferences}
							selectedOrder={this.state.selectedOrder}
							appliedFilters={[]}
							searchTerm={''}
							applyFilters={appliedFilters => {
								this.setState({ appliedFilters });
							}}
							setSearchTerm={searchTerm => {
								this.setState({ searchTerm });
							}}
						/>
					</Layout.Section>
				</Layout>
			);

		return (
			<Modal
				large
				open={active}
				onClose={this.handleChange}
				title="Pick list Preview"
				primaryAction={{
					content: 'Ok Great',
					onAction: this.handleChange,
				}}
			>
				<Modal.Section>
					<Layout>
						<Layout.Section>
							<Stack vertical>
								{renderPickList}
								<Subheading>This is an example of how an order will show in your pick list.</Subheading>
							</Stack>
						</Layout.Section>
					</Layout>
				</Modal.Section>
			</Modal>
		);
	}
}
