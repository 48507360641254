import { FETCH_BUNDLES, FETCH_BUNDLE } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_BUNDLES:
      return action.payload;
    case FETCH_BUNDLE:
      return action.payload;
    default:
      return state;
  }
};