import axios from 'axios'

export class ApiService {


    async get(resource, query = {}) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/ppp/${resource}`, {
            headers: this.getHeader(),
            params: query
        })
        return response.data;
    }

    getHeader() {
        return (process.env.NODE_ENV === 'development')
            ? {
                'test': 'true',
                'shop': sessionStorage.shopOrigin
            }
            : {
                'hmac': sessionStorage.hmac,
                'query': sessionStorage.query,
                'shop': sessionStorage.shopOrigin
            }
    }
}
