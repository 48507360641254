
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage'
// import { FirebaseConfig } from "../config/keys";

export const FirebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    projectId: process.env.REACT_APP_FIREBASE_ID,
    authDomain: `${process.env.REACT_APP_FIREBASE_ID}.firebaseapp.com`,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

firebase.initializeApp(FirebaseConfig);

//if (window.location.hostname === "localhost") {
//  firebase.firestore().useEmulator("localhost", 8080);
//}

// const settings = {/* your settings... */ timestampsInSnapshots: true };
// firestore.settings(settings)

const getShop = (allowImpersonate) => {
    if (!allowImpersonate) return sessionStorage.shopOrigin;
    if (!sessionStorage.impersonating || sessionStorage.impersonating === "false") return sessionStorage.shopOrigin;
    if (sessionStorage.shopOrigin === process.env.REACT_APP_TEST_SHOP) return sessionStorage.impersonatingShopOrigin;
    return sessionStorage.shopOrigin;
}
export const configRef = (allowImpersonate = true) => {
    const firestore = firebase.firestore();
    return sessionStorage.shopOrigin != null ? firestore.collection('configuration').doc(getShop(allowImpersonate)) : undefined;
}
export const promosRef = () => { return configRef().collection('promos') }
export const packsRef = () => { return configRef().collection('packs') }
export const bundlesRef = () => { return configRef().collection('bundles') }
export const logosRef = () => { return firebase.storage().ref('logos') }
export const customListsRef = () => { return configRef().collection('custom_lists') }
export const colorSkuRef = () => { return configRef().collection('colorSKU') }
