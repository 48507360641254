import { helpers } from '../helpers'
import { promosRef } from "../config/firebase";
import {
    DELETE_PROMO,
    FETCH_PROMOS,
    FETCH_PROMO
} from "./types";

export const addPromo = newPromo => async dispatch => {
    const ref = await promosRef().add(newPromo)
    return ref.id
};

export const deletePromo = id => async dispatch => {
    promosRef().doc(id).delete().then(() => {
        dispatch({
            type: DELETE_PROMO
        });
    });
};

export const updatePromo = promo => async dispatch => {
    promosRef().doc(promo.id).set(promo)
};

export const fetchPromos = () => async dispatch => {
    helpers.dispatchAllFromCollection(promosRef, FETCH_PROMOS, dispatch)
};

export const fetchPromo = (id) => async dispatch => {
    promosRef().doc(id).get().then(snapshot => {
        dispatch({
            type: FETCH_PROMO,
            payload: snapshot.data()
        });
    })
};
