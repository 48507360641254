export const FETCH_PREFERENCES = "FETCH_PREFERENCES";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const FETCH_SHOP = "FETCH_SHOP";
export const FETCH_SHOPS = "FETCH_SHOPS";
export const FETCH_PROMOS = "FETCH_PROMOS";
export const FETCH_PROMO = "FETCH_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";
export const FETCH_PLAN = "FETCH_PLAN";
export const FETCH_SUPPORT = "FETCH_SUPPORT";
export const FETCH_SUBSCRIBE_URL = "FETCH_SUBSCRIBE_URL";
export const FETCH_PICK = "FETCH_PICK";
export const UPDATE_PICKED = "UPDATE_PICKED";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_BUNDLES = "FETCH_BUNDLES";
export const FETCH_BUNDLE = "FETCH_BUNDLE";
export const DELETE_BUNDLE = "DELETE_BUNDLE";
export const FETCH_PACKS = "FETCH_PACKS";
export const FETCH_PACK = "FETCH_PACK";
export const DELETE_PACK = "DELETE_PACK";
export const FETCH_PARAMETERS_ERROR = "FETCH_PARAMETERS_ERROR";
export const SYNC_COMPLETED = "SYNC_COMPLETED";
export const SYNC_ERROR = "SYNC_ERROR";
export const IMPERSONATE_COMPLETED = "IMPERSONATE_COMPLETED";
export const FETCH_CUSTOM_LISTS = "FETCH_CUSTOM_LISTS";
export const DELETE_CUSTOM_LIST = "DELETE_CUSTOM_LIST";
export const FETCH_SCOPE = "FETCH_SCOPE";
export const FETCH_EXPORT = "FETCH_EXPORT";
