import React, { Component } from 'react';
import { Page, Card } from '@shopify/polaris';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class Contact extends Component {
	render() {
		return (
			<Page
				title="Contact"
				breadcrumbs={[{ content: 'Pick', url: `/pick${window.location.search}` }]}
			>
				<Card>
					<div className="contact">
						{/* <iframe frameBorder="none" className="zen_frame" title="Contact Form"
                            src="https://chat.zenquiries.com/contact_page?api_key=%242a%2410%24W12MOHgYLRQigmKCbTbIRuwpOjERel6a3XAypqUmpu36Xt6a86yli"></iframe> */}
						<script
							type="text/javascript"
							src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"
						></script>
						<style type="text/css" media="screen, projection">
							@import
							url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
						</style>
						<iframe
							title="Feedback Form"
							class="freshwidget-embedded-form"
							id="freshwidget-embedded-form"
							src="https://promoandgo.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No"
							scrolling="no"
							height="500px"
							width="100%"
							frameborder="0"
						></iframe>
					</div>
				</Card>
			</Page>
		);
	}
}
const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps, actions)(Contact);
