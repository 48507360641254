import { FETCH_ERROR, FETCH_SUBSCRIBE_URL } from "../actions/types";

export default (state = {}, action) => {
    switch (action.type) {
        case FETCH_ERROR:
        case FETCH_SUBSCRIBE_URL:
            return action.payload;
        default:
            return state;
    }
};
