
import { configRef, logosRef } from "../config/firebase";
import {
    FETCH_PREFERENCES,
    UPDATE_LOGO,
    SYNC_COMPLETED,
    SYNC_ERROR
} from "./types";
import * as request from 'request'

export const updatePreferences = preferences => async dispatch => {
    configRef().update(preferences)
};

export const syncLatest = _ => async dispatch => {

    let headers = (process.env.NODE_ENV === 'development') ? {
        'test': 'true',
        'shop': sessionStorage.shopOrigin
    } : {
            'hmac': sessionStorage.hmac,
            'query': sessionStorage.query,
            'shop': sessionStorage.shopOrigin
        }

    request.get(`${process.env.REACT_APP_API_URL}/ppp/orders/sync`, { headers }, (error, response) => {
        if (error) {
            console.error(error || response.statusCode !== 200);
            dispatch({
                type: SYNC_ERROR,
                payload: { message: error }
            });
            return
        }
        dispatch({
            type: SYNC_COMPLETED,
            payload: true
        });

    })
}

export const syncProducts = _ => async dispatch => {

    let headers = (process.env.NODE_ENV === 'development') ? {
        'test': 'true',
        'shop': sessionStorage.shopOrigin
    } : {
            'hmac': sessionStorage.hmac,
            'query': sessionStorage.query,
            'shop': sessionStorage.shopOrigin
        }

    request.get(`${process.env.REACT_APP_API_URL}/ppp/products/sync`, { headers }, (error, response) => {
        if (error) {
            console.error(error || response.statusCode !== 200);
            dispatch({
                type: SYNC_ERROR,
                payload: { message: error }
            });
            return
        }
        dispatch({
            type: SYNC_COMPLETED,
            payload: true
        });

    })
}

export const fetchPreferences = () => async dispatch => {
    configRef().get().then(snapshot => {
        let preferences = snapshot.data() || { displayOrderTags: true, displayCustomerTags: true, expectSkuCodes: true, pickDisplayMode: 'list' }
        return dispatch({
            type: FETCH_PREFERENCES,
            payload: preferences
        });
    })
}

export const updateLogo = file => async dispatch => {
    let logoImage = await logosRef().child(sessionStorage.shopOrigin)
    await logoImage.put(file)
    let url = await logoImage.getDownloadURL()
    return dispatch({
        type: UPDATE_LOGO,
        payload: url
    })
}
