import React, { Component } from 'react';
import { Button, Heading, Subheading, Banner, Stack, Modal, Layout, TextContainer } from '@shopify/polaris';
import { Redirect as RouterRedirect } from 'react-router'

export class Welcome extends Component {

    state = {
        active: true,
        order: undefined,
        checked: false,
        quantity: 1,
        contact: false
    };

    componentDidMount() {
        this.setState({
            order: this.props.order
        });
    }


    componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            // active: nextProps["active"],
            order: nextProps["order"]
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        // your condition if you want to re-render every time on props change
        return true;
    }

    render() {
        const { active } = this.state;

        if (this.state.contact) {
            return (
                <RouterRedirect to={`/contact${window.location.search}`} />
            )
        }
        return (
            <div>
                <Modal
                    large
                    open={active}
                    onClose={this.handleChange}
                    title="Welcome to Pick, Pack and Promo"
                    primaryAction={{
                        content: 'Ok Great',
                        onAction: this.handleChange,
                    }}
                    secondaryActions={[
                      {
                        content: 'Cancel',
                        onAction: this.handleChange,
                      },
                    ]}
                >
                    <Modal.Section>
                        <Layout>
                            <Layout.Section>
                                <Stack vertical>
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <iframe title="youtube" width="560" height="315" src="https://www.youtube.com/embed/kTZ9D_tCZmo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                    <Subheading>If you haven't done so yet, check out our demo video it showcases a bunch of our features and use cases.</Subheading>
                                    <Button onClick={this.handleWhatsNew}>Whats new!</Button>
                                    <Button onClick={() => this.setState({ contact: true })}>Get in touch</Button>
                                </Stack>
                            </Layout.Section>
                            <Layout.Section secondary>
                                <TextContainer>
                                <Heading>Congratulations on choosing the best Pick list for Shopify.</Heading>
                                <p>Head over to the preferences section to configure exactly how you want your pick list to appear. The two main modes are list and card, depending on how your packers process orders. Each list is optimized to be printed (although think about the trees).</p>
                                <p>If you have any questions or problems, hit the little chat button in the corner and we'll see if we can help you out.</p>
                                </TextContainer>
                                <br />
                            <Banner
                                title="Only active/unfulfilled orders will be displayed"
                                status="info"
                                onDismiss={() => { }}
                            >
                                <p>Orders will appear in PPP as they are placed in Shopify and disappear when they have been fulfilled. We are currently syncing any open orders while we distract you by reading this message.</p>
                            </Banner>
                            </Layout.Section>
                        </Layout>
                    </Modal.Section>
                </Modal>
            </div>
        );
    }

    handleChange = () => {
        this.setState(({ active }) => ({ active: false, showHelp: false }));
        this.props.closeModal()
    };
    handleWhatsNew = () => {
        this.setState(({ active }) => ({ active: false, showHelp: false, dismissedReleaseUpdate: false }));
        this.props.showReleaseUpdates()
    };
}
