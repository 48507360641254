import React, { Component } from "react";
import { RadioButton, Button, Stack, Modal, Layout } from "@shopify/polaris";
import { Preview } from "./Preview";
import { connect } from "react-redux";
import * as actions from "../../actions/index";

export class SetupWizard extends Component {
    constructor(props) {
        super(props);
        this.handleSavePreferences = this.handleSavePreferences.bind(this);
    }

    state = {
        active: true,
        showPreview: false,
        listType: "minimal",
        preferences: null,
    };

    componentDidMount() {
        this.setState({ preferences: SetupWizard.themePresets.minimal });
    }

    handleChange = (checked, listType) => this.setState({ listType, preferences: SetupWizard.themePresets[listType] });

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            // active: nextProps["active"],
            order: nextProps["closeModel"],
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // your condition if you want to re-render every time on props change
        return true;
    }

    async handleSavePreferences() {
        await this.props.updatePreferences(this.state.preferences);
        this.setState((active) => ({ active: false, showSetup: false }));
        this.props.closeModal(true);
    }

    render() {
        if (this.state.showPreview) return <Preview onClose={() => this.setState({ showPreview: false })} preferences={this.state.preferences} />;
        const { active } = this.state;
        return (
            <div>
                <Modal
                    large
                    open={active}
                    onClose={this.handleClose}
                    title="Configure how you want your pick list to display"
                    primaryAction={{
                        content: "Save these settings",
                        onAction: this.handleSavePreferences,
                    }}
                    secondaryActions={[
                        {
                            content: "Never mind, I`ll do it myself",
                            onAction: this.handleClose,
                        },
                    ]}
                >
                    <Modal.Section>
                        <Layout>
                            <Layout.Section>
                                <Stack vertical>
                                    <RadioButton label="Minimal list" helpText="Customers will only be able to check out as guests." checked={this.state.listType === "minimal"} id="minimal" name="minimal" onChange={this.handleChange} />
                                    <RadioButton
                                        label="Detailed list"
                                        helpText="Customers will be able to check out with a customer account or as a guest."
                                        id="detailed"
                                        name="detailed"
                                        checked={this.state.listType === "detailed"}
                                        onChange={this.handleChange}
                                    />
                                    <RadioButton
                                        label="Printable cards"
                                        helpText="Customers will be able to check out with a customer account or as a guest."
                                        id="card"
                                        name="card"
                                        checked={this.state.listType === "card"}
                                        onChange={this.handleChange}
                                    />
                                    <Button onClick={() => this.setState({ showPreview: true })}>Display a preview</Button>
                                </Stack>
                            </Layout.Section>
                        </Layout>
                    </Modal.Section>
                </Modal>
            </div>
        );
    }

    handleClose = () => {
        this.setState(({ active }) => ({ active: false, showSetup: false }));
        this.props.closeModal(false);
    };

    static exportConfigurationPreset = {
        ordersExportMode: "line_item",
        displayCompany: false,
        displayContact: true,
        displayCustomerTags: true,
        displayOrderTags: true,
        displayProductProperties: false,
        displayOrderProperties: false,
        displayOrderNotes: false,
        displayShippingDetails: false,
        displayShippingDescription: false,
        displayItemCharge: false,
        displayItemFullfillableQuantity: false,
        displayShippingAndTaxes: false,
        displayTotalWeight: false,
        displayOrderTotal: false,
        displayOrderDate: true,
        displayAddress: false,
        displayDiscountCode: false,
        displaySummarySKU: true,
        displaySummaryProductName: true,
        displaySummaryQuantity: true,
        displaySummaryItemCharge: false,
        displayPaymentStatus: false,
    };

    static themePresets = {
        minimal: {
            displayOrderNumber: false,
            displayCompany: false,
            displayContact: false,
            displayCustomerTags: false,
            displayOrderTags: false,
            displayProductProperties: false,
            displayOrderProperties: false,
            displayOrderNotes: false,
            displayOrderDate: false,
            displayShippingDetails: false,
            displayShippingDescription: false,
            displayItemCharge: false,
            displayItemFullfillableQuantity: false,
            displayOrderTotal: false,
            displayShippingAndTaxes: false,
            displayDiscountCode: false,
            displayOnAllOrders: true,
            pickDisplayMode: "list",
            displayAddress: false,
            displayPaymentStatus: false,
            displayFulfillmentLocations: false,
            displayFulfillmentLocation: false,
            orderMode: "pick",
            displayMode: "sku",
            expectSkuCodes: true,
            displayTotalWeight: false,
            labelLayout: "3",
            logo: "",
            headerText: "",
            footerText: "",
        },
        detailed: {
            displayOrderNumber: false,
            displayCompany: true,
            displayContact: true,
            displayCustomerTags: true,
            displayOrderTags: true,
            displayProductProperties: true,
            displayOrderProperties: true,
            displayOrderNotes: true,
            displayOrderDate: true,
            displayShippingDetails: false,
            displayShippingDescription: true,
            displayItemCharge: true,
            displayItemFullfillableQuantity: true,
            displayOrderTotal: true,
            displayShippingAndTaxes: true,
            displayOnAllOrders: true,
            displayTotalWeight: false,
            pickDisplayMode: "list",
            displayAddress: true,
            displayDiscountCode: false,
            displayPaymentStatus: false,
            displayFulfillmentLocations: false,
            displayFulfillmentLocation: false,
            orderMode: "pick",
            displayMode: "product",
            expectSkuCodes: true,
            labelLayout: "3",
            logo: "",
            headerText: "",
            footerText: "",
        },
        card: {
            displayOrderNumber: false,
            displayCompany: true,
            displayContact: true,
            displayCustomerTags: true,
            displayOrderTags: true,
            displayProductProperties: true,
            displayOrderProperties: true,
            displayOrderNotes: true,
            displayOrderDate: true,
            displayShippingDetails: true,
            displayShippingDescription: false,
            displayItemCharge: true,
            displayItemFullfillableQuantity: true,
            displayOrderTotal: true,
            displayShippingAndTaxes: true,
            displayOnAllOrders: true,
            displayTotalWeight: false,
            displayDiscountCode: false,
            displayPaymentStatus: false,
            displayFulfillmentLocations: false,
            displayFulfillmentLocation: false,
            pickDisplayMode: "card",
            displayAddress: false,
            labelLayout: "3",
            orderMode: "pick",
            displayMode: "product",
            expectSkuCodes: false,
            logo: "/images/logo.png",
            headerText: "This is some header text,\nMaybe your business address\nOr maybe some contact details",
            footerText: "Display some footer text here",
        },
    };
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, actions)(SetupWizard);
