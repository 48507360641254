import React, { Component } from 'react';
import { Button, Avatar } from '@shopify/polaris';
import CurrencyFormatter from '../CurrencyFormatter';

export class PickCardDataTable extends Component {
	state = {
		header: [],
		rows: [],
		isColorSkuEnabled: false,
	};

	constructor(props) {
		super(props);
		this.getHeadings = this.getHeadings.bind(this);
	}

	UNSAFE_componentWillMount() {
		const header = this.getHeadings();
		const rows = this.getOrderRows(this.props.pick_list, this.props.prepacked, this.props.colorSkuList);
		this.setState({ header, rows });
		if (this.props.colorSkuList && this.props.colorSkuList.colorSku && this.props.colorSkuList.colorSku.length > 0)
			this.setState({ isColorSkuEnabled: true });
		else this.setState({ isColorSkuEnabled: false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.colorSkuList !== nextProps.colorSkuList) {
			let isColorSkuEnabled = false;
			if (nextProps.colorSkuList && nextProps.colorSkuList.colorSku && nextProps.colorSkuList.colorSku.length > 0)
				isColorSkuEnabled = true;

			this.setState({ colorSkuList: isColorSkuEnabled });
		}
	}

	getHeadings() {
		const {
			productSupport,
			displayFulfillmentLocation,
			displayProductProperties,
			displayItemCharge,
			displayItemFullfillableQuantity,
			displayVariantImage,
			displayProductTags,
			displayMetafield,
			metafields,
		} = this.props.preferences;
		let result = ['IsParent', 'Hidden', 'SKU', 'Parent', 'Color'];

		if (productSupport && displayVariantImage) {
			result.push('');
		}

		result.push('Product');

		if (displayFulfillmentLocation) {
			result.push('Location');
		}

		if (displayItemCharge) {
			result.push('Price');
		}
		if (displayProductProperties) {
			result.push('Properties');
		}

		if (productSupport && displayProductTags) {
			result.push('Tags');
		}

		if (productSupport && displayMetafield !== null) {
			for (const display of Object.entries(displayMetafield || {})) {
				if (display[1] === true) {
					const metafield = metafields.find(
						x => x.namespace === display[0].split('.')[0] && x.key === display[0].split('.')[1]
					);
					if (metafield) {
						result.push(metafield.description || display[0]);
					}
				}
			}
		}

		if (displayItemFullfillableQuantity) {
			result.push('Fullfillable');
		}

		result.push('Quantity');
		if (displayItemCharge) {
			result.push('Net Sales');
		}
		return result;
	}

	getContentTypes() {
		const {
			productSupport,
			displayFulfillmentLocation,
			displayProductProperties,
			displayItemCharge,
			displayItemFullfillableQuantity,
			displayVariantImage,
			displayProductTags,
			displayMetafield,
		} = this.props.preferences;
		let result = ['boolean', 'boolean', 'text', 'text', 'text'];

		if (productSupport && displayVariantImage) {
			result.push('text');
		}

		result.push('text');
		if (displayFulfillmentLocation) {
			result.push('text');
		}
		if (displayItemCharge) {
			result.push('numeric');
		}
		if (displayProductProperties) {
			result.push('text');
		}

		if (productSupport && displayProductTags) {
			result.push('text');
		}

		if(productSupport){
			for (const display of Object.entries(displayMetafield || {})) {
				if (display[1] === true) result.push('text');
			}
		}

		if (displayItemFullfillableQuantity) {
			result.push('numeric');
		}
		result.push('numeric');
		if (displayItemCharge) {
			result.push('numeric');
		}
		return result;
	}

	formatCurrency(number) {
		if (number >= 0 && this.props.order.currency != null && this.props.order.currency.length > 0) {
			return (
				<CurrencyFormatter price={number} curr={this.props.order.currency} locale={this.props.order.locale} form={'short'} />
			);
		} else return number;
	}

	getOrderRows(items, prepacked, colorSkuList) {
		const {
			productSupport,
			displayFulfillmentLocation,
			displayProductProperties,
			displayItemCharge,
			displayItemFullfillableQuantity,
			displayVariantImage,
			displayProductTags,
			displayMetafield,
            includeImagesinPrint,
		} = this.props.preferences;
		let results = [];

		function assemble(arr) {
			const table = {};
			arr.sort((a, b) => {
				if (a.display > b.display) {
					return 1;
				}
				if (b.display > a.display) {
					return -1;
				}
				return 0;
			}).forEach(el => {
				if (!table[el.parent]) {
					table[el.parent] = [];
				}
				table[el.parent].push(el);
			});
			const result = [];
			const constructResult = key => {
				if (table[key]) {
					table[key].forEach(el => {
						result.push(el);
						const childKey = el.sku != null && el.sku.length ? el.sku : el.display;
						if (childKey.length) constructResult(childKey);
					});
				}
			};
			constructResult('');
			return result;
		}

		const itemsCopy = [...items];
		assemble(items).forEach(item => {
			const variant = (this.props.variants || []).find(x => x.variant_id === item.variant_id);
			// is it parent?
			const isParent = itemsCopy.find(o => o.parent && o.parent === item.sku) !== undefined;
			// find parent
			const findParent = itemsCopy.find(o => o.sku && o.sku === item.parent);
			// find color in colorSkuList
			const color =
				colorSkuList && colorSkuList.colorSku && item.sku !== null && item.sku !== undefined
					? colorSkuList.colorSku.find(o => o.sku === item.sku.toUpperCase())
					: null;
			let result = [];
			isParent ? result.push(true) : result.push(false);
			item.hideBundleItems ? result.push(true) : result.push(false);
			result.push(item.sku);
			result.push(item.parent);
			// if we found a color then we add it to the result table
			result.push(color !== undefined && color !== null ? color.color : '');
			const textDisplay = item.parent.length ? `- ${item.display}` : item.display;
			const textDisplayLayout = <span dangerouslySetInnerHTML={{ __html: textDisplay }} />;

			if (productSupport && displayVariantImage) {
				const image = variant !== undefined && variant.image !== null ? <div className={includeImagesinPrint ? "yesPrint":""}><Avatar source={variant.image.src} /></div> : null;
				result.push(image);
			}

			result.push(textDisplayLayout);
			if (displayFulfillmentLocation) {
				result.push(item.assigned_location);
			}
			if (displayItemCharge) {
				// if parent exist then do not print the price for the bundle items (price is on the parent level)
				findParent !== undefined ? result.push('') : result.push(this.formatCurrency(item.price));
			}
			if (displayProductProperties) {
				if (item.bundleItem) {
					result.push('Bundled item');
				} else {
					const propertiesToExclude =
						this.props.propertyExclusion === undefined || this.props.propertyExclusion === null
							? []
							: this.props.propertyExclusion;
					result.push(
						item.properties.map(prop =>
							prop.name[0] === '_' ||
							propertiesToExclude.find(exclusion => exclusion.propertyName === prop.name) !== undefined ? (
								''
							) : (
								<p key={prop.name}>{`${prop.name} - ${prop.value}`}</p>
							)
						)
					);
				}
			}

			if (productSupport && displayProductTags && variant) {
				result.push(variant.tags);
			}
			if(productSupport){
				for (const display of Object.entries(displayMetafield || {})) {
					if (display[1] === true) {
						if (variant?.metafields) {
							const metafield = variant.metafields.find(
								x => x.namespace === display[0].split('.')[0] && x.key === display[0].split('.')[1]
							);
							metafield !== undefined ? result.push(metafield.value) : result.push('');
						}
					}
				}
			}

			if (displayItemFullfillableQuantity) {
				result.push(item.fulfillable_quantity);
			}
			result.push(item.quantity);
			if (displayItemCharge) {
				if (item.price >= 0 && item.quantity > 0) {
					if (!item.bundleItem) result.push(this.formatCurrency(parseFloat(item.price) * item.quantity));
					else {
						// if parent exist then do not print the price for the bundle items (price is on the parent level)
						findParent !== undefined
							? result.push('')
							: result.push(this.formatCurrency(parseFloat(item.price) * item.bundleQuantity));
					}
				} else {
					result.push(item.price);
				}
			}
			results.push(result);
		});
		if (prepacked.length) {
			// find color in colorSkuList
			const color =
				colorSkuList && colorSkuList.colorSku && prepacked !== false && prepacked !== undefined
					? colorSkuList.colorSku.find(o => o.sku === prepacked.toUpperCase())
					: null;

			let result = [];
			result.push(false);
			result.push(false);
			result.push(prepacked);
			result.push('');
			// if we found a color then we add it to the result table
			result.push(color !== undefined && color !== null ? color.color : '');

			if (productSupport && displayVariantImage) {
				result.push('');
			}

			result.push(prepacked);
			if (displayItemCharge) {
				result.push('');
			}
			if (displayProductProperties) {
				result.push('Pre packed');
			}
			if (displayItemFullfillableQuantity) {
				result.push('-');
			}
			result.push(1);
			if (displayItemCharge) {
				result.push('');
			}
			results.push(result);
		}
		return results;
	}

	displayBundles(item) {
		const rowsUpdated = this.state.rows.map(row => {
			if (item[2] === row[3]) row[1] = !row[1];
			return row;
		});
		this.setState({ ...this.state, rows: rowsUpdated });
	}

	render() {
		const header = (
			<tr>
				{this.state.header.map((heading, ix) => {
					if (ix === 0) return <th key={`pcdt-header-${ix}`}></th>;
					if (ix === 4 && this.state.isColorSkuEnabled)
						return (
							<th key={`pcdt-header-${ix}`} style={{ width: '20px' }}>
								&nbsp;
							</th>
						);
					if (ix > 4) return <th key={`pcdt-header-${ix}`}>{heading}</th>;
					else return null;
				})}
			</tr>
		);
		const rows = this.state.rows;
		const lines = rows
			.filter(row => row[1] === false)
			.map((row, ix) => {
				return (
					<tr key={`pcdt-row-${ix}`}>
						{row.map((cell, rix) => {
							if (rix === 0) {
								if (cell)
									return (
										<td key={`pcdt-row-cell-${rix}`}>
											<Button plain onClick={() => this.displayBundles(row)}>
												&#9655;
											</Button>
										</td>
									);
								else return <td key={`pcdt-row-cell-${rix}`}>&nbsp;</td>;
							}
							if (rix === 4 && this.state.isColorSkuEnabled)
								return (
									<td key={`pcdt-row-cell-${rix}`}>
										<div style={{ width: '20px', borderRadius: '100%', backgroundColor: `${cell}` }}>&nbsp;</div>
									</td>
								);
							if (rix > 4) return <td key={`pcdt-row-cell-${rix}`}>{cell}</td>;
							else return null;
						})}
					</tr>
				);
			});
		return (
			<div>
				<table key={'pcdt'} className="card-products">
					<thead>{header}</thead>
					<tbody>{lines}</tbody>
				</table>
			</div>
		);
	}
}
