import React, { Component } from "react";
import { helpers } from "../../helpers";
import { Select, RadioButton, Card, Checkbox, Caption, FormLayout, TextField, Button, ChoiceList, Stack, DropZone, Subheading } from "@shopify/polaris";
import { Preview } from "./Preview";
import { SetupWizard } from "./SetupWizard";
import { connect } from "react-redux";
import { isFeatureAvailableForPlan, getUnavailableMessage } from "../../helpers/features";
import * as actions from "../../actions";

export class Theme extends Component {
    constructor(props) {
        super(props);
        if (props.preferences == null || props.preferences.displayMode == null) {
            this.state = {
                ...SetupWizard.themePresets.detailed,
                showPreview: false,
            };
        } else {
            this.state = {
                displayCompany: props.preferences.displayCompany,
                displayContact: props.preferences.displayContact,
                displayCustomerTags: props.preferences.displayCustomerTags,
                displayOrderTags: props.preferences.displayOrderTags,
                displayProductProperties: props.preferences.displayProductProperties,
                displayOrderProperties: props.preferences.displayOrderProperties,
                displayOrderNotes: props.preferences.displayOrderNotes,
                displayOrderDate: props.preferences.displayOrderDate,
                displayShippingDetails: props.preferences.displayShippingDetails,
                displayShippingDescription: props.preferences.displayShippingDescription,
                displayItemCharge: props.preferences.displayItemCharge,
                displayItemFullfillableQuantity: props.preferences.displayItemFullfillableQuantity,
                displayOrderTotal: props.preferences.displayOrderTotal,
                displayShippingAndTaxes: props.preferences.displayShippingAndTaxes,
                displayTotalWeight: props.preferences.displayTotalWeight,

                displayOrderNumber: props.preferences.displayOrderNumber,

                displayProductTags: props.preferences.displayProductTags || false,
                displayVariantImage: props.preferences.displayVariantImage || false,
                displayMetafield: props.preferences.displayMetafield || {},
                displayAddress: props.preferences.displayAddress,
                displayDiscountCode: props.preferences.displayDiscountCode,

                displayPaymentStatus: props.preferences.displayPaymentStatus,

                displayFulfillmentLocations: props.preferences.displayFulfillmentLocations,
                displayFulfillmentLocation: props.preferences.displayFulfillmentLocation,

                displayOnAllOrders: props.preferences.displayOnAllOrders,

                pickDisplayMode: props.preferences.pickDisplayMode,

                displayMode: props.preferences.displayMode,
                expectSkuCodes: props.preferences.expectSkuCodes,

                logo: props.preferences.logo,
                headerText: props.preferences.headerText,
                footerText: props.preferences.footerText,

                labelLayout: props.preferences.labelLayout,

                showPreview: false,
                metafields: props.preferences.metafields || [],
            };
        }
    }

    update() {
        this.props.update(this.state);
    }

    handleDisplayCompanyChange = (displayCompany) => this.setState({ displayCompany }, this.update);
    handleDisplayContactChange = (displayContact) => this.setState({ displayContact }, this.update);
    handleDisplayCustomerTagsChange = (displayCustomerTags) => this.setState({ displayCustomerTags }, this.update);
    handleDisplayOrderTagsChange = (displayOrderTags) => this.setState({ displayOrderTags }, this.update);

    handleDisplayProductPropertiesChange = (displayProductProperties) => this.setState({ displayProductProperties }, this.update);
    handleDisplayOrderPropertiesChange = (displayOrderProperties) => this.setState({ displayOrderProperties }, this.update);
    handleDisplayNotesChange = (displayOrderNotes) => this.setState({ displayOrderNotes }, this.update);
    handleDisplayShippingDetailsChange = (displayShippingDetails) => this.setState({ displayShippingDetails }, this.update);
    handleDisplayShippingDescriptionChange = (displayShippingDescription) => this.setState({ displayShippingDescription }, this.update);
    handleDisplayItemChargeChange = (displayItemCharge) => this.setState({ displayItemCharge }, this.update);
    handleDisplayItemFullfillableQuantityChange = (displayItemFullfillableQuantity) => this.setState({ displayItemFullfillableQuantity }, this.update);
    handleDisplayShippingAndTaxesChange = (displayShippingAndTaxes) => this.setState({ displayShippingAndTaxes }, this.update);
    handleDisplayTotalWeight = (displayTotalWeight) => this.setState({ displayTotalWeight }, this.update);

    handleDisplayOrderTotalChange = (displayOrderTotal) => this.setState({ displayOrderTotal }, this.update);
    handleDisplayOrderDateChange = (displayOrderDate) => this.setState({ displayOrderDate }, this.update);
    handleDisplayAddressChange = (displayAddress) => this.setState({ displayAddress }, this.update);
    handleDisplayDiscountCode = (displayDiscountCode) => this.setState({ displayDiscountCode }, this.update);

    handleDisplayOrderNumber = (displayOrderNumber) => this.setState({ displayOrderNumber }, this.update);

    handleDisplayVariantImageChange = (displayVariantImage) => this.setState({ displayVariantImage }, this.update);
    handleDisplayProductTagsChange = (displayProductTags) => this.setState({ displayProductTags }, this.update);

    handleDisplayMetafieldChange = (checked, id) =>
        this.setState((state) => {
            const displayMetafield = state.displayMetafield;
            displayMetafield[id] = checked;
            return { displayMetafield };
        }, this.update);

    handleDisplayPaymentStatusChange = (displayPaymentStatus) => this.setState({ displayPaymentStatus }, this.update);

    handleDisplayFulfillmentLocationsChange = (displayFulfillmentLocations) => this.setState({ displayFulfillmentLocations }, this.update);

    handleDisplayFulfillmentLocationChange = (displayFulfillmentLocation) => this.setState({ displayFulfillmentLocation }, this.update);
    handleDisplayOnAllOrdersChange = (displayOnAllOrders) => this.setState({ displayOnAllOrders }, this.update);

    handleDisplayModeChange = (displayMode) => this.setState({ displayMode: displayMode[0] }, this.update);

    handlePickDisplayModeChange = (checked, pickDisplayMode) => this.setState({ pickDisplayMode }, this.update);

    handleExpectSkuCodesChange = (expectSkuCodes) => this.setState({ expectSkuCodes }, this.update);

    handleUploadLogo = async (files, acceptedFiles) => {
        let response = await this.props.updateLogo(acceptedFiles[0]);
        this.setState({ logo: response.payload }, this.update);
    };

    handleHeaderTextChange = (headerText) => this.setState({ headerText }, this.update);
    handleFooterTextChange = (footerText) => this.setState({ footerText }, this.update);

    handleLabelLayoutChange = (labelLayout) => this.setState({ labelLayout }, this.update);

    render() {
        const renderPreview = this.state.showPreview ? <Preview onClose={() => this.setState({ showPreview: false })} preferences={this.state} /> : "";

        let renderCardStyleOptions =
            this.state.pickDisplayMode === "card" || this.state.pickDisplayMode === "label" ? (
                <Stack vertical>
                    <p>Optional branding options to be printed on each card</p>
                    {this.state.logo != null && this.state.logo.length > 0 ? (
                        <Stack vertical>
                            <img className="logo-preview" alt="Logo" src={this.state.logo}></img>
                            <Button onClick={() => this.setState({ logo: "" })}>Remove Logo</Button>
                        </Stack>
                    ) : (
                        ""
                    )}
                    <p>Logo to display</p>
                    <DropZone title="Logo to display" accept="image/gif,image/jpeg,image/png" onDrop={this.handleUploadLogo}>
                        <DropZone.FileUpload></DropZone.FileUpload>
                    </DropZone>
                    <TextField label="Header text" placeholder="Text to display in the top right of each card, add as many lines as is needed" value={this.state.headerText} onChange={this.handleHeaderTextChange} multiline align="right" />
                    <TextField label="Footer text" placeholder="Text to display across the bottom of each card" value={this.state.footerText} onChange={this.handleFooterTextChange} align="center" multiline />
                    {this.state.pickDisplayMode === "label" ? <Select label="Label layout" id="labelLayout" options={helpers.labelLayouts} onChange={this.handleLabelLayoutChange} value={this.state.labelLayout} /> : ""}
                </Stack>
            ) : (
                ""
            );
        return (
            <Stack vertical>
                <Card title="Choose how you want orders to be displayed" sectioned>
                    <Stack vertical>
                        <RadioButton
                            label="List style"
                            helpText="Perfect for large numbers of orders."
                            checked={this.state.pickDisplayMode == null || this.state.pickDisplayMode === "list"}
                            id="list"
                            name="list"
                            onChange={this.handlePickDisplayModeChange}
                        />
                        <RadioButton
                            label="Packing slip style"
                            helpText="Great for printing out complex orders or printing packing slips for including in orders."
                            id="card"
                            name="card"
                            checked={this.state.pickDisplayMode === "card"}
                            onChange={this.handlePickDisplayModeChange}
                        />
                        <RadioButton
                            label="Label style"
                            helpText="Print out labels to attach to orders (does not include tracking barcodes)."
                            id="label"
                            name="label"
                            checked={this.state.pickDisplayMode === "label"}
                            onChange={this.handlePickDisplayModeChange}
                        />
                        {/* <RadioButton
                            label="Template"
                            helpText="Get full control over how the list is displayed, requires HTML knowledge."
                            id="template"
                            name="template"
                            disabled
                            checked={this.state.pickDisplayMode === 'template'}
                            onChange={this.handlePickDisplayModeChange}
                        /> */}
                        {renderCardStyleOptions}
                        <Button
                            onClick={() => {
                                this.setState({ showPreview: true });
                            }}
                        >
                            Show Preview
                        </Button>
                    </Stack>
                </Card>
                <Card title="Select what information you want to display for each order in the pick list" sectioned>
                    <FormLayout>
                        <Subheading>Order details:</Subheading>
                        <FormLayout.Group>
                            <Checkbox checked={this.state.displayCompany} onChange={this.handleDisplayCompanyChange} label="Company" />
                            <Checkbox checked={this.state.displayContact} onChange={this.handleDisplayContactChange} label="Contact" />
                            <Checkbox checked={this.state.displayCustomerTags && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayCustomerTagsChange} label="Customer tags" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox checked={this.state.displayOrderDate} onChange={this.handleDisplayOrderDateChange} label="Order date" />
                            <Checkbox checked={this.state.displayOrderTags && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayOrderTagsChange} label="Order tags" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox
                                checked={this.state.displayOrderProperties && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayOrderPropertiesChange}
                                label="Order properties"
                                disabled={this.state.pickDisplayMode === "label"}
                            />
                            <Checkbox checked={this.state.displayOrderNotes} onChange={this.handleDisplayNotesChange} label="Notes" />
                            <Checkbox checked={this.state.displayShippingDetails} onChange={this.handleDisplayShippingDetailsChange} label="Shipping code" />
                            <Checkbox checked={this.state.displayShippingDescription} onChange={this.handleDisplayShippingDescriptionChange} label="Shipping description" />
                            <Checkbox checked={this.state.displayAddress || this.state.pickDisplayMode === "label"} onChange={this.handleDisplayAddressChange} label="Delivery address" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox
                                checked={this.state.displayShippingAndTaxes && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayShippingAndTaxesChange}
                                label="Shipping & taxes"
                                disabled={this.state.pickDisplayMode === "label"}
                            />
                            <Checkbox checked={this.state.displayOrderTotal && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayOrderTotalChange} label="Order total" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox checked={this.state.displayTotalWeight && this.state.displayTotalWeight !== "label"} onChange={this.handleDisplayTotalWeight} label="Total weight" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox checked={this.state.displayDiscountCode && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayDiscountCode} label="Discount code" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox checked={this.state.displayPaymentStatus && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayPaymentStatusChange} label="Payment Status" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox
                                checked={this.state.displayFulfillmentLocations && this.state.pickDisplayMode !== "label" && isFeatureAvailableForPlan(this.props.plan, "locationsSupport")}
                                onChange={this.handleDisplayFulfillmentLocationsChange}
                                label={`Fulfillment Locations${!isFeatureAvailableForPlan(this.props.plan, "locationsSupport") ? " *" : ""}`}
                                disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "locationsSupport")}
                            />
                        </FormLayout.Group>
                        <Subheading>Line item details:</Subheading>
                        <FormLayout.Group>
                            <Checkbox
                                checked={this.state.displayProductProperties && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayProductPropertiesChange}
                                label="Note attributes"
                                disabled={this.state.pickDisplayMode === "label"}
                            />
                            <Checkbox checked={this.state.displayItemCharge && this.state.pickDisplayMode !== "label"} onChange={this.handleDisplayItemChargeChange} label="Item charges" disabled={this.state.pickDisplayMode === "label"} />
                            <Checkbox
                                checked={this.state.displayItemFullfillableQuantity && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayItemFullfillableQuantityChange}
                                label="Fulfillable Quantity"
                                disabled={this.state.pickDisplayMode === "label"}
                            />
                            <Checkbox
                                checked={this.state.displayFulfillmentLocation && this.state.pickDisplayMode !== "label" && isFeatureAvailableForPlan(this.props.plan, "locationsSupport")}
                                onChange={this.handleDisplayFulfillmentLocationChange}
                                label={`Fulfillment Location${!isFeatureAvailableForPlan(this.props.plan, "locationsSupport") ? " *" : ""}`}
                                disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "locationsSupport")}
                            />
                        </FormLayout.Group>
                        <Subheading>Product information:{!isFeatureAvailableForPlan(this.props.plan, "productSupport") ? " **" : ""}</Subheading>
                        <FormLayout.Group>
                            <Checkbox
                                checked={isFeatureAvailableForPlan(this.props.plan, "productSupport") && this.props.isProductSupport && this.state.displayVariantImage && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayVariantImageChange}
                                label="Variant Image"
                                disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "productSupport") || !this.props.isProductSupport}
                            />
                            <Checkbox
                                checked={isFeatureAvailableForPlan(this.props.plan, "productSupport") && this.props.isProductSupport && this.state.displayProductTags && this.state.pickDisplayMode !== "label"}
                                onChange={this.handleDisplayProductTagsChange}
                                label="Product Tags"
                                disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "productSupport") || !this.props.isProductSupport}
                            />
                            {this.props.isProductSupport &&
                                (this.props.metafields || []).map((metafield) => {
                                    const metaId = `${metafield.namespace}.${metafield.key}`;
                                    const metaTitle = metafield.description || metaId;
                                    return (
                                        <Checkbox
                                            checked={isFeatureAvailableForPlan(this.props.plan, "productSupport") && this.state.displayMetafield[metaId] && this.state.pickDisplayMode !== "label"}
                                            onChange={this.handleDisplayMetafieldChange}
                                            key={`${metaId}-display`}
                                            id={metaId}
                                            label={`Metafield: ${metaTitle}`}
                                            disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "productSupport")}
                                        />
                                    );
                                })}
                        </FormLayout.Group>

                        <Subheading>Customer information:</Subheading>
                        <FormLayout.Group>
                            <Checkbox
                                checked={isFeatureAvailableForPlan(this.props.plan, "customerSupport") && this.props.isCustomerSupport && this.state.displayOrderNumber}
                                onChange={this.handleDisplayOrderNumber}
                                label={`Order number${
                                    !isFeatureAvailableForPlan(this.props.plan, "customerSupport")
                                        ? ` (available on goods plan${!this.props.isCustomerSupport ? " - and enable customer data support" : ""})`
                                        : `${!this.props.isCustomerSupport ? "(You need to enable customer data support)" : ""}`
                                }`}
                                disabled={this.state.pickDisplayMode === "label" || !isFeatureAvailableForPlan(this.props.plan, "customerSupport") || !this.props.isCustomerSupport}
                            />
                        </FormLayout.Group>
                        {this.props.plan !== "nuts" ? <Caption>* {getUnavailableMessage("nutsOnly")}</Caption> : ""}
                        {this.props.plan !== "greats" ? <Caption>** {getUnavailableMessage("greatsOnly")}</Caption> : ""}
                        <Button
                            onClick={() => {
                                this.setState({ showPreview: true });
                            }}
                        >
                            Show Preview
                        </Button>
                    </FormLayout>
                </Card>
                <Card title="Product display mode" sectioned>
                    <p>Select how you identify your products. These will be displayed in the pick list to add to the order. If a barcode or SKU is not available it will fall back to the product name</p>
                    <br />
                    <ChoiceList
                        choices={[
                            {
                                label: "SKU Code",
                                value: "sku",
                                helpText: "Display the SKU code of the product falling back to the product name if no SKU provided",
                            },
                            { label: "Barcode", value: "barcode", helpText: "Display the product bar-code number" },
                            { label: "Product name", value: "name", helpText: "Display the name of the product/variant" },
                            {
                                label: "Vendor - Product name",
                                value: "vendor_name",
                                helpText: "Display the Vendor name first and then the product/variant name",
                            },
                            {
                                label: "SKU - Product name",
                                value: "sku_name",
                                helpText: "Display the SKU (if available) then the product/variant name",
                            },
                            {
                                label: "Product name (SKU)",
                                value: "name_sku",
                                helpText: "Display the product/variant name first then SKU (if available) in brackets",
                            },
                            {
                                label: "Product Name, Variant, and SKU in different lines",
                                value: "name_var_SKU_lines",
                                helpText: "Display the product name, variant name, and SKU (if available) in different lines",
                            },
                            {
                                label: "Vendor, Product Name, Variant, and SKU in different lines",
                                value: "vendor_name_var_SKU_lines",
                                helpText: "Display the vendor, product name, variant name, and SKU (if available) in different lines",
                            },
                        ]}
                        selected={[this.state.displayMode]}
                        onChange={this.handleDisplayModeChange}
                    />
                    <br />

                    <Checkbox
                        checked={this.state.expectSkuCodes}
                        onChange={this.handleExpectSkuCodesChange}
                        label="Expect SKU codes for products"
                        helpText="Display 'NO-SKU' or 'NO-BARCODE' when missing to aid finding missing codes, otherwise fallback to variant/product title"
                    />
                    <br />
                    <Button
                        onClick={() => {
                            this.setState({ showPreview: true });
                        }}
                    >
                        Show Preview
                    </Button>
                </Card>
                {renderPreview}
            </Stack>
        );
    }
}

const mapStateToProps =
    (state) =>
    ({ customLists }) => {
        return {
            // customLists
        };
    };

export default connect(mapStateToProps, actions)(Theme);
