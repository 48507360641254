import React, { useCallback, useState } from 'react';
import { Modal } from '@shopify/polaris';
import { CSVLink } from "react-csv";

const PopupDownloadCSV = ({ csv }) => {
    const [active, setActive] = useState(true);
    const toggleModal = useCallback(() => setActive((active) => !active), []);

    return (
        <div>
            <Modal
                open={active}
                onClose={toggleModal}
            >
                <Modal.Section>
                    <CSVLink data={csv.data} headers={csv.headers} filename={csv.filename}>
                        Download file {csv.filename}
                    </CSVLink>;
                </Modal.Section>
            </Modal>
        </div>
    );
}

export default PopupDownloadCSV;
