import { configRef } from "../config/firebase";
import {
    FETCH_SHOPS,
    SYNC_ERROR,
    IMPERSONATE_COMPLETED
} from "./types";
import { ApiService } from '../helpers/api.service';

export const fetchShops = () => async dispatch => {
    try {
        const service = new ApiService();
        const response = await service.get('admin/shops');

        dispatch({
            type: FETCH_SHOPS,
            payload: response.shops.map(shop => { return { label: shop, value: shop } })
        });
    } catch (error) {
        dispatch({
            type: SYNC_ERROR,
            payload: { message: error }
        });
    }
    // let headers = (process.env.NODE_ENV === 'development') ? {
    //     'test': 'true',
    //     'shop': sessionStorage.shopOrigin
    // } : {
    //         'hmac': sessionStorage.hmac,
    //         'query': sessionStorage.query,
    //         'shop': sessionStorage.shopOrigin
    //     }

    // request.get(`${process.env.REACT_APP_API_URL}/ppp/admin/shops`, { headers }, (error, response) => {
    //     if (error) {
    //         console.error(error || response.statusCode !== 200);
    //         dispatch({
    //             type: SYNC_ERROR,
    //             payload: { message: error }
    //         });
    //         return
    //     }

    //     dispatch({
    //         type: FETCH_SHOPS,
    //         payload: JSON.parse(response.body).shops.map(shop => { return { label: shop, value: shop } })
    //     });

    // })
}

export const impersonate = shop => async dispatch => {
    if (process.env.REACT_APP_TEST_SHOP === sessionStorage.shopOrigin) {
        const impersonateShop = (shop || '')
        sessionStorage.impersonating = impersonateShop.length > 0;
        sessionStorage.impersonatingShopOrigin = impersonateShop;
        await configRef(false).update({
            impersonateShop: impersonateShop
        });
        // await fetchPreferences();
        dispatch({
            type: IMPERSONATE_COMPLETED,
            payload: true
        });
        window.location.reload();
    }
}
