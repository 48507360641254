import React, { Component } from "react";
import DOMPurify from "dompurify";
import { DisplayText, TextStyle, ResourceList, FilterType, Badge, Card, Caption, Stack, Link, CalloutCard } from "@shopify/polaris";
import { helpers } from "../../helpers";
import moment from "moment";
import * as _ from "lodash";
import { PickCardDataTable } from "./PickCardDataTable";
import CurrencyFormatter from "../CurrencyFormatter";
import * as request from "request";
import NumberFormat from "react-number-format";

export class PickCard extends Component {
    state = {
        orders: [],
        promotions: [],
        products: [],
        packed: [],
        picked: [],
        selectedTab: 0,
        selectedOrder: null,
        colorSkuList: [],
        weightUnit: "",
    };
    loading = true;

    getProductProperties(properties, displayProductProperties, displayOnAllOrders, selectedOrder) {
        const renderProps = properties.map((a) => {
            return <Caption key={a.name}>{`${a.name}: ${a.value}`}</Caption>;
        });
        return !displayProductProperties ? "" : displayOnAllOrders ? renderProps : selectedOrder ? renderProps : "";
    }

    handleSelectionChange = (picked) => {
        this.setState({ picked });
    };

    buildGroupCard(group) {
        return (
            <div key={`${group.title}-${Math.random()}`} className="card noPrint">
                <Card sectioned>
                    <div className="cardContent">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>
                                <h3 className="Polaris-DisplayText Polaris-DisplayText--sizeSmall" style={{ fontWeight: "600" }}>
                                    {group.title}
                                </h3>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }

    formatCurrency(number, order) {
        if (number >= 0 && order.currency != null && order.currency.length > 0) return <CurrencyFormatter price={number} curr={order.currency} locale={order.locale} />;
        else return number;
    }

    buildOrderCard(order) {
        const {
            id,
            order_name,
            name,
            phone,
            company,
            discount,
            shipping_code,
            shipping,
            note,
            note_attributes,
            total_price,
            total_weight,
            total_tax,
            total_shipping,
            tags,
            customerTags,
            pick_list,
            promotions,
            prepacked,
            redacted,
            financial_status,
            original_date,
        } = order;

        const orderDate = new Date(order.date);
        const orderUrl = `https://${sessionStorage.shopOrigin}/admin/orders/${id}`;

        const orderName = (
            <span>
                <TextStyle variation="strong">
                    {name}
                    {this.props.preferences.displayCompany && company && company !== "" ? ` c\\o ${company}` : ""}
                </TextStyle>
            </span>
        );
        const renderTags =
            tags.length && this.props.preferences.displayOrderTags
                ? tags.split(", ").map((o) => (
                      <Badge key={o} status="attention">
                          {o}
                      </Badge>
                  ))
                : "";
        const renderCustomerTags =
            customerTags.length && this.props.preferences.displayCustomerTags
                ? customerTags.split(", ").map((o) => (
                      <Badge key={o} status="success">
                          {o}
                      </Badge>
                  ))
                : "";

        const renderShippingAddress =
            !this.props.preferences.displayAddress || !order.shipping_address ? (
                ""
            ) : (
                <div>
                    <Badge status="default">Shipping Address</Badge>
                    <p>
                        {order.shipping_address.first_name} {order.shipping_address.last_name}
                    </p>
                    <p>{order.shipping_address.company}</p>
                    <p>{order.shipping_address.address1}</p>
                    <p>{order.shipping_address.address2}</p>
                    <p>
                        {order.shipping_address.city}, {order.shipping_address.province}
                    </p>
                    <p>
                        {order.shipping_address.country}, {order.shipping_address.zip}
                    </p>
                </div>
            );

        const renderShippingCode = !this.props.preferences.displayShippingDetails ? (
            ""
        ) : (
            <div>
                <br></br>
                <Badge status="default">Shipping method</Badge>
                <p>{shipping_code}</p>
            </div>
        );
        const renderShippingName = !this.props.preferences.displayShippingDescription ? (
            ""
        ) : (
            <div>
                <br></br>
                <Badge status="default">Shipping</Badge>
                <p>{shipping}</p>
            </div>
        );

        const renderNoteInstructions = note ? (
            <span>
                <Badge status="default">Notes</Badge>
                <span dangerouslySetInnerHTML={{ __html: note }} />
            </span>
        ) : (
            ""
        );
        const renderOrderDate = this.props.preferences.displayOrderDate ? (
            <Badge>
                <TextStyle variation={this.props.preferences.altDateRetainOriginalDisplay === true ? "strong" : "normal"}>
                    {this.props.preferences.altDateLabel != null && this.props.preferences.altDateLabel.length > 0 ? this.props.preferences.altDateLabel : "Order Date"}: {moment(orderDate).format("Do MMM, YYYY")}
                </TextStyle>
            </Badge>
        ) : (
            ""
        );
        const renderOriginalOrderDate =
            this.props.preferences.altDateRetainOriginalDisplay === true ? (
                <Badge>
                    {this.props.preferences.altDateRetainOriginalLabel != null && this.props.preferences.altDateRetainOriginalLabel.length > 0 ? this.props.preferences.altDateRetainOriginalLabel : "Original Order Date"}:{" "}
                    {moment(original_date).format("Do MMM, YYYY")}
                </Badge>
            ) : (
                ""
            );
        const attributes = note_attributes.map((n) => {
            return <Caption key={n.toString()}>{`${n.name}: ${n.value}`}</Caption>;
        });

        const renderContact = (
            <Stack>
                <Badge status="default">Contact</Badge>
                <p>
                    {name} {phone && phone !== "" ? ` - ${phone}` : ""}
                </p>
            </Stack>
        );
        const renderDiscountCode = (
            <Stack>
                <Badge status="default">Discount Code</Badge>
                <p>{discount}</p>
            </Stack>
        );

        const renderNoteAttributes = note_attributes.length ? (
            <Stack>
                <Badge status="default">Attributes</Badge>
                <Stack vertical>{attributes}</Stack>
            </Stack>
        ) : (
            ""
        );
        const detailDisplay = (
            <Stack vertical="true">
                {this.props.preferences.displayContact ? renderContact : ""}
                {this.props.preferences.displayDiscountCode && discount && discount.length ? renderDiscountCode : ""}
                {this.props.preferences.displayOrderNotes ? renderNoteInstructions : ""}
                {this.props.preferences.displayOrderProperties ? renderNoteAttributes : ""}
            </Stack>
        );

        const renderName = <TextStyle variation="strong">{orderName}</TextStyle>;

        const shippingAndTaxes = (
            <div>
                <br />
                <Badge size="large">Shipping: {this.formatCurrency(total_shipping, order)}</Badge>
                <br />
                <Badge size="large">Taxes: {this.formatCurrency(total_tax, order)}</Badge>
            </div>
        );
        const orderTotal = (
            <div>
                <br />
                <Badge status="attention" size="large">
                    <TextStyle variation="strong">Total: {this.formatCurrency(total_price, order)}</TextStyle>
                </Badge>
            </div>
        );
        const paymentStatus = (
            <div>
                <br></br>
                <Badge status="default">Payment Status</Badge>
                <p>{financial_status}</p>
            </div>
        );

        const renderTotalWeight = this.props.preferences.displayTotalWeight ? (
            <div>
                <br />
                <Badge status="attention">
                    <NumberFormat value={helpers.calculateWeight(total_weight, this.state.weightUnit)} displayType={"text"} thousandSeparator={true} prefix="Weight : " suffix={` ${this.state.weightUnit}`} decimalScale={2} />
                </Badge>
            </div>
        ) : (
            ""
        );
        const renderShippingAndTaxes = !this.props.preferences.displayShippingAndTaxes ? "" : shippingAndTaxes;
        const renderOrderTotal = !this.props.preferences.displayOrderTotal ? "" : orderTotal;
        const renderPaymentStatus = !this.props.preferences.displayPaymentStatus ? "" : paymentStatus;

        const shortcutActions = [
            order.pick_status === "flagged"
                ? {
                      content: "Un-flag",
                      onAction: (e) => {
                          if (e !== undefined) e.preventDefault();
                          this.props.updatePickedStatus(id, "unpicked");
                      },
                  }
                : {
                      content: "Flag",
                      onAction: (e) => {
                          if (e !== undefined) e.preventDefault();
                          this.props.updatePickedStatus(id, "flagged");
                      },
                  },
            order.pick_status === "picked"
                ? {
                      content: "Un-Pick",
                      onAction: (e) => {
                          if (e !== undefined) e.preventDefault();
                          this.props.updatePickedStatus(id, "unpicked");
                      },
                  }
                : {
                      content: "Picked",
                      onAction: (e) => {
                          if (e !== undefined) e.preventDefault();
                          this.props.updatePickedStatus(id, "picked");
                      },
                  },
            {
                content: "Select",
                onAction: (e) => {
                    if (e !== undefined) e.preventDefault();
                    this.props.updateBulkSelection("select", id);
                },
            },
            {
                content: "Unselect",
                onAction: (e) => {
                    if (e !== undefined) e.preventDefault();
                    this.props.updateBulkSelection("unselect", id);
                },
            },
        ];

        const isThisOrderSelected = _.find(this.props.bulkSelected, (x) => x === id) != null;
        const primaryAction = this.props.bulkSelecting ? (isThisOrderSelected ? shortcutActions[3] : "") : shortcutActions[1];
        const secondaryActions = this.props.bulkSelecting ? (isThisOrderSelected ? "" : [shortcutActions[2]]) : [shortcutActions[0]];

        if (!this.props.bulkSelecting && this.props.preferences.orderMode === "fulfillment") {
            let push = false;
            if (this.props.selectedTab === "pick-list" && this.props.preferences.fulfillFromMain) push = true;
            else if (this.props.selectedTab === "picked" && this.props.preferences.fulfillFromPickedAndFlagged) push = true;
            else if (this.props.selectedTab === "picked" && this.props.preferences.fulfillFromPickedAndFlagged) push = true;
            else if (this.props.preferences.fulfillFromCustom) push = true;
            if (push)
                secondaryActions.unshift({
                    content: "Fulfilled",
                    onAction: (e) => {
                        if (e !== undefined) e.preventDefault();
                        this.props.updatePickedStatus(id, "fulfilled");
                    },
                });
        }
        if (!this.props.bulkSelecting) {
            (this.props.customLists || []).forEach((list) => {
                secondaryActions.push(
                    this.props.selectedTab === list.id
                        ? {
                              content: `Remove from ${list.content}`,
                              onAction: (e) => {
                                  if (e !== undefined) e.preventDefault();
                                  this.props.updateCustomList(order.id, "");
                              },
                          }
                        : {
                              content: `Move to ${list.content}`,
                              onAction: (e) => {
                                  if (e !== undefined) e.preventDefault();
                                  this.props.updateCustomList(order.id, list.id);
                              },
                          }
                );
            });
        }

        const renderHeader =
            this.props.preferences.logo.length > 0 || this.props.preferences.headerText.length > 0 ? (
                <div className="card-header">
                    <img className="logo-preview" src={this.props.preferences.logo} alt="Logo"></img>
                    <div>
                        <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.preferences.headerText || "") }}></pre>
                    </div>
                </div>
            ) : (
                ""
            );
        const renderFooter =
            this.props.preferences.footerText.length > 0 ? (
                <div className="card-footer">
                    <div>
                        <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.preferences.footerText || "") }}></pre>
                    </div>
                </div>
            ) : (
                ""
            );

        let renderDataTables = <PickCardDataTable preferences={this.props.preferences} colorSkuList={this.props.colorSkuList} pick_list={pick_list} variants={this.props.variants} prepacked={prepacked} order={order} />;
        const renderOrderNumber =
            this.props.preferences.customerSupport && this.props.preferences.displayOrderNumber ? (
                <Badge key={order.count} status="default">
                    {helpers.ordinal_suffix_of(order.count)} order
                </Badge>
            ) : (
                ""
            );
        const renderFulfillmentLocations =
            this.props.preferences.displayFulfillmentLocations && order.fulfillment_locations ? (
                <Badge key={order.fulfillment_locations} status="default">
                    Locations: {order.fulfillment_locations}
                </Badge>
            ) : (
                ""
            );
        const cardClass = this.props.bulkSelecting && !isThisOrderSelected ? "noPrint card" : "card";
        const redactedOrder = (
            <CalloutCard
                title="You have reached the limit of unfulfilled orders"
                illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
                primaryAction={{
                    content: "Upgrade account",
                    url: `/account${window.location.search}`,
                }}
            >
                <p>The free account can only display up to 25 orders. Either fulfill some of your orders or consider upgrading your account.</p>
            </CalloutCard>
        );
        return (
            <div key={order.id} className={cardClass}>
                {" "}
                <Card secondaryFooterActions={secondaryActions} primaryFooterAction={primaryAction} key={id} sectioned>
                    <div className="cardContent">
                        {renderHeader}
                        {redacted ? (
                            redactedOrder
                        ) : (
                            <div>
                                <div className="card-head-content">
                                    <div>
                                        <DisplayText size="large">
                                            <Link external="true" url={orderUrl}>
                                                <TextStyle variation="strong"> {order_name} </TextStyle>
                                            </Link>
                                            {renderName}
                                        </DisplayText>
                                        <div>{renderOrderDate}</div>
                                        <div>{renderOriginalOrderDate}</div>
                                        <div>{renderTags}</div>
                                        <div>{renderCustomerTags}</div>
                                        <div>{renderOrderNumber}</div>
                                        <div>{renderFulfillmentLocations}</div>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        {renderShippingAddress}
                                        {renderShippingCode}
                                        {renderShippingName}
                                        {renderPaymentStatus}
                                    </div>
                                </div>
                                {renderDataTables}
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div>{detailDisplay}</div>
                                    <div>
                                        <div style={{ textAlign: "right" }}>
                                            {/* {this.getPickList(prepacked, pick_list, this.props.preferences.displayProductProperties, this.props.preferences.displayOnAllOrders, this.props.selectedOrder === id, this.props.preferences.displayItemCharge)} */}
                                            {promotions.map((o) => (
                                                <Badge key={o} status="info">
                                                    {o}
                                                </Badge>
                                            ))}
                                            {renderTotalWeight}
                                            {renderShippingAndTaxes}
                                            {renderOrderTotal}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {renderFooter}
                    </div>
                </Card>
                <div className="printBreak"></div>
            </div>
        );
    }

    buildOrderCards(orders) {
        let orderCards = [];
        orders.forEach((order) => {
            if (order.group) {
                orderCards.push(this.buildGroupCard(order));
            } else {
                orderCards.push(this.buildOrderCard(order));
            }
        });
        return orderCards;
    }

    componentDidMount() {
        let headers =
            process.env.NODE_ENV === "development"
                ? {
                      test: "true",
                  }
                : {
                      hmac: sessionStorage.hmac,
                      query: sessionStorage.query,
                      shop: sessionStorage.shopOrigin,
                  };

        request.get(`${process.env.REACT_APP_API_URL}/ppp/weight-unit`, { headers }, (error, response, weightUnit) => {
            this.setState({ weightUnit });
        });
    }

    render() {
        const filterControl = this.props.preferences.enableFiltering ? (
            <ResourceList.FilterControl
                onFiltersChange={(appliedFilters) => {
                    this.props.applyFilters(appliedFilters);
                }}
                onSearchChange={(searchTerm) => {
                    this.props.setSearchTerm(searchTerm);
                }}
                searchValue={this.props.searchTerm}
                searchTerm={this.props.searchTerm}
                appliedFilters={this.props.appliedFilters}
                filters={[
                    {
                        key: "orderDateFilterBefore",
                        label: "Date",
                        operatorText: "is",
                        type: FilterType.DateSelector,
                        dateOptionType: "full",
                        minKey: "dateAfter",
                        maxKey: "dateBefore",
                    },
                    {
                        key: "orderItemSkuFilter",
                        label: "Contains SKU",
                        type: FilterType.TextField,
                        textFieldType: "text",
                    },
                    {
                        key: "orderItemOnlySkuFilter",
                        label: "ONLY contains SKU",
                        type: FilterType.TextField,
                        textFieldType: "text",
                    },
                    {
                        key: "orderItemNoSkuFilter",
                        label: "Does NOT contain SKU",
                        type: FilterType.TextField,
                        textFieldType: "text",
                    },
                    {
                        key: "orderShipCodeFilter",
                        label: "Shipping Code",
                        type: FilterType.TextField,
                        textFieldType: "text",
                    },
                    {
                        key: "orderNoShipCodeFilter",
                        label: "Does NOT have Shipping Code",
                        type: FilterType.TextField,
                        textFieldType: "text",
                    },
                ]}
            />
        ) : (
            ""
        );

        const filter = !this.props.preferences.enableFiltering ? (
            ""
        ) : (
            <div style={{ marginBottom: "20px" }}>
                <Card>
                    <ResourceList
                        resourceName={{ singular: "Order", plural: "Orders" }}
                        loading={this.props.loading}
                        items={[{}]}
                        // showHeader={true}
                        filterControl={filterControl}
                        renderItem={() => {}}
                    />
                </Card>
            </div>
        );
        return (
            <div>
                {filter}
                {this.buildOrderCards(this.props.orders)}
            </div>
        );
    }
}
