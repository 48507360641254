import React, { Component } from 'react';
import { Popover, ActionList, Checkbox, Stack, Card, Button } from '@shopify/polaris';
import { PrintMajorMonotone, FlagMajorMonotone, EnableSelectionMinor, BehaviorMajorMonotone } from '@shopify/polaris-icons';
import { isFeatureAvailableForPlan } from '../helpers/features';
import PopupCustomList from './PopupCustomList';
import PopupDownloadCSV from './PopupDownloadCSV';
import { ExportService } from '../helpers/exporter';
import { deleteCustomList } from '../actions/custom-list.actions'
export class Tools extends Component {

    exp = null;
    csv = {};

    state = {
        toggleActive: false,
        active: false,
        downloadPopup: false,
    }

    toggleActive = _ => this.setState({ active: !this.state.active }, this.update);
    downloadPopupActive = () => this.setState({ downloadPopup: !this.state.downloadPopup }, this.update);

    createPickListCSV = async () => {
        if (this.state.downloadPopup) this.downloadPopupActive()
        this.exp = new ExportService(this.props.preferences.exports);
        this.csv = await this.exp.exportPickListDataCSV(this.props.filteredOrders)
        this.downloadPopupActive()
    }

    createSummaryCSV = async () => {
        if (this.state.downloadPopup) this.downloadPopupActive()
        this.exp = new ExportService(this.props.preferences.exports);
        this.csv = await this.exp.exportSummaryDataCSV(this.props.filteredOrders)
        this.downloadPopupActive()
    }

    deleteCustomList = async (id) => {
        console.log(id)
        await deleteCustomList(id)(); // call action to delete custom list
        this.props.handleTabChange(0); // go back to the Pick list
        this.props.fetchCustomLists(this.props.plan); // Update the lists
    }

    render() {
        let allowBulkFulfill = false;

        if (this.props.preferences.fulfillFromBulk) {
            if (this.props.selectedTab === 'pick-list') allowBulkFulfill = true;
            else if (this.props.selectedTab === 'picked') allowBulkFulfill = true;
            else allowBulkFulfill = true;
        }
        const activator = (
            <Button onClick={this.toggleActive} disclosure>
                Export
            </Button>
        );
        const renderBulkFulfilled = allowBulkFulfill ? <Button onClick={() => this.props.updateBulkPickedStatus('fulfilled')} icon={FlagMajorMonotone}>Fulfill selected</Button> : '';
        return <Card className="noPrint">
            <div className="noPrint" style={{ margin: '10px' }}>
                <Stack>
                    {this.props.filteredOrders.length !== 0 &&
                        (<Button onClick={() => window.print()} icon={PrintMajorMonotone}>{this.props.bulkSelecting ? 'Print Selected' : 'Print'}</Button>)
                    }
                    {(this.props.filteredOrders.length !== 0 && !this.props.preferences.hideSummary) &&
                        <Checkbox
                            checked={this.props.printSummary}
                            onChange={this.props.handlePrintSummaryChange}
                            label="Print summary"
                        />
                    }
                    {this.props.filteredOrders.length !== 0 &&
                        <Checkbox
                            checked={this.props.displayListName}
                            onChange={this.props.handleDisplayListNameChange}
                            label="Display list name"
                        />
                    }
                    {this.props.bulkSelecting &&
                        <Button
                            onClick={() => this.props.updateBulkPickedStatus(this.props.selectedTab !== 1 ? 'picked' : 'unpicked')} icon={BehaviorMajorMonotone}>{this.props.selectedTab !== 1 ? 'Pick selected' : 'Unpick selected'}</Button>
                    }
                    {this.props.bulkSelecting &&
                        <Button
                            onClick={() => this.props.updateBulkPickedStatus(this.props.selectedTab !== 2 ? 'flagged' : 'unpicked')} icon={FlagMajorMonotone}>{this.props.selectedTab !== 2 ? 'Flag selected' : 'Unflag selected'}</Button>
                    }
                    {this.props.bulkSelecting && renderBulkFulfilled}
                    {this.props.bulkSelecting &&
                        <Button onClick={() => this.props.selectAll(this.props.tabs)}>Select all</Button>
                    }
                    {this.props.bulkSelecting && this.props.bulkSelected.length > 0 ? <PopupCustomList bulkSelected={this.props.bulkSelected} updateCustomList={this.props.updateCustomList} plan={this.props.plan} fetchCustomLists={this.props.fetchCustomLists} handleBulkSelecting={this.props.handleBulkSelecting} /> : ''}
                    {this.state.downloadPopup ? <PopupDownloadCSV csv={this.csv} /> : ''}
                    {this.props.filteredOrders.length !== 0 &&
                        <Button onClick={() => this.props.handleBulkSelecting()} icon={this.props.bulkSelecting ? '' : EnableSelectionMinor}>{this.props.bulkSelecting ? 'Cancel Selection' : 'Bulk Select'}</Button>
                    }
                    {
                        this.props.filteredOrders.length > 0 && isFeatureAvailableForPlan(this.props.plan, 'exports') &&
                        <Popover active={this.state.active} activator={activator} onClose={this.toggleActive}>
                            <ActionList
                                items={[
                                    {
                                        content: 'Save pick list as CSV',
                                        onAction: this.createPickListCSV
                                    },
                                    {
                                        content: 'Save summary as CSV',
                                        onAction: this.createSummaryCSV
                                    },
                                ]}
                            />
                        </Popover>
                    }
                    {this.props.tabs[this.props.selectedTab].manual && this.props.filteredOrders.length === 0 &&
                        <Button destructive onClick={() => this.deleteCustomList(this.props.tabs[this.props.selectedTab].id)}>Delete Custom List</Button>
                    }
                </Stack>
            </div>
        </Card>
    }
}
// export default connect(null, { downloadPickListCsv, downloadSummaryCsv })(Tools);
