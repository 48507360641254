import React, { Component } from 'react';
import { Banner, Page, Card, Button, Spinner } from '@shopify/polaris';
import { Context } from '@shopify/app-bridge-react';
import { connect } from 'react-redux'
import * as actions from '../../actions';
import { Redirect } from '@shopify/app-bridge/actions';
import { Redirect as RouterRedirect } from 'react-router'

class Account extends Component {

    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            working: false,
            contact: false
        }
        props.fetchPlan();
        props.fetchCustomLists();
    }

    componentDidUpdate(nextProps) {
        if (this.props.subscriptionUrl.length) {
            const url = this.props.subscriptionUrl
            this.setState({ working: false })
            if (url === 'subscription_cancelled') {
                this.setState({ redirect: true })
                return;
            }
            if (url === 'no_subscriptions_found') {
                this.setState({ redirect: true })
                return;
            }
            const redirect = Redirect.create(this.context);
            if (url.indexOf('/admin') > -1) {
                redirect.dispatch(Redirect.Action.ADMIN_PATH, {
                    path: url.split('/admin')[1]
                });
            }
        }
    }

    upgradePlan(plan) {
        this.setState({ working: true })
        this.props.fetchSubscriptionUrl(plan);
    }

    render() {
        if (this.state.redirect) {
            return (
                <RouterRedirect to={`/pick${window.location.search}`} />
            )
        }
        if (this.state.contact) {
            return (
                <RouterRedirect to={`/contact${window.location.search}`} />
            )
        }
        const currentPlan = this.props.plan === ''
            ? <Spinner />
            : <div className="account"><Card sectioned title="Account">
                <p>Your current account is: {this.props.plan === 'free' ? 'Free' : this.props.plan === 'goods' ? 'The Goods' : this.props.plan === 'greats' ? 'The Greats' : 'The Nuts'}</p>
            </Card>
                {this.props.plan !== 'goods' && this.props.customLists.length ?
                    <div>
                        <br />
                        <Banner key="banner">
                            Your account currently contains custom pick lists - these are only available on The Greats or Nuts plans.
                        </Banner>
                    </div> : ''}
            </div>

        return (
            <Page title="Account"
                breadcrumbs={[{ content: 'Pick', url: `/pick${window.location.search}` }]}>
                {currentPlan}
                {this.state.working ? <Spinner accessibilityLabel="Processing subscription" size="large" color="teal" /> :
                    <>
                        <div className="plans">
                            <div className="plan">
                                <Card sectioned title="Free">
                                    <p>Perfect for those wanting to give things a spin and try out our features, people with smaller shops or just cheapskates</p>
                                    <ul>
                                        <li>Access to all features</li>
                                        <li>Limited to 25 unfulfilled orders at once</li>
                                    </ul>
                                    <h2>$0/month</h2>
                                    <br />
                                    <Button disabled={this.props.plan === 'free'} onClick={() => this.upgradePlan('free')}>{this.props.plan === 'free' ? 'Current Plan' : 'Select Plan'}</Button>
                                </Card>
                            </div>
                            <div className="plan">
                                <Card sectioned title="The Goods">
                                    <p>Our standard plan with access to the main features and unlimited orders</p>
                                    <ul>
                                        <li>Unlimited orders</li>
                                        <li>Pick list</li>
                                        <li>Bundled SKU's</li>
                                        <li>Pre-packed orders</li>
                                        <li>Promo rules</li>
                                        <li>Sorting and filtering</li>
                                    </ul>
                                    <h2>$16/month</h2>
                                    <br />
                                    <Button disabled={this.props.plan === 'goods'} onClick={() => this.upgradePlan('goods')}>{this.props.plan === 'goods' ? 'Current Plan' : 'Select Plan'}</Button>
                                </Card>
                            </div>
                            <div className="plan">
                                <Card sectioned title="The Greats">
                                    <p>Set up multiple manual or automatic pick lists with custom formatting and alternative date filtering</p>
                                    <ul>
                                        <li>Everything in 'The Goods'</li>
                                        <li><strong>Custom dates</strong></li>
                                        <li><strong>Multiple pick lists</strong></li>
                                        <li><strong>Product Metafields</strong></li>
                                        <li><strong>Display product images</strong></li>
                                    </ul>
                                    <h2>$24/month</h2>
                                    <br />
                                    <Button disabled={this.props.plan === 'greats'} onClick={() => this.upgradePlan('greats')}>{this.props.plan === 'greats' ? 'Current Plan' : 'Select Plan'}</Button>
                                </Card>
                            </div>
                            <div className="plan">
                                <Card sectioned title="The Nuts">
                                    <p>The full works, access to our full suite of features and unlimited orders</p>
                                    <ul>
                                        <li>Everything in 'The Greats'</li>
                                        <li><strong>Locations support</strong></li>
                                        <li><strong>Fulfillment mode</strong></li>
                                        <li><strong>Export as CSV</strong></li>
                                    </ul>
                                    <h2>$36/month</h2>
                                    <br />
                                    <Button disabled={this.props.plan === 'nuts'} onClick={() => this.upgradePlan('nuts')}>{this.props.plan === 'nuts' ? 'Current Plan' : 'Select Plan'}</Button>
                                </Card>
                            </div>
                        </div>
                        {/* <div>
                            <div className="plan">
                                <Card sectioned title="Custom">
                                    <p>Is there something that Pick, Pack & Promo doesn't do that you'd love. Get in touch and we will see what we can do. If we can incorporate it, we will quote a custom monthly price.</p>
                                    <br />
                                    <Button onClick={() => this.setState({ contact: true })}>{this.props.plan === 'custom' ? 'Current Plan' : 'Get in touch'}</Button>
                                </Card>
                            </div>
                        </div> */}
                    </>
                }
            </Page>
        );
    }
}
const mapStateToProps = ({ subscriptionUrl, plan, customLists }) => {
    return {
        subscriptionUrl,
        plan,
        customLists
    };
};

export default connect(mapStateToProps, actions)(Account);
