import React, { Component } from 'react';
import { ResourceList, FilterType, Card, Stack, CalloutCard } from '@shopify/polaris';
import * as _ from 'lodash';
import DOMPurify from 'dompurify';

export class PickLabel extends Component {
	state = {
		orders: [],
		selectedTab: 0,
		selectedOrder: null,
	};
	loading = true;

	handleSelectionChange = picked => {
		this.setState({ picked });
	};

	buildOrderCard(order) {
		if (order.blank)
			return (
				<div key={`blank-${order.index}`} className="label">
					<div className="label-content"></div>
				</div>
			);
		const { id, shipping_code, shipping, note, redacted } = order;
		const renderShippingAddress = !order.shipping_address ? (
			''
		) : (
			<div>
				<p className="strong">
					{order.shipping_address.first_name} {order.shipping_address.last_name}
				</p>
				{order.shipping_address.company !== '' ? <p className="strong">{order.shipping_address.company}</p> : ''}
				<p>{order.shipping_address.address1}</p>
				<p>{order.shipping_address.address2}</p>
				<p>
					{order.shipping_address.city}, {order.shipping_address.province}
				</p>
				<p>
					{order.shipping_address.country}, {order.shipping_address.zip}
				</p>
			</div>
		);

		const renderShippingCode = !this.props.preferences.displayShippingDetails ? (
			''
		) : (
			<div>
				<br />
				{shipping_code}
			</div>
		);
		const renderShippingName = !this.props.preferences.displayShippingDescription ? (
			''
		) : (
			<div>
				<br />
				{shipping}
			</div>
		);
		const renderNoteInstructions = note ? (
			<span>
				<br />
				{note}
			</span>
		) : (
			''
		);

		const detailDisplay = (
			<Stack vertical="true">
				{this.props.preferences.displayOrderNotes ? renderNoteInstructions : ''}
				{/* {this.props.preferences.displayOrderProperties ? renderNoteAttributes : ''} */}
			</Stack>
		);

		const shortcutActions = [
			order.pick_status === 'flagged'
				? {
						content: 'Un-flag',
						onAction: e => {
							if (e !== undefined) e.preventDefault();
							this.props.updatePickedStatus(id, 'unpicked');
						},
				  }
				: {
						content: 'Flag',
						onAction: e => {
							if (e !== undefined) e.preventDefault();
							this.props.updatePickedStatus(id, 'flagged');
						},
				  },
			order.pick_status === 'picked'
				? {
						content: 'Un-Pick',
						onAction: e => {
							if (e !== undefined) e.preventDefault();
							this.props.updatePickedStatus(id, 'unpicked');
						},
				  }
				: {
						content: 'Picked',
						onAction: e => {
							if (e !== undefined) e.preventDefault();
							this.props.updatePickedStatus(id, 'picked');
						},
				  },
			{
				content: 'Select',
				onAction: e => {
					if (e !== undefined) e.preventDefault();
					this.props.updateBulkSelection('select', id);
				},
			},
			{
				content: 'Unselect',
				onAction: e => {
					if (e !== undefined) e.preventDefault();
					this.props.updateBulkSelection('unselect', id);
				},
			},
		];

		const isThisOrderSelected = _.find(this.props.bulkSelected, x => x === id) != null;
		const primaryAction = this.props.bulkSelecting ? (isThisOrderSelected ? shortcutActions[3] : '') : shortcutActions[1];
		const secondaryActions = this.props.bulkSelecting ? (isThisOrderSelected ? '' : [shortcutActions[2]]) : [shortcutActions[0]];

		if (!this.props.bulkSelecting && this.props.preferences.orderMode === 'fulfillment') {
			let push = false;
			if (this.props.selectedTab === 'pick-list' && this.props.preferences.fulfillFromMain) push = true;
			else if (this.props.selectedTab === 'picked' && this.props.preferences.fulfillFromPickedAndFlagged) push = true;
			else if (this.props.selectedTab === 'picked' && this.props.preferences.fulfillFromPickedAndFlagged) push = true;
			else if (this.props.preferences.fulfillFromCustom) push = true;
			if (push)
				secondaryActions.unshift({
					content: 'Fulfilled',
					onAction: e => {
						e.preventDefault();
						this.props.updatePickedStatus(id, 'fulfilled');
					},
				});
		}
		if (!this.props.bulkSelecting) {
			(this.props.customLists || []).forEach(list => {
				secondaryActions.push(
					this.props.selectedTab === list.id
						? {
								content: `Remove from ${list.content}`,
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updateCustomList(order.id, '');
								},
						  }
						: {
								content: `Move to ${list.content}`,
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updateCustomList(order.id, list.id);
								},
						  }
				);
			});
		}
		const renderHeader =
			this.props.preferences.logo.length > 0 || this.props.preferences.headerText.length > 0 ? (
				<div className="label-header">
					{this.props.preferences.logo.length ? (
						<img className="label-logo" src={this.props.preferences.logo} alt="Logo"></img>
					) : (
						<span></span>
					)}
					{this.props.preferences.headerText.length ? (
						<div>
							<pre dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.preferences.headerText || "")}}></pre>
						</div>
					) : (
						<span></span>
					)}
				</div>
			) : (
				''
			);
		const renderFooter =
			this.props.preferences.footerText.length > 0 ? (
				<div className="label-footer">
					<div>
						<pre dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.preferences.footerText || "")}}></pre>
					</div>
				</div>
			) : (
				''
			);

		// const renderOrderNumber = this.props.preferences.displayOrderNumber ? <Badge key={order.count} status='default'>{helpers.ordinal_suffix_of(order.count)} order</Badge> : '';
		const cardClass = this.props.bulkSelecting && !isThisOrderSelected ? 'noPrint label' : 'label';
		const redactedOrder = (
			<CalloutCard
				title="You have reached the limit of unfulfilled orders"
				illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
				primaryAction={{
					content: 'Upgrade account',
					url: `/account${window.location.search}`,
				}}
			>
				<p>
					The free account can only display up to 25 orders. Either fulfill some of your orders or consider upgrading your
					account.
				</p>
			</CalloutCard>
		);
		return (
			<div key={order.id} className={cardClass}>
				<div className="label-content">
					<Card secondaryFooterActions={secondaryActions} primaryFooterAction={primaryAction} key={id} sectioned>
						{renderHeader}
						{redacted ? (
							redactedOrder
						) : (
							<div>
								<div className="label-head-content">
									<div>
										{renderShippingAddress}
										{renderShippingCode}
										{renderShippingName}
									</div>
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<div>{detailDisplay}</div>
								</div>
							</div>
						)}
						{renderFooter}
					</Card>
				</div>
			</div>
		);
	}

	buildOrderCards(orders) {
		const arrayTo2DArray2 = (list, howMany) => {
			var idx = 0;
			const result = [];

			while (idx < list.length) {
				if (idx % howMany === 0) result.push([]);
				result[result.length - 1].push(list[idx++]);
			}
			if (result.length) {
				for (let index = 0; index < result[result.length - 1].length % howMany; index++) {
					result[result.length - 1].push({ blank: true, index });
				}
			}
			return result;
		};
		const renderGroups = [];
		const groups = arrayTo2DArray2(orders, parseInt(this.props.preferences.labelLayout));
		groups.forEach((group, ix) => {
			renderGroups.push(
				<div key={`gp-${ix}`}>
					<div className="label-group">
						{group.map(order => {
							return this.buildOrderCard(order);
						})}
					</div>
					{parseInt(this.props.preferences.labelLayout) === 1 ? <div className="printBreak"></div> : ''}
				</div>
			);
		});
		return renderGroups;
	}

	render() {
		const filterControl = this.props.preferences.enableFiltering ? (
			<ResourceList.FilterControl
				onFiltersChange={appliedFilters => {
					this.props.applyFilters(appliedFilters);
				}}
				onSearchChange={searchTerm => {
					this.props.setSearchTerm(searchTerm);
				}}
				searchValue={this.props.searchTerm}
				searchTerm={this.props.searchTerm}
				appliedFilters={this.props.appliedFilters}
				filters={[
					{
						key: 'orderDateFilterBefore',
						label: 'Date',
						operatorText: 'is',
						type: FilterType.DateSelector,
						dateOptionType: 'full',
						minKey: 'dateAfter',
						maxKey: 'dateBefore',
					},
					{
						key: 'orderItemSkuFilter',
						label: 'Contains SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderItemOnlySkuFilter',
						label: 'ONLY contains SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderItemNoSkuFilter',
						label: 'Does NOT contain SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderShipCodeFilter',
						label: 'Shipping Code',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderNoShipCodeFilter',
						label: 'Does NOT have Shipping Code',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
				]}
			/>
		) : (
			''
		);

		const filter = !this.props.preferences.enableFiltering ? (
			''
		) : (
			<div style={{ marginBottom: '20px' }}>
				<Card>
					<ResourceList
						resourceName={{ singular: 'Order', plural: 'Orders' }}
						loading={this.props.loading}
						items={[{}]}
						// showHeader={true}
						filterControl={filterControl}
						renderItem={() => {}}
					/>
				</Card>
			</div>
		);
		return (
			<div>
				{filter}
				<div className="labels">{this.buildOrderCards(this.props.orders)}</div>
			</div>
		);
	}
}
