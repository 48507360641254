
import { customListsRef } from "../config/firebase";
import { helpers } from '../helpers'
import * as request from 'request'
import {
    FETCH_CUSTOM_LISTS,
    FETCH_ERROR,
    FETCH_PARAMETERS_ERROR
} from "./types";

export const addCustomList = customList => async dispatch => {
    const ref = await customListsRef().add(customList)
    return ref.id
};


export const fetchCustomLists = (plan) => async dispatch => {
    if (plan === 'goods') return dispatch({ type: FETCH_CUSTOM_LISTS, payload: [] });
    helpers.dispatchAllFromCollection(customListsRef, FETCH_CUSTOM_LISTS, dispatch)
};

export const deleteCustomList = id => async dispatch => {
    await customListsRef().doc(id).delete()
    // dispatch({
    //     type: DELETE_CUSTOM_LIST
    // });
    return;
};

export const updateCustomList = customList => async dispatch => {
    await customListsRef().doc(customList.id).set(customList)
};

export const updateManualCustomList = picked => async dispatch => {

    let headers = (process.env.NODE_ENV === 'development') ? {
        'test': 'true'
    } : {
            'hmac': sessionStorage.hmac,
            'query': sessionStorage.query,
            'shop': sessionStorage.shopOrigin
        }

    request.get(`${process.env.REACT_APP_API_URL}/ppp/order/${picked.order_id}/custom_list/${picked.id}`, { headers }, (error, response, body) => {

        if (error) {
            console.error(error);
            dispatch({
                type: FETCH_ERROR,
                payload: { message: error }
            });
            return
        }
        if (response.statusCode !== 200) {
            if (response.statusCode === 401) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "No shop parameter provided", status: "no-shop" }
                });
                return
            }
            if (response.statusCode === 403) {
                dispatch({
                    type: FETCH_PARAMETERS_ERROR,
                    payload: { message: "App must be loaded from within Shopify", status: 'failed-validation' }
                });
                return
            }
        }
        // dispatch({
        //     type: UPDATE_PICKED,
        //     payload: true
        // });
    })
}
