
import React, { Component } from 'react';
import { Page, Card, TextField, PageActions, FormLayout, Button, DataTable, Link, Banner, Layout, Checkbox } from '@shopify/polaris';
import { helpers } from '../helpers'
import { connect } from 'react-redux'
import * as actions from '../actions';

class Pack extends Component {

    state = { id: '', name: '', code: '', ignorePromotions: false, productValue: '', quantityValue: '', promoValue: '', products: [], promotions: [], invalid: false, conditionInvalid: false, promoInvalid: false, redirect: false }

    constructor(props) {
        super(props);
        this.addSubProduct = this.addSubProduct.bind(this);
        this.addPromotion = this.addPromotion.bind(this);
        this.removeCondition = this.removeCondition.bind(this);
        this.removePromotion = this.removePromotion.bind(this);
    }

    componentWillMount() {
        let pathParts = this.props.location.pathname.split('/')
        if (pathParts.length === 3) {
            let id = pathParts[2]
            this.props.fetchPack(id);
            this.setState({ id })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.packs !== nextProps.packs) {
            this.setState(nextProps.packs);
        }
    }

    handleProductChange = productValue => this.setState({ productValue });
    handleQuantityChange = quantityValue => this.setState({ quantityValue });
    handlePromoChange = promoValue => this.setState({ promoValue });
    handleNameChange = name => this.setState({ name });
    handleCodeChange = code => this.setState({ code });
    handleIgnorePromotionsChange = ignorePromotions => this.setState({ ignorePromotions });

    productIsValid() {
        return this.state.quantityValue !== '' && this.state.productValue !== '';
    }
    promoIsValid() {
        return this.state.promoValue !== '';
    }

    addSubProduct() {
        if (!this.productIsValid()) {
            this.setState({ conditionInvalid: true })
            return;
        }
        this.setState((state) => {
            const products = state.products.concat({ id: helpers.generateId(), product: state.productValue, quantity: state.quantityValue });
            return { products }
        });
        this.setState({ conditionInvalid: false, productValue: '', quantityValue: '' })
    }

    addPromotion() {
        if (!this.promoIsValid()) {
            this.setState({ promoInvalid: true })
            return;
        }
        this.setState((state) => {
            const promotions = state.promotions.concat({ id: helpers.generateId(), code: state.promoValue });
            return { promotions }
        });
        this.setState({ promoInvalid: false, productValue: '' })
    }

    removeCondition(id) {
        let products = this.state.products.filter((c) => c.id !== id)
        this.setState({ products })
    }

    removePromotion(id) {
        let promotions = this.state.promotions.filter((c) => c.id !== id)
        this.setState({ promotions })
    }

    getSubProductsForDisplay() {
        return this.state.products.map((product) => [product.product, product.quantity, <Link onClick={() => this.removeCondition(product.id)}>Remove</Link>])
    }

    getPromotionsForDisplay() {
        return this.state.promotions.map((promo) => [promo.code, <Link onClick={() => this.removePromotion(promo.id)}>Remove</Link>])
    }

    isValid() {
        return this.state.name !== '' && this.state.code !== ''
    }

    async save() {
        if (!this.isValid()) {
            this.setState({ invalid: true });
            return;
        }
        // Save
        if (this.state.id === '') {
            let id = await actions.addPack({ name: this.state.name, code: this.state.code, products: this.state.products, promotions: this.state.promotions, ignorePromotions: this.state.ignorePromotions })()
            this.setState({ id })
            this.props.history.push(`/pack/${id}`)
        } else {
            actions.updatePack({ id: this.state.id, name: this.state.name, code: this.state.code, products: this.state.products, promotions: this.state.promotions, ignorePromotions: this.state.ignorePromotions })()
            this.props.history.push(`/packs`)
        }
    }

    render() {
        let dataTable, promotionsDataTable;
        let conditionError, promoError;
        let error;
        if (this.getSubProductsForDisplay().length > 0) {
            dataTable = <DataTable
                columnContentTypes={[
                    'text',
                    'numeric',
                    'text'
                ]}
                headings={[
                    'SKU',
                    'Quantity',
                    ''
                ]}
                rows={this.getSubProductsForDisplay()}
            />
        }

        if (this.getPromotionsForDisplay().length > 0) {
            promotionsDataTable = <DataTable
                columnContentTypes={[
                    'text',
                    'text'
                ]}
                headings={[
                    'Promotion code',
                    ''
                ]}
                rows={this.getPromotionsForDisplay()}
            />
        }
        if (this.state.conditionInvalid) {
            conditionError = <Banner
                title="Select product values"
                status="critical">
                <p>You must select a sku code and quantity to add a sub product to the pack</p>
            </Banner>
        }
        if (this.state.promoInvalid) {
            promoError = <Banner
                title="Select promotion code"
                status="critical">
                <p>You must select a code to add a promotion to the pack</p>
            </Banner>
        }

        if (this.state.invalid) {
            error = <Banner
                title="Bundle must have a name and a code"
                status="critical">
                <p>You must provide a name to identify the promotion and a SKU code for tagging and pick lists.</p>
            </Banner>
        }

        const renderProductsAndPromos = this.state.id === '' ? '' : <Layout.AnnotatedSection
            title="Packed Products & Promotions"
            description="These are the products that if all match an order then this pack will display"
        >
            <Card title="Products" sectioned>
                <FormLayout>
                    {dataTable}
                    {conditionError}
                    <FormLayout.Group condensed>
                        <TextField
                            label="SKU"
                            id="product"
                            onChange={this.handleProductChange}
                            value={this.state.productValue}
                            helpText="Products must have valid SKU codes (or the product value from a bundle) to be added to a pack"
                        />
                        <TextField
                            label="Quantity"
                            id="quantity"
                            onChange={this.handleQuantityChange}
                            value={this.state.quantityValue}
                        />
                    </FormLayout.Group>
                    <Button onClick={this.addSubProduct}>Add Product to Pack</Button>
                </FormLayout>
            </Card>
            <Card title="Promotions" sectioned>
                <FormLayout>
                    {promotionsDataTable}
                    {promoError}
                    <FormLayout.Group condensed>
                        <TextField
                            label="Code"
                            id="code"
                            onChange={this.handlePromoChange}
                            value={this.state.promoValue}
                        />
                    </FormLayout.Group>
                    <Button onClick={this.addPromotion}>Add Promotion to Pack</Button>
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>

        return (
                <Page title="Pre Packed"
                    breadcrumbs={[{ content: 'Pick', url: `/pick${window.location.search}` }, { content: 'Packs', url: `/packs${window.location.search}`}]}>
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            onClick: () => { this.save() }
                        }}
                    />
                    {error}

                    <Layout>
                        <Layout.AnnotatedSection
                            title="Prepacked settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        label="Pack name"
                                        type="text"
                                        value={this.state.name}
                                        onChange={this.handleNameChange}
                                        helpText="This is used to identify the bundle in case you need to edit it later."
                                    />
                                    <TextField
                                        label="Virtual SKU code"
                                        type="text"
                                        value={this.state.code}
                                        onChange={this.handleCodeChange}
                                        helpText="This is a code will appear in the pick list ONLY and replace the composite SKU codes."
                                    />
                                    <Checkbox
                                        checked={this.state.ignorePromotions}
                                        onChange={this.handleIgnorePromotionsChange}
                                        label="Ignore promotions"
                                        helpText="Promotions will still need to be packed if this is enabled, if promotions are already prepacked leave this off and set the promotions below"
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        {renderProductsAndPromos}
                    </Layout>
                </Page>
        );
    }
}

const mapStateToProps = ({ packs }) => {
    return {
        packs: packs
    };
};

export default connect(mapStateToProps, actions)(Pack);
