import React, { Component } from "react";
import { Banner, Link, Page, Card, PageActions, Tabs, Caption, Layout } from "@shopify/polaris";
import { WandMajorMonotone, StoreMajorMonotone, SettingsMajorMonotone } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import moment from "moment";
import { helpers } from "../helpers";
import * as actions from "../actions";
import * as _ from "lodash";
import { CompactSummary } from "./pick/CompactSummary";
import { DetailedSummary } from "./pick/DetailedSummary";
import { PickCard } from "./pick/PickCard";
import { PickLabel } from "./pick/PickLabel";
import { PickLine } from "./pick/PickLine";
import { Welcome } from "./Welcome";
import { Tools } from "./Tools";
import ReleaseUpdate from "./ReleaseUpdate";
import SetupWizard from "./settings/SetupWizard";
import { ExportService } from "../helpers/exporter";

export class Pick extends Component {
    constructor(props) {
        super(props);
        this.selectRow = this.selectRow.bind(this);
        this.updatePickedStatus = this.updatePickedStatus.bind(this);
        this.showHelp = this.showHelp.bind(this);
        this.updateBulkSelection = this.updateBulkSelection.bind(this);
        this.updateBulkPickedStatus = this.updateBulkPickedStatus.bind(this);
        this.updateCustomList = this.updateCustomList.bind(this);
        this.showSetup = this.showSetup.bind(this);
        this.handleBulkSelecting = this.handleBulkSelecting.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.props.fetchPickList();
        this.props.fetchPreferences();
        this.props.fetchPlan();
        this.props.fetchColorSkuList();
    }

    state = {
        bulkSelecting: false,
        bulkSelected: [],
        printSummary: true,
        orders: [],
        promotions: [],
        variants: [],
        packed: [],
        picked: [],
        selectedTab: 0,
        selectedOrder: null,
        preferences: {
            displayCustomerTags: false,
            displayOrderTags: false,
            expectSkuCodes: true,
            orderMode: "pick",
            syncLatest: false,
            firstRun: false,
            pickDisplayMode: "list",
            displayAddress: false,
            displayProductProperties: false,
            displayOrderProperties: false,
            displayOrderNotes: false,
            displayShippingDetails: false,
            displayShippingDescription: false,
            displayTotalWeight: false,
            displayOnAllOrders: false,
            displayOrderNumber: false,
            currentVersion: -1,
            logo: "",
        },
        customLists: [],
        plan: "",
        colorSkuList: [],
        displayListName: false,
    };
    loading = true;

    handlePrintSummaryChange = (printSummary) => {
        this.setState({ printSummary: printSummary });
    };

    handleDisplayListNameChange = (displayListName) => {
        this.setState({ displayListName: displayListName });
    };

    handleTabChange = (tab) => {
        this.setState({ selectedTab: tab });
    };

    handleBulkSelecting() {
        this.setState({
            bulkSelecting: !this.state.bulkSelecting,
            bulkSelected: [],
        });
    }

    async handleSync() {
        this.loading = false;
        await this.props.syncLatest();
        await this.props.syncProducts();
    }

    updateBulkSelection(action, id) {
        if (action === "select") {
            const selected = this.state.bulkSelected;
            selected.push(id);
            this.setState({ bulkSelected: selected });
        } else {
            this.setState({
                bulkSelected: _.reject(this.state.bulkSelected, (x) => x === id),
            });
        }
    }

    updateBulkPickedStatus(status) {
        for (const id of this.state.bulkSelected) {
            this.updatePickedStatus(id, status);
        }
        this.setState({ bulkSelected: [], bulkSelecting: false });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.preferences !== nextProps.preferences) {
            this.setState({ preferences: nextProps.preferences });
            if (nextProps.preferences.hidePrintedSummary) this.setState({ printSummary: false });
            if (nextProps.preferences.syncLatest === true || nextProps.preferences.firstRun === true) {
                this.handleSync();
            }
        }
        if (this.props.colorSkuList !== nextProps.colorSkuList) {
            this.setState({ colorSkuList: nextProps.colorSkuList });
        }
        if (this.props.plan !== nextProps.plan) {
            this.setState({ plan: nextProps.plan });
            this.props.fetchCustomLists(nextProps.plan);
        }
        if (this.props.customLists !== nextProps.customLists) {
            const filteredList = nextProps.customLists.filter((list) => {
                return list.disabled === undefined || !list.disabled;
            });
            this.setState({ customLists: filteredList });
        }
        if (this.props.syncCompleted !== nextProps.syncCompleted) {
            this.props.fetchColorSkuList();
            this.props.fetchPickList();
            this.loading = false;
            this.props.updatePreferences({
                firstRun: false,
                syncLatest: false,
            });
        }
        if (this.props.pick !== nextProps.pick) {
            (nextProps.pick.orders || []).forEach((order) => {
                (order.pick_list || []).forEach((line) => {
                    line.display = helpers.getProductDisplayTitle(this.props.preferences, line);
                });
            });
            this.setState({
                orders: nextProps.plan === "free" ? this.sortOrders(nextProps.pick.orders).slice(0, 25) : this.sortOrders(nextProps.pick.orders),
                variants: nextProps.pick.variants,
            });
            if (!this.state.preferences.syncLatest) this.loading = false;
        }
    }

    sortOrders(orders, sortMode) {
        let preSorted = orders;

        if (!sortMode || sortMode === "") {
            sortMode = this.state.preferences.sortMode;
        }
        switch (sortMode) {
            case "geo":
                preSorted = _.sortBy(preSorted, [`shipping_address.${this.state.preferences.groupByRegionMode}`, "date"]);
                break;
            case "name":
                preSorted = _.sortBy(preSorted, (o) => [o.name?.toLowerCase()]);
                break;
            case "delivery":
                preSorted = _.sortBy(preSorted, ["shipping_code", "date"]);
                break;
            case "payment":
                preSorted = _.sortBy(preSorted, ["financial_status", "date"]);
                break;
            case "date":
                preSorted = _.sortBy(preSorted, ["date"]);
                break;
            case "altDate":
                preSorted = _.sortBy(preSorted, ["date", "timeslot.timeStart", "timeslot.timeEnd"]);
                break;
            default:
                preSorted = _.sortBy(preSorted, ["date"]);
                break;
        }
        return preSorted;
    }

    filterCustomListOrders(orders, listId) {
        if (orders == null || !orders.length) return [];

        if (this.state.appliedFilters) {
            this.state.appliedFilters.forEach((filter) => {
                orders = this.applyFilters(orders, filter);
            });
        }
        orders = this.applySearch(orders);
        try {
            return orders.filter((order) => {
                if (order.pick_status !== "unpicked") return false;
                return order.custom_list === listId || (order.custom_lists || []).find((x) => x === listId);
            });
        } catch (error) {
            console.error(error);
            return orders;
        }
    }

    filterOrders(orders, selectedTab) {
        if (this.state.appliedFilters) {
            this.state.appliedFilters.forEach((filter) => {
                orders = this.applyFilters(orders, filter);
            });
        }
        orders = this.applySearch(orders);
        if (orders == null || !orders.length) return [];
        if (selectedTab.id === "pick-list")
            return orders.filter((order) => {
                let listIsValid = false;
                const customLists = order.custom_lists ? order.custom_lists : [];
                if (order.pick_status === "unpicked") {
                    let customListsValid = true;
                    let customListValid = true;
                    if (this.state.customLists.length === 0) listIsValid = true;
                    if ((order.custom_list == null || order.custom_list === undefined || order.custom_list === "") && customLists.length === 0) listIsValid = true;
                    if (customLists.length > 0) {
                        customListsValid = customLists.filter((customList) => this.state.customLists.filter((stateList) => stateList.id === customList && (!stateList.keepInPickList || stateList.keepInPickList === undefined)).length > 0).length === 0;
                    }
                    if (order.custom_list != null && order.custom_list !== undefined && order.custom_list !== "") {
                        customListValid = this.state.customLists.find((stateList) => stateList.id === order.custom_list && (!stateList.keepInPickList || stateList.keepInPickList === undefined)) === undefined;
                    }
                    if (customListValid && customListsValid) listIsValid = true;
                } else listIsValid = false;

                return listIsValid;
            });

        if (selectedTab.id === "picked")
            return orders.filter((order) => {
                return order.pick_status === "picked";
            });

        if (selectedTab.id === "flagged")
            return orders.filter((order) => {
                return order.pick_status === "flagged";
            });
        return [];
    }

    groupOrders(orders) {
        if (!orders.length) return orders;
        let groupOrder = this.state.preferences.groupOrders;
        let groupByMode = this.state.preferences.groupByMode;
        if (groupByMode === "") {
            groupByMode = this.state.preferences.sortMode === "geo" ? "location" : "date";
        }

        let groupedOrders = [];
        let tempGroup = [];
        switch (groupByMode) {
            case "delivery":
                let currentCarrier = orders[0].shipping_code;
                if (groupOrder) {
                    groupedOrders.push({ group: true, title: currentCarrier });
                }
                orders.forEach((order, i) => {
                    let compareCarrier = order.shipping_code != null ? order.shipping_code : "NO SHIPPING";
                    if (currentCarrier !== compareCarrier) {
                        groupedOrders.push(...this.groupSort(tempGroup));

                        currentCarrier = compareCarrier;
                        if (groupOrder) {
                            groupedOrders.push({ group: true, title: currentCarrier });
                        }
                        tempGroup = [];
                    }
                    tempGroup.push(order);
                    if (i === orders.length - 1) {
                        groupedOrders.push(...this.groupSort(tempGroup));
                    }
                });
                break;

            case "location":
                let currentRegion = orders[0].shipping_address != null ? orders[0].shipping_address[this.state.preferences.groupByRegionMode] : "No address";
                if (groupOrder) {
                    groupedOrders.push({ group: true, title: currentRegion });
                }
                orders.forEach((order, i) => {
                    let compareRegion = order.shipping_address != null ? order.shipping_address[this.state.preferences.groupByRegionMode] : "No address";
                    if (currentRegion !== compareRegion) {
                        groupedOrders.push(...this.groupSort(tempGroup));

                        currentRegion = compareRegion;
                        if (groupOrder) {
                            groupedOrders.push({ group: true, title: currentRegion });
                        }
                        tempGroup = [];
                    }
                    // groupedOrders.push(order);
                    tempGroup.push(order);
                    if (i === orders.length - 1) {
                        groupedOrders.push(...this.groupSort(tempGroup));
                    }
                });
                break;

            case "contact":
                const getContact = (order) => {
                    const { company, first_name, last_name } = order;
                    const name = `${first_name} ${last_name}`;
                    return `${this.props.preferences.displayContact && company && company !== "" ? company : name} ${
                        this.props.preferences.displayCompany && company && company !== "" ? (!this.props.preferences.displayContact ? ` c\\o ${company}` : "") : ""
                    }`;
                };
                const groups = _.groupBy(orders, (order) => _.trimEnd(getContact(order)));
                for (const key of Object.keys(groups)) {
                    if (groupOrder) {
                        groupedOrders.push({ group: true, title: key });
                    }
                    groupedOrders.push(...this.groupSort(groups[key]));
                    // groups[key].forEach((order) => groupedOrders.push(order));
                }
                break;

            case "payment":
                let currentPayment = orders[0].financial_status;
                if (groupOrder) {
                    groupedOrders.push({ group: true, title: currentPayment.replace("_", " ") });
                }
                orders.forEach((order, i) => {
                    if (currentPayment !== order.financial_status) {
                        groupedOrders.push(...this.groupSort(tempGroup));

                        currentPayment = order.financial_status;
                        if (groupOrder) {
                            groupedOrders.push({ group: true, title: currentPayment.replace("_", " ") });
                        }
                        tempGroup = [];
                    }
                    tempGroup.push(order);
                    if (i === orders.length - 1) {
                        groupedOrders.push(...this.groupSort(tempGroup));
                    }
                });
                break;

            case "timeslot":
                let currDate = new Date(orders[0].date);
                let currTime = orders[0].timeslot ? orders[0].timeslot.time : "";

                if (groupOrder) {
                    groupedOrders.push({
                        group: true,
                        title: `${moment(currDate).format("MMM Do, YYYY")} ${currTime}`,
                    });
                }
                orders.forEach((order, i) => {
                    const compareDate = new Date(order.date);
                    const compareTime = order.timeslot ? order.timeslot.time : "";
                    if (currDate.getDate() !== compareDate.getDate() || currDate.getMonth() !== compareDate.getMonth() || currDate.getFullYear() !== compareDate.getFullYear() || compareTime !== currTime) {
                        groupedOrders.push(...this.groupSort(tempGroup));

                        currDate = new Date(order.date);
                        currTime = compareTime;

                        if (groupOrder) {
                            groupedOrders.push({
                                group: true,
                                title: `${moment(currDate).format("MMM Do, YYYY")} ${currTime}`,
                            });
                        }
                        tempGroup = [];
                    }
                    tempGroup.push(order);
                    if (i === orders.length - 1) {
                        groupedOrders.push(...this.groupSort(tempGroup));
                    }
                });
                break;

            case "date":
            default:
                let currentDate = new Date(orders[0].date);
                if (groupOrder) {
                    groupedOrders.push({
                        group: true,
                        title: moment(currentDate).format("MMM Do, YYYY"),
                    });
                }
                orders.forEach((order, i) => {
                    const compareDate = new Date(order.date);
                    if (currentDate.getDate() !== compareDate.getDate() || currentDate.getMonth() !== compareDate.getMonth() || currentDate.getFullYear() !== compareDate.getFullYear()) {
                        groupedOrders.push(...this.groupSort(tempGroup));

                        currentDate = new Date(order.date);
                        if (groupOrder) {
                            groupedOrders.push({
                                group: true,
                                title: moment(currentDate).format("MMM Do, YYYY"),
                            });
                        }
                        tempGroup = [];
                    }
                    tempGroup.push(order);
                    if (i === orders.length - 1) {
                        groupedOrders.push(...this.groupSort(tempGroup));
                    }
                });
                break;
        }
        return groupedOrders;
    }

    groupSort(orders) {
        if (!orders) return;

        if (orders.length < 2 || !this.state.preferences.enableSecondarySorting) return orders;

        let sortedGroup = this.sortOrders(orders, this.state.preferences.secondaryGroupByMode);
        return sortedGroup;
    }

    applySearch(orders) {
        if (!this.state.searchTerm || !this.state.searchTerm.length) return orders;
        return orders.filter(
            (order) =>
                order.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1 ||
                order.order_number.toString().indexOf(this.state.searchTerm) > -1 ||
                (order.pick_list != null && order.pick_list.filter((item) => item.display.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1).length) ||
                (order.promotions != null && order.promotions.filter((promo) => promo.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1).length)
        );
    }

    showHelp() {
        this.setState({ showHelp: true });
    }

    showSetup() {
        this.setState({ showSetup: true });
    }

    applyFilters(orders, filter) {
        switch (filter.key) {
            case "orderItemSkuFilter":
                return orders.filter(
                    (order) =>
                        (order.pick_list.length > 0 && order.pick_list.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) !== undefined) ||
                        (typeof order.prepacked === "string" && order.prepacked !== null && order.prepacked !== undefined && order.prepacked.toUpperCase() === filter.value.toUpperCase()) ||
                        (typeof order.prepacked === "object" && order.prepacked.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) !== undefined)
                );
            case "orderItemOnlySkuFilter":
                return orders.filter(
                    (order) =>
                        (((typeof order.prepacked === "string" && order.prepacked === "") || (typeof order.prepacked === "object" && order.prepacked.length === 0)) &&
                            order.pick_list.length === 1 &&
                            order.pick_list.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) !== undefined) ||
                        (order.pick_list.length === 0 && typeof order.prepacked === "string" && order.prepacked !== null && order.prepacked !== undefined && order.prepacked.toUpperCase() === filter.value.toUpperCase()) ||
                        (order.pick_list.length === 0 &&
                            typeof order.prepacked === "object" &&
                            order.prepacked.length === 1 &&
                            order.prepacked.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) !== undefined)
                );
            case "orderItemNoSkuFilter":
                return orders.filter(
                    (order) =>
                        order.pick_list.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) === undefined &&
                        ((typeof order.prepacked === "string" && order.prepacked !== null && order.prepacked !== undefined && order.prepacked.toUpperCase() !== filter.value.toUpperCase()) ||
                            (typeof order.prepacked === "object" && order.prepacked.find((item) => item.sku !== null && item.sku !== undefined && item.sku.toUpperCase() === filter.value.toUpperCase()) === undefined))
                );
            case "orderShipCodeFilter":
                return orders.filter((order) => order.shipping_code !== null && order.shipping_code !== undefined && order.shipping_code.toUpperCase() === filter.value.toUpperCase());
            case "orderNoShipCodeFilter":
                return orders.filter((order) => order.shipping_code !== null && order.shipping_code !== undefined && order.shipping_code.toUpperCase() !== filter.value.toUpperCase());
            case "dateAfter":
            case "dateBefore":
            case "orderDateFilterBefore":
                let startDate = null;
                let endDate = null;

                switch (filter.value) {
                    case "past_year":
                        startDate = moment().subtract(1, "years").subtract(1, "days").endOf("day").toDate(); //new Date().setFullYear(today.getFullYear() - 1);
                        endDate = moment().endOf("day").toDate(); //new Date()
                        break;
                    case "past_quarter":
                        startDate = moment().subtract(3, "months").subtract(1, "days").endOf("day").toDate(); //new Date().setMonth(today.getMonth() - 3);
                        endDate = moment().endOf("day").toDate(); //new Date();
                        break;
                    case "past_month":
                        startDate = moment().subtract(1, "months").subtract(1, "days").endOf("day").toDate(); //new Date().setMonth(today.getMonth() - 1);
                        endDate = moment().endOf("day").toDate(); //new Date();
                        break;
                    case "past_week":
                        startDate = moment().subtract(8, "days").endOf("day").toDate(); //new Date().setDate(today.getDate() - 7);
                        endDate = moment().endOf("day").toDate(); //new Date();
                        break;
                    case "coming_year":
                        startDate = moment().startOf("day").toDate(); //new Date();
                        endDate = moment().add(1, "years").add(1, "days").startOf("day").toDate(); //new Date().setFullYear(today.getFullYear() + 1);
                        break;
                    case "coming_quarter":
                        startDate = moment().startOf("day").toDate(); //new Date();
                        endDate = moment().add(3, "months").add(1, "days").startOf("day").toDate(); //new Date().setMonth(today.getMonth() + 3);
                        break;
                    case "coming_month":
                        startDate = moment().startOf("day").toDate(); //new Date();
                        endDate = moment().add(1, "months").add(1, "days").startOf("day").toDate(); //new Date().setMonth(today.getMonth() + 1);
                        break;
                    case "coming_week":
                        startDate = moment().startOf("day").toDate(); //new Date();
                        endDate = moment().add(8, "days").startOf("day").toDate(); //new Date().setDate(today.getDate() + 7);
                        break;
                    default:
                        break;
                }
                if (filter.key === "dateAfter") {
                    startDate = moment(filter.value).subtract(1, "days").endOf("day").toDate(); //new Date(filter.value);
                    endDate = moment().add(1, "years").add(1, "days").startOf("day").toDate();
                }
                if (filter.key === "dateBefore") {
                    startDate = moment().subtract(1, "years").subtract(1, "days").endOf("day").toDate();
                    endDate = moment(filter.value).add(1, "days").startOf("day").toDate(); //new Date(filter.value);
                }

                return orders.filter((order) => {
                    return new moment(order.date).toDate() > startDate && moment(order.date).toDate() < endDate;
                });
            default:
                break;
        }
    }

    updatePickedStatus(id, status) {
        let orders = this.state.orders;
        orders.find((order) => order.id === id).pick_status = status;
        this.props.updatePickedStatus({ id, status });
        this.setState(orders);
    }

    updateCustomList(order_id, id) {
        let orders = this.state.orders;
        orders.find((order) => order.id === order_id).custom_list = id === "" ? null : id;
        this.props.updateManualCustomList({ order_id, id });
        this.setState(orders);
    }

    selectRow(id) {
        this.setState({ selectedOrder: id });
    }

    selectAll(tabs) {
        const list = tabs[this.state.selectedTab];
        let orders = [];
        if (list.panelID === "custom_list") {
            orders = this.filterCustomListOrders(this.state.orders, list.id).map((x) => x.id);
        } else {
            orders = this.filterOrders(this.state.orders, tabs[this.state.selectedTab]).map((x) => x.id);
        }
        this.setState({ bulkSelected: orders });
    }

    render() {
        console.log(this.state.support);
        const renderPopup =
            this.state.preferences.firstRun || this.state.showHelp ? (
                <Welcome
                    closeModal={() => {
                        this.setState({
                            showHelp: false,
                            showWhatsNew: false,
                        });
                    }}
                    showReleaseUpdates={() => {
                        this.setState({
                            showHelp: false,
                            showWhatsNew: true,
                        });
                    }}
                />
            ) : this.state.showWhatsNew || (this.state.preferences.currentVersion > 0 && this.state.preferences.currentVersion < parseFloat(process.env.REACT_APP_CURRENT_VERSION)) ? (
                <ReleaseUpdate />
            ) : this.state.showSetup ? (
                <SetupWizard
                    closeModal={(reload) => {
                        this.setState({
                            showSetup: false,
                        });
                        if (reload) {
                            window.location.reload();
                        }
                    }}
                />
            ) : (
                ""
            );

        const tabs = [
            {
                id: "pick-list",
                content: "Pick list",
                accessibilityLabel: "Current pick list",
                panelID: "pick-list",
            },
            {
                id: "picked",
                content: `Picked (${this.filterOrders(this.state.orders, { id: "picked" }).length})`,
                panelID: "picked",
            },
            {
                id: "flagged",
                content: `Flagged (${this.filterOrders(this.state.orders, { id: "flagged" }).length})`,
                panelID: "flagged",
            },
        ];
        for (const list of this.state.customLists) {
            const tabOrders = this.filterCustomListOrders(this.state.orders, list.id);
            tabs.push({
                id: list.id,
                content: `${list.content}${tabOrders.length ? ` (${tabOrders.length})` : ""}`,
                panelID: "custom_list",
                manual: list.manual,
            });
        }
        // if (this.state.preferences.summaryDisplayMode === 'detailed') {
        //     tabs.unshift({
        //         id: 'summary',
        //         content: 'Summary',
        //         accessibilityLabel: 'Pick list summary',
        //         panelID: 'summary',
        //     })
        // }
        let filteredOrders = [];
        let displayPreferences = this.state.preferences;
        if (tabs[this.state.selectedTab].panelID === "custom_list") {
            if (this.state.customLists != null) {
                const list = this.state.customLists.find((x) => x.id === tabs[this.state.selectedTab].id);
                if (list != null && list.custom === true && list.theme != null) {
                    displayPreferences = list.theme;
                }
            }
            filteredOrders = this.filterCustomListOrders(this.state.orders, tabs[this.state.selectedTab].id);
        } else {
            filteredOrders = this.filterOrders(this.state.orders, {
                id: tabs[this.state.selectedTab].id,
            });
        }
        const caption = this.loading ? "Fetching orders..." : this.state.selectedTab === 0 ? `${filteredOrders.length} orders to pick` : `${filteredOrders.length} orders in this list`;

        const summaryOrders = helpers.getSummary(this.state.bulkSelecting ? filteredOrders.filter((elm) => this.state.bulkSelected.includes(elm.id)) : filteredOrders);
        const orders = this.groupOrders(this.sortOrders(filteredOrders));
        const renderSummary = this.state.preferences.hideSummary ? (
            ""
        ) : this.state.preferences.summaryDisplayMode === "detailed" ? (
            <div className={this.state.printSummary === true ? "" : "noPrint"}>
                <DetailedSummary
                    orders={this.state.bulkSelecting ? filteredOrders.filter((elm) => this.state.bulkSelected.includes(elm.id)) : filteredOrders}
                    printSummary={this.state.printSummary}
                    colorSkuList={this.state.colorSkuList}
                    detailedSummary={this.state.preferences.detailedSummary}
                    displayItemFullfillableQuantity={this.state.preferences.displayItemFullfillableQuantity}
                    preferences={this.props.preferences}
                />
            </div>
        ) : (
            <div className={this.state.printSummary === true ? "" : "noPrint"}>
                <CompactSummary summaryOrders={summaryOrders} displayItemFullfillableQuantity={this.state.preferences.displayItemFullfillableQuantity} syncLatest={this.state.preferences.syncLatest} colorSkuList={this.state.colorSkuList} />
            </div>
        );
        const banner =
            this.state.plan === "free" ? (
                <Banner status="warning">
                    <p>
                        {`You are using the "${this.state.plan}" plan. Only 25 orders will be displayed. `}
                        <Link url={`/account${window.location.search}`}>Upgrade your Account</Link>.
                    </p>
                </Banner>
            ) : null;

        let currentCustomListTitle = "";

        if (this.state.customLists && this.state.customLists.length && tabs && tabs.length && typeof this.state.selectedTab === "number") {
            const selectedTabId = tabs[this.state.selectedTab].id;

            const currentCustomList = this.state.customLists.find((eachCustomList) => {
                return selectedTabId === eachCustomList.id;
            });

            if (currentCustomList) {
                currentCustomListTitle = currentCustomList.content;
            }
        }

        const renderPickList =
            displayPreferences.pickDisplayMode === "card" || displayPreferences.pickDisplayMode === "label" ? (
                <Layout>
                    <Layout.Section>
                        <div className={this.state.printSummary ? "" : "noPrint"}>
                            <Card>
                                <div className="noPrint">
                                    <Tabs tabs={tabs} selected={this.state.selectedTab} onSelect={this.handleTabChange} />
                                </div>
                                <div className="caption noPrint">
                                    <Caption>{caption}</Caption>
                                </div>
                            </Card>
                        </div>
                        <Tools
                            updateBulkPickedStatus={this.updateBulkPickedStatus}
                            selectAll={this.selectAll}
                            handleBulkSelecting={this.handleBulkSelecting}
                            handlePrintSummaryChange={this.handlePrintSummaryChange}
                            handleDisplayListNameChange={this.handleDisplayListNameChange}
                            bulkSelecting={this.state.bulkSelecting}
                            bulkSelected={this.state.bulkSelected}
                            updateCustomList={this.updateCustomList}
                            tabs={tabs}
                            plan={this.props.plan}
                            fetchCustomLists={this.props.fetchCustomLists}
                            preferences={this.state.preferences}
                            selectedTab={this.state.selectedTab}
                            handleTabChange={this.handleTabChange}
                            printSummary={this.state.printSummary}
                            displayListName={this.state.displayListName}
                            filteredOrders={filteredOrders}
                            downloadPickListCsv={() => new ExportService(this.state.preferences.exports).exportPickListCsv(filteredOrders)}
                            downloadSummaryCsv={() => new ExportService(this.state.preferences.exports).exportSummaryCsv(filteredOrders)}
                        />
                        <div className="printBreak"></div>
                    </Layout.Section>
                    <Layout.Section>
                        {renderSummary}
                        {displayPreferences.pickDisplayMode === "card" ? (
                            <PickCard
                                loading={this.loading}
                                selectedTab={tabs[this.state.selectedTab].id}
                                customLists={this.state.customLists.filter((list) => list.manual)}
                                colorSkuList={this.state.colorSkuList}
                                updatePickedStatus={this.updatePickedStatus}
                                selectRow={this.selectRow}
                                orders={orders}
                                variants={this.state.variants}
                                preferences={displayPreferences}
                                selectedOrder={this.state.selectedOrder}
                                appliedFilters={this.state.appliedFilters}
                                searchTerm={this.state.searchTerm}
                                bulkSelecting={this.state.bulkSelecting}
                                bulkSelected={this.state.bulkSelected}
                                updateBulkSelection={this.updateBulkSelection}
                                updateCustomList={this.updateCustomList}
                                applyFilters={(appliedFilters) => {
                                    this.setState({ appliedFilters });
                                }}
                                setSearchTerm={(searchTerm) => {
                                    this.setState({ searchTerm });
                                }}
                            />
                        ) : (
                            <PickLabel
                                loading={this.loading}
                                selectedTab={tabs[this.state.selectedTab].id}
                                customLists={this.state.customLists.filter((list) => list.manual)}
                                updatePickedStatus={this.updatePickedStatus}
                                selectRow={this.selectRow}
                                orders={this.sortOrders(filteredOrders)}
                                variants={this.state.variants}
                                preferences={displayPreferences}
                                selectedOrder={this.state.selectedOrder}
                                appliedFilters={this.state.appliedFilters}
                                searchTerm={this.state.searchTerm}
                                bulkSelecting={this.state.bulkSelecting}
                                bulkSelected={this.state.bulkSelected}
                                updateBulkSelection={this.updateBulkSelection}
                                updateCustomList={this.updateCustomList}
                                applyFilters={(appliedFilters) => {
                                    this.setState({ appliedFilters });
                                }}
                                setSearchTerm={(searchTerm) => {
                                    this.setState({ searchTerm });
                                }}
                            />
                        )}
                    </Layout.Section>
                </Layout>
            ) : (
                <Card id="pickList">
                    <div className="noPrint">
                        <Tabs tabs={tabs} selected={this.state.selectedTab} onSelect={this.handleTabChange} />
                    </div>
                    <Tools
                        updateBulkPickedStatus={this.updateBulkPickedStatus}
                        selectAll={this.selectAll}
                        handleBulkSelecting={this.handleBulkSelecting}
                        handlePrintSummaryChange={this.handlePrintSummaryChange}
                        handleDisplayListNameChange={this.handleDisplayListNameChange}
                        bulkSelecting={this.state.bulkSelecting}
                        bulkSelected={this.state.bulkSelected}
                        updateCustomList={this.updateCustomList}
                        tabs={tabs}
                        plan={this.props.plan}
                        fetchCustomLists={this.props.fetchCustomLists}
                        preferences={this.state.preferences}
                        selectedTab={this.state.selectedTab}
                        handleTabChange={this.handleTabChange}
                        printSummary={this.state.printSummary}
                        displayListName={this.state.displayListName}
                        filteredOrders={filteredOrders}
                        downloadPickListCsv={() => new ExportService(this.state.preferences.exports).exportPickListCsv(filteredOrders)}
                        downloadSummaryCsv={() => new ExportService(this.state.preferences.exports).exportSummaryCsv(filteredOrders)}
                    />
                    {this.state.displayListName && currentCustomListTitle && <h2 style={{ padding: "16px", fontWeight: "bold", fontSize: "24px" }}>{currentCustomListTitle}</h2>}
                    {renderSummary}
                    {this.state.preferences.hideSummary || orders.length === 0 ? (
                        ""
                    ) : (
                        <div className="noPrint">
                            <Card sectioned className="caption">
                                <Caption>{caption}</Caption>
                            </Card>
                        </div>
                    )}

                    <PickLine
                        loading={this.loading}
                        selectedTab={tabs[this.state.selectedTab].id}
                        customLists={this.state.customLists.filter((list) => list.manual)}
                        updatePickedStatus={this.updatePickedStatus}
                        selectRow={this.selectRow}
                        orders={orders}
                        variants={this.state.variants}
                        preferences={displayPreferences}
                        selectedOrder={this.state.selectedOrder}
                        appliedFilters={this.state.appliedFilters}
                        searchTerm={this.state.searchTerm}
                        bulkSelecting={this.state.bulkSelecting}
                        bulkSelected={this.state.bulkSelected}
                        updateBulkSelection={this.updateBulkSelection}
                        updateCustomList={this.updateCustomList}
                        applyFilters={(appliedFilters) => {
                            this.setState({ appliedFilters });
                        }}
                        setSearchTerm={(searchTerm) => {
                            this.setState({ searchTerm });
                        }}
                        colorSkuList={this.state.colorSkuList}
                    />
                </Card>
            );

        return (
            <Page>
                <div className="noPrint">
                    <PageActions
                        className="noPrint"
                        primaryAction={{
                            content: "Help",
                            onAction: this.showHelp,
                        }}
                        secondaryActions={[
                            {
                                content: "Configuration",
                                icon: SettingsMajorMonotone,
                                url: `/configuration${window.location.search}`,
                            },
                            {
                                content: "Account",
                                icon: StoreMajorMonotone,
                                url: `/account${window.location.search}`,
                            },
                            {
                                content: "Setup",
                                icon: WandMajorMonotone,
                                onAction: this.showSetup,
                            },
                        ]}
                    />
                </div>
                {
                    <style>{`
                    @media print {
                        .noPrint{display: none !important;}
                        .yesPrint, .yesPrint .Polaris-Avatar__Image{display: block !important;}
                    }`}</style>
                }
                {banner}
                {renderPickList}
                {renderPopup}
            </Page>
        );
    }
}
const mapStateToProps = ({ pick, preferences, syncCompleted, customLists, plan, support, colorSkuList, error }) => {
    return {
        pick,
        preferences,
        syncCompleted,
        customLists,
        plan,
        support,
        colorSkuList,
        error,
    };
};

export default connect(mapStateToProps, actions)(Pick);
