import { ColorSkuActionTypes } from '../actions/ColorSku.actions';

const INITIAL_STATE = { colorSku: [] }

const colorSkuReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ColorSkuActionTypes.ADD_COLOR_SKU:
            return {
                ...state,
                colorSku: addColorSku(state.colorSku, action.payload),
            };
        case ColorSkuActionTypes.EDIT_COLOR_SKU:
            return {
                ...state,
                colorSku: addColorSku(state.colorSku, action.payload),
            };

        case ColorSkuActionTypes.GET_COLOR_SKU_LIST:
            return {
                ...state,
                colorSku: action.payload,
            };
        case ColorSkuActionTypes.DELETE_COLOR_SKU:
            return {
                ...state,
                colorSku: deleteColorSku(state.colorSku, action.payload),
            };
        default:
            return state;
    }
};

const addColorSku = (colorSkuList, colorSkuToAdd) => {
    const existingColorSkuItem = findItem(colorSkuList, colorSkuToAdd);
    if (existingColorSkuItem) {
        return colorSkuList.map((colorSkuItem) =>
            colorSkuItem.sku === colorSkuToAdd.sku
                ? colorSkuToAdd
                : colorSkuItem
        );
    }
    return [colorSkuToAdd, ...colorSkuList];
}

export const clearSkuFromList = (colorSkuList, colorSkuToClear) => {
    return colorSkuList.filter((colorSkuItem) => colorSkuItem.id !== colorSkuToClear.id);
};

export const deleteColorSku = (colorSkuList, colorSkuToDelete) => {
    const existingColorSkuItem = findItem(colorSkuList, colorSkuToDelete);

    if (existingColorSkuItem) {
        return clearSkuFromList(colorSkuList, colorSkuToDelete);
    }

    return colorSkuList;
};

export const findItem = (colorSkuList, colorSkuToFind) => {
    return colorSkuList.find((colorSkuItem) => colorSkuItem.sku === colorSkuToFind.sku);
};

export default colorSkuReducer;
