
import { packsRef } from "../config/firebase";
import {
    DELETE_PACK,
    FETCH_PACKS,
    FETCH_PACK
} from "./types";
import { helpers } from '../helpers'

export const deletePack = id => async dispatch => {
    packsRef().doc(id).delete().then(() => {
        dispatch({
            type: DELETE_PACK
        });
    });
};

export const fetchPacks = () => async dispatch => {
    helpers.dispatchAllFromCollection(packsRef, FETCH_PACKS, dispatch)
};

export const fetchPack = (id) => async dispatch => {
    packsRef().doc(id).get().then(snapshot => {
        dispatch({
            type: FETCH_PACK,
            payload: snapshot.data()
        });
    })
};

export const addPack = pack => async dispatch => {
    let ref = await packsRef().add(pack)
    return ref.id
};

export const updatePack = pack => async dispatch => {
    packsRef().doc(pack.id).set(pack)
};
