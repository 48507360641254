import React, { Component } from "react";
import { Page, Card, ResourceList, Avatar, TextStyle, Button, Layout } from "@shopify/polaris";
import { connect } from "react-redux";
import * as actions from "../actions";

class Packs extends Component {
    componentWillMount() {
        this.props.fetchBundles();
        this.props.fetchPacks();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.packs !== nextProps.packs && this.props.bundles !== nextProps.bundles) {
            this.loading = false;
        }
    }

    handleRemoveBundle(id) {
        this.props.deleteBundle(id);
        this.props.fetchBundles();
    }

    handleRemovePack(id) {
        this.props.deletePack(id);
        this.props.fetchPacks();
    }

    render() {
        return (
            <Page title="Bundles & Prepacked" breadcrumbs={[{ content: "Configuration", url: `/configuration${window.location.search}` }]}>
                <Layout>
                    <Layout.AnnotatedSection title="Bundles" description="Bundles are SKU's that can be purchased that represent a group of products, bundles will display their composite sub products in the pick list.">
                        <Card sectioned>
                            <ResourceList
                                resourceName={{ singular: "bundle", plural: "bundles" }}
                                items={this.props.bundles}
                                loading={this.loading}
                                renderItem={(item) => {
                                    const { id, name, code } = item;
                                    const media = <Avatar customer size="medium" name={name} />;
                                    const shortcutActions = id ? [{ content: "Remove", onAction: () => this.handleRemoveBundle(id) }] : null;
                                    return (
                                        <ResourceList.Item id={id} url={`/bundle/${id}${window.location.search}`} media={media} accessibilityLabel={`View details for ${name}`} shortcutActions={shortcutActions} persistActions>
                                            <h3>
                                                <TextStyle variation="strong">{name}</TextStyle>
                                            </h3>
                                            <div>{code}</div>
                                        </ResourceList.Item>
                                    );
                                }}
                            />
                            <br />
                            <Button url={`/bundle${window.location.search}`}>Add new bundle</Button>
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Prepacked"
                        description="Orders that occur most commonly and are always the same, save time when fulfilling by pre-packing the orders. If an order contains EXACTLY the SKU items and promotions they are combined into a single item in the pick list."
                    >
                        <Card sectioned>
                            <ResourceList
                                resourceName={{ singular: "pack", plural: "packs" }}
                                items={this.props.packs}
                                loading={this.loading}
                                renderItem={(item) => {
                                    const { id, name, code } = item;
                                    const media = <Avatar customer size="medium" name={name} />;
                                    const shortcutActions = id ? [{ content: "Remove", onAction: () => this.handleRemovePack(id) }] : null;

                                    return (
                                        <ResourceList.Item id={id} url={`/pack/${id}${window.location.search}`} media={media} accessibilityLabel={`View details for ${name}`} shortcutActions={shortcutActions} persistActions>
                                            <h3>
                                                <TextStyle variation="strong">{name}</TextStyle>
                                            </h3>
                                            <div>{code}</div>
                                        </ResourceList.Item>
                                    );
                                }}
                            />
                            <br />
                            <Button url={`/pack${window.location.search}`}>Add new pack</Button>
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </Page>
        );
    }
}
const mapStateToProps = ({ bundles, packs }) => {
    return {
        bundles,
        packs,
    };
};

export default connect(mapStateToProps, actions)(Packs);
