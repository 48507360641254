import React, { Component } from "react";
import { Stack, Page, Checkbox, Card, PageActions, Tabs, Button, TextStyle, SettingToggle, TextField, FormLayout } from "@shopify/polaris";
import { Context } from "@shopify/app-bridge-react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Redirect as RouterRedirect } from "react-router";
import CustomListRules from "./CustomListRules";
import Theme from "./Theme";
import SetupWizard from "./SetupWizard";

export class CustomLists extends Component {
    state = {
        redirect: false,
        working: false,
        customLists: [],
        metafields: [],
        selected: 0,
        nameEdited: false,
        disabledEdited: false,
        keepInPickListEdited: false,
    };

    constructor(props) {
        super(props);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleKeepInPickListChange = this.handleKeepInPickListChange.bind(this);
        props.fetchPlan();
        props.fetchPreferences();
    }

    static contextType = Context;

    UNSAFE_componentWillMount() {
        this.props.fetchCustomLists();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.customLists !== this.props.customLists) {
            this.setState({ customLists: nextProps.customLists });
        }
        if (nextProps.preferences !== this.props.preferences) {
            this.setState({ metafields: nextProps.preferences.metafields || [] });
        }
    }

    async newCustomPickList() {
        await actions.addCustomList({
            content: `New List ${this.state.customLists.length + 1}`,
            disabled: false,
            keepInPickList: false,
            manual: true,
            custom: false,
        })();
        this.props.fetchCustomLists();
    }

    async deleteCustomList(id) {
        await actions.deleteCustomList(id)();
        this.setState({ selected: 0 });
        this.props.fetchCustomLists();
    }

    async setManual(customList, manual) {
        customList.manual = manual;
        await actions.updateCustomList(customList)();
        this.props.fetchCustomLists();
    }

    async setCustomDisplayOptions(customList, custom) {
        customList.custom = custom;
        await actions.updateCustomList(customList)();
        this.props.fetchCustomLists();
    }

    async saveCustomOptions(customList, theme) {
        customList.theme = theme;
        await actions.updateCustomList(customList)();
        this.props.fetchCustomLists();
    }

    async updateListData(customList) {
        await actions.updateCustomList(customList)();
        this.setState({
            nameEdited: false,
            enableDisableEdited: false,
            keepInPickListEdited: false,
            redirect: true,
        });
        this.props.fetchCustomLists();
    }

    handleNameChange(value) {
        const customLists = this.state.customLists;
        customLists[this.state.selected].content = value;
        this.setState({ customLists, nameEdited: true });
    }

    async handleEnableDisableChange(value) {
        const customLists = this.state.customLists;
        customLists[this.state.selected].disabled = value;
        this.setState({ customLists, enableDisableEdited: true });
    }

    async handleKeepInPickListChange(checked) {
        const customLists = this.state.customLists;
        customLists[this.state.selected].keepInPickList = checked;
        this.setState({ customLists, keepInPickListEdited: true });
    }

    render() {
        const handleTabChange = (ix) => {
            this.setState({ selected: ix });
        };
        if (this.state.redirect) {
            return <RouterRedirect to={`/configuration${window.location.search}`} />;
        }
        const thisList = this.state.customLists[this.state.selected];
        if (thisList != null && thisList.disabled === undefined) thisList.disabled = false;
        if (thisList != null && thisList.keepInPickList === undefined) thisList.keepInPickList = false;
        const theme = thisList != null && thisList.theme != null ? thisList.theme : SetupWizard.themePresets.detailed;
        return (
            <Page title="Custom Pick Lists" breadcrumbs={[{ content: "Configuration", url: `/configuration${window.location.search}` }]}>
                <PageActions
                    primaryAction={{
                        content: "Add custom list",
                        onAction: () => this.newCustomPickList(),
                    }}
                />
                <Card>
                    {this.state.customLists.length > 0 ? (
                        <Tabs tabs={this.state.customLists} selected={this.state.selected} onSelect={handleTabChange}>
                            <Card.Section>
                                <FormLayout>
                                    <TextField label="List Name" id="list-name" onChange={this.handleNameChange} value={thisList.content} />
                                    <Checkbox
                                        label="Keep orders in main Pick List"
                                        checked={thisList.keepInPickList}
                                        id="keep-in-pickList-checkbox"
                                        onChange={this.handleKeepInPickListChange}
                                        helpText="Orders in the custom list will be kept in the main Pick List too (only if order is not in any other custom list with different settings)"
                                    />
                                    <SettingToggle
                                        action={{
                                            content: thisList.disabled ? "Enable" : "Disable",
                                            onAction: () => {
                                                this.handleEnableDisableChange(!thisList.disabled);
                                            },
                                        }}
                                        enabled={!thisList.disabled}
                                    >
                                        This list is <TextStyle variation={thisList.disabled ? "negative" : "positive"}>{thisList.disabled ? "disabled" : "enabled"}</TextStyle>.
                                    </SettingToggle>
                                    {this.state.nameEdited || this.state.enableDisableEdited || this.state.keepInPickListEdited ? (
                                        <div style={{ marginBottom: "20px" }}>
                                            <Button onClick={() => this.updateListData(thisList)}>Save</Button>
                                        </div>
                                    ) : (
                                        <span></span>
                                    )}
                                </FormLayout>
                                {thisList.manual ? (
                                    <SettingToggle
                                        action={{
                                            content: "Make Dynamic",
                                            onAction: () => {
                                                this.setManual(thisList, false);
                                            },
                                        }}
                                    >
                                        You can add orders to this list <TextStyle variation="strong">manually</TextStyle> from the main pick list.
                                    </SettingToggle>
                                ) : (
                                    <Stack vertical>
                                        <SettingToggle
                                            action={{
                                                content: "Make Manual",
                                                onAction: () => {
                                                    this.setManual(thisList, true);
                                                },
                                            }}
                                            enabled={true}
                                        >
                                            Orders are <TextStyle variation="strong">automatically</TextStyle> added to this based on the rules below.
                                        </SettingToggle>
                                        <CustomListRules customList={thisList} preferences={this.props.preferences} plan={this.props.plan}></CustomListRules>
                                    </Stack>
                                )}
                                <br />
                                {thisList.custom === true ? (
                                    <Stack vertical>
                                        <SettingToggle
                                            action={{
                                                content: "Use default display options",
                                                onAction: () => {
                                                    this.setCustomDisplayOptions(thisList, false);
                                                },
                                            }}
                                            enabled={true}
                                        >
                                            Display options will use the <TextStyle variation="strong">custom</TextStyle> settings below.
                                        </SettingToggle>
                                        <Theme
                                            preferences={{ ...theme, metafields: this.state.metafields }}
                                            update={(theme) => this.saveCustomOptions(thisList, theme)}
                                            updateLogo={this.props.updateLogo}
                                            plan={this.props.plan}
                                            metafields={this.state.metafields}
                                            isProductSupport={this.props.preferences.productSupport}
                                            isCustomerSupport={this.props.preferences.customerSupport}
                                        />
                                    </Stack>
                                ) : (
                                    <SettingToggle
                                        action={{
                                            content: "Customize display options",
                                            onAction: () => {
                                                this.setCustomDisplayOptions(thisList, true);
                                            },
                                        }}
                                    >
                                        This list will use the <TextStyle variation="strong">default</TextStyle> display options.
                                    </SettingToggle>
                                )}
                                <br />
                                <Button destructive onClick={() => this.deleteCustomList(thisList.id)}>
                                    Delete list
                                </Button>
                                <div style={{ float: "right" }}>
                                    <Button onClick={() => this.updateListData(thisList)}>Save</Button>
                                </div>
                            </Card.Section>
                        </Tabs>
                    ) : (
                        ""
                    )}
                </Card>
            </Page>
        );
    }
}
const mapStateToProps = ({ preferences, customLists, plan }) => {
    return {
        plan,
        customLists,
        preferences,
    };
};

export default connect(mapStateToProps, actions)(CustomLists);
