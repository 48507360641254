import * as nonce from "nonce";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Stack, Modal, Layout } from "@shopify/polaris";
import { Redirect } from "@shopify/app-bridge/actions";
import { Context } from "@shopify/app-bridge-react";
import * as actions from "../../actions";

class ScopePermission extends Component {
    state = {
        active: true,
    };

    static contextType = Context;

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleUpgrade = this.handleUpgrade.bind(this);
    }

    handleClose() {
        this.setState({ active: false });
    }

    handleUpgrade() {
        const writeScopes = "write_orders,read_products,read_customers,read_inventory,read_third_party_fulfillment_orders,read_merchant_managed_fulfillment_orders";
        const state = nonce()();
        const url = `https://${this.props.shop}/admin/oauth/authorize?client_id=${process.env.REACT_APP_SHOPIFY_KEY}&scope=${writeScopes}&state=${state}&redirect_uri=${process.env.REACT_APP_APP_URL}/upgrade/callback`;
        const redirect = Redirect.create(this.context);
        redirect.dispatch(Redirect.Action.REMOTE, url);
    }

    render() {
        const { active } = this.state;
        return (
            <Modal
                open={active}
                onClose={this.handleCloase}
                title="We need to request some more permissions"
                primaryAction={{
                    content: "Upgrade permissions",
                    onAction: this.handleUpgrade,
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: this.handleClose,
                    },
                ]}
            >
                <Modal.Section>
                    <Layout>
                        <Layout.Section>
                            <Stack vertical>
                                <p>To enable this feature we need to request more permissions from Shopify, you will be redirected to a screen where you can authorize us to be able to access the functions we need.</p>
                            </Stack>
                        </Layout.Section>
                    </Layout>
                </Modal.Section>
            </Modal>
        );
    }
}

const mapStateToProps = ({ shop }) => {
    return {
        shop,
    };
};

export default connect(mapStateToProps, actions)(ScopePermission);
