import React, { Component } from 'react';
export class ColorBadge extends Component {
  getColor() {
    if (this.props.color != null && this.props.color.color != null) {
      return this.props.color.color;
    }
    switch (this.props.status) {
      case 'primary':
        return 'default';
      case 'warning':
      case 'default':
        return 'default';
      case 'new':
        return '#47c1bf';
      default:
        return 'default';
    }
  }
  getSecondaryColor() {
    if (this.props.color != null && this.props.color.colorSecondary != null) {
      return this.props.color.colorSecondary;
    }
    switch (this.props.status) {
      case 'new':
        return '#003135';
      default:
        return '#000000';
    }
  }

  getProgressIndicator(backgroundColor, secondaryColor) {
    switch (this.props.progress) {
      case 'complete':
        //Polaris-Badge--progressComplete
        return (
          <span
            style={{
              backgroundColor: backgroundColor,
              color: secondaryColor,
            }}
            className="Polaris-Badge Polaris-Badge--progressComplete"
          >
            <span className="Polaris-Badge__Pip" style={{ color: secondaryColor }}>
              <span className="Polaris-VisuallyHidden">Complete</span>
            </span>
            <span style={{ textDecoration: 'line-through' }}>
              {this.props.children}
            </span>
          </span>
        );
      case 'incomplete':
        //Polaris-Badge--progressIncomplete
        return (
          <span
            style={{
              backgroundColor: backgroundColor,
              color: secondaryColor,
            }}
            className="Polaris-Badge Polaris-Badge--progressIncomplete"
          >
            <span className="Polaris-Badge__Pip" style={{ color: secondaryColor }}>
              <span className="Polaris-VisuallyHidden">Incomplete</span>
            </span>
            {this.props.children}
          </span>
        );
      case 'partiallyComplete':
        //Polaris-Badge--progressPartiallyComplete
        return (
          <span
            style={{
              backgroundColor: backgroundColor,
              color: secondaryColor,
            }}
            className="Polaris-Badge Polaris-Badge--progressPartiallyComplete"
          >
            <span className="Polaris-Badge__Pip" style={{ color: secondaryColor }}>
              <span className="Polaris-VisuallyHidden">Partially complete</span>
            </span>
            {this.props.children}
          </span>
        );
      default:
        return (
          <span
            style={{
              backgroundColor: backgroundColor,
              color: secondaryColor,
            }}
            className="Polaris-Badge"
          >
            {this.props.children}
          </span>
        );
    }
  }

  render() {
    const backgroundColor = this.getColor();
    const secondaryColor = this.getSecondaryColor();
    const badgeLayout = this.getProgressIndicator(backgroundColor, secondaryColor);
    return badgeLayout;
  }
}
