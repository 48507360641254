import React, { Component } from 'react';
import { TextStyle, Badge, Card, Stack } from '@shopify/polaris';
import { ColorBadge } from './ColorBadge';
export class CompactSummary extends Component {
  orderGroupAndSort(items) {
    const comparator = (a, b) => {
      if (a.toUpperCase() > b.toUpperCase()) {
        return 1;
      }
      if (b.toUpperCase() > a.toUpperCase()) {
        return -1;
      }
      return 0;
    };
    return items != null ? Object.keys(items).sort(comparator) : [];
  }
  render() {
    let summary = '';
    let summaryOrders = this.props.summaryOrders;
    if (this.props.syncLatest) {
      summary = (
        <h1>
          Syncing latest orders - this can take some time, refresh to get the
          latest orders.
        </h1>
      );
    } else {
      const renderProductSummary = this.orderGroupAndSort(
        summaryOrders.products
      ).map((o) => {
        // find color in colorSkuList
        const color = this.props.colorSkuList && this.props.colorSkuList.colorSku && (summaryOrders.products[o].sku !== null && summaryOrders.products[o].sku !== undefined) ? 
        this.props.colorSkuList.colorSku.find((item) => item.sku === summaryOrders.products[o].sku.toUpperCase()) : null;
        let textDisplayLayout = o.split('<br />').join(' - ')//replaceAll('<br />', ' - ');
        return (
          <Stack key={Math.random()} spacing="extraTight">
            <ColorBadge
              key={o}
              color={color}
              status={
                summaryOrders.products[o].sku === 'NO-SKU'
                  ? 'warning'
                  : 'default'
              }
            >
              {textDisplayLayout}
              <TextStyle variation="strong">
                &nbsp;x&nbsp;
                {this.props.displayItemFullfillableQuantity
                  ? `(${summaryOrders.products[o].fulfillable_quantity} of ${summaryOrders.products[o].quantity})`
                  : summaryOrders.products[o].quantity}
              </TextStyle>
            </ColorBadge>
          </Stack>
        );
      });
      const renderPackedSummary = this.orderGroupAndSort(
        summaryOrders.packed
      ).map((o) => {
        // find color in colorSkuList
        const color = this.props.colorSkuList && this.props.colorSkuList.colorSku && (o !== null && o !== undefined) ? this.props.colorSkuList.colorSku.find((item) => item.sku === o.toUpperCase()) : null;
        let textDisplayLayout = o.split('<br />').join(' - ')//replaceAll('<br />', ' - ');
        return (
          <Stack key={Math.random()} spacing="extraTight">
            <ColorBadge
              className="summary-item"
              color={color}
              status="new"
              key={0}
            >
              {textDisplayLayout} x
              <TextStyle variation="strong">
                {summaryOrders.packed[o]}
              </TextStyle>
            </ColorBadge>
          </Stack>
        );
      });
      const renderPromotionsSummary = this.orderGroupAndSort(
        summaryOrders.promotions
      ).map((o) => {
        return (
          <Stack key={Math.random()} spacing="extraTight">
            <Badge className="summary-item" key={o} status="info">
              <TextStyle variation="strong">
                {summaryOrders.promotions[o]}
              </TextStyle>{' '}
              x {o}
            </Badge>
          </Stack>
        );
      });
      summary = (
        <div className="compact-summary">
          {renderProductSummary}
          {renderPackedSummary}
          {renderPromotionsSummary}
        </div>
      );
    }

    return <Card sectioned>{summary}</Card>;
  }
}
