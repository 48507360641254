import React, { Component } from 'react';
import { ColorBadge } from './ColorBadge';
import {
	Card,
	TextStyle,
	ResourceList,
	FilterType,
	DisplayText,
	Badge,
	Link,
	Caption,
	Stack,
	CalloutCard,
	Avatar,
	Subheading,
} from '@shopify/polaris';
import { helpers } from '../../helpers';
import * as moment from 'moment';
import * as _ from 'lodash';
import CurrencyFormatter from '../CurrencyFormatter';
import * as request from 'request'
import NumberFormat from 'react-number-format';

export class PickLine extends Component {
	state = {
		orders: [],
		products: [],
		promotions: [],
		packed: [],
		picked: [],
		selectedTab: 0,
		selectedOrder: null,
		weightUnit: ''
	};
	loading = true;

	getProductProperties(properties, displayProductProperties, propertyExclusion, displayOnAllOrders, selectedOrder) {
		if (propertyExclusion === undefined || propertyExclusion === null) propertyExclusion = [];
		const renderProps = properties.map(prop =>
			prop.name[0] === '_' || propertyExclusion.find(exclusion => exclusion.propertyName === prop.name) !== undefined ? (
				''
			) : (
				<Caption key={prop.name}>{`${prop.name}: ${prop.value}`}</Caption>
			)
		);
		return !displayProductProperties ? '' : displayOnAllOrders ? renderProps : selectedOrder ? renderProps : '';
	}

	handleSelectionChange = picked => {
		this.setState({ picked });
	};

	getPickList(
		prepacked,
		pick_list,
		displayProductProperties,
		propertyExclusion,
		displayOnAllOrders,
		selectedOrder,
		displayItemCharge,
		displayItemFullfillableQuantity,
		order,
		colorSkuList,
		displayFulfillmentLocation
	) {
		if (prepacked.length) {
			// find color in colorSkuList
			const color =
				colorSkuList && colorSkuList.colorSku && prepacked !== null && prepacked !== undefined
					? colorSkuList.colorSku.find(o => o.sku === prepacked.toUpperCase())
					: null;

			return (
				<li key={prepacked}>
					<Stack distribution="trailing" spacing="extraTight">
						<ColorBadge
							color={color}
							status="new"
							progress={
								displayItemFullfillableQuantity
									? order.fulfillment_status === 'fulfilled'
										? 'complete'
										: 'incomplete'
									: ''
							}
						>
							{prepacked}
						</ColorBadge>
					</Stack>
				</li>
			);
		} else {
			return pick_list.map(o => {
				// find color in colorSkuList
				const color =
					colorSkuList && colorSkuList.colorSku && o.sku !== null && o.sku !== undefined
						? colorSkuList.colorSku.find(item => item.sku === o.sku.toUpperCase())
						: null;
				// text to display
				const separatedPrice = this.props.preferences.displayMode === 'name_var_SKU_lines' ? <br /> : ' - ';
				let textDisplay = `${o.quantity} x ${o.display}`;
				textDisplay =
					displayItemFullfillableQuantity && o.fulfillable_quantity !== 0 && o.fulfillable_quantity !== o.quantity
						? `${o.fulfillable_quantity} of ${textDisplay}`
						: textDisplay;
				let textDisplayLayout = <span key={`${o.variant_id}-inner`} dangerouslySetInnerHTML={{ __html: textDisplay }} />;
				textDisplayLayout =
					displayItemCharge && o.price != null
						? [textDisplayLayout, separatedPrice, this.formatCurrency(o.price, order)]
						: textDisplayLayout;
				textDisplayLayout = <div>{textDisplayLayout}</div>;
				const variant = (this.props.variants || []).find(x => x.variant_id === o.variant_id);
				const image =
					this.props.preferences.displayVariantImage && variant !== undefined && variant.image !== null ? <Avatar source={variant.image.src} /> : null;
				const tags =
					this.props.preferences.displayProductTags && variant != null && variant.tags.length ? (
						<Badge status="success">{variant.tags}</Badge>
					) : null;
				const metafields =
					this.props.preferences.metafields &&
						this.props.preferences.metafields.length > 0 &&
						variant !== undefined &&
						variant.metafields
						? this.props.preferences.metafields.map(m => {
							const metafield = variant.metafields.find(
								field => field.namespace === m.namespace && field.key === m.key
							);
							if (metafield === undefined) return null;
							const metaId = `${metafield.namespace}.${metafield.key}`;
							const displayMetafield =
								this.props.preferences.displayMetafield &&
								this.props.preferences.displayMetafield[metaId] === true;
							return displayMetafield === true ? (
								<Badge key={metafield.id}>
									<Subheading>{m.description?.length ? m.description : metaId}:&nbsp;</Subheading>
									{metafield.value}
								</Badge>
							) : null;
						})
						: null;
				return (
					<li key={`${o.variant_id}-badge${Math.random()}`}>
						<div
							className="Polaris-Stack Polaris-Stack--alignmentLeading"
							style={{ justifyContent: 'flex-end', marginTop: '0px' }}
						>
							<div className={this.props.preferences.productSupport && this.props.preferences.includeImagesinPrint ? 'yesPrint' : ''}>
								{image}
							</div>
							<Stack vertical spacing="tight">
								<ColorBadge
									color={color}
									// status={o.display === 'NO-SKU' ? 'warning' : 'default'}
									progress={
										displayItemFullfillableQuantity
											? o.fulfillable_quantity > 0
												? o.fulfillable_quantity === o.quantity
													? 'incomplete'
													: 'partiallyComplete'
												: 'complete'
											: ''
									}
								>
									{textDisplayLayout}
								</ColorBadge>
								{displayFulfillmentLocation && o.assigned_location ? (
									<Badge status="info" posit key={o.assigned_location}>
										<Subheading>Location:</Subheading>
										{o.assigned_location}
									</Badge>
								) : null}
								{tags}
								{metafields}
								{this.getProductProperties(
									o.properties,
									displayProductProperties,
									propertyExclusion,
									displayOnAllOrders,
									selectedOrder
								)}
							</Stack>
						</div>
					</li>
				);
			});
		}
	}

	formatCurrency(number, order) {
		if (number >= 0 && order.currency != null && order.currency.length > 0)
			return <CurrencyFormatter key={Math.random()} price={number} curr={order.currency} locale={order.locale} />;
		else return number;
	}

	componentDidMount() {
		let headers = (process.env.NODE_ENV === 'development') ? {
			'test': 'true'
		} : {
			'hmac': sessionStorage.hmac,
			'query': sessionStorage.query,
			'shop': sessionStorage.shopOrigin
		}

		request.get(`${process.env.REACT_APP_API_URL}/ppp/weight-unit`, { headers }, (error, response, weightUnit) => {
			this.setState({ weightUnit })
		})
	}

	render() {
		const filterControl = this.props.preferences.enableFiltering ? (
			<ResourceList.FilterControl
				onFiltersChange={appliedFilters => {
					this.props.applyFilters(appliedFilters);
				}}
				onSearchChange={searchTerm => {
					this.props.setSearchTerm(searchTerm);
				}}
				searchValue={this.props.searchTerm}
				searchTerm={this.props.searchTerm}
				appliedFilters={this.props.appliedFilters}
				filters={[
					{
						key: 'orderDateFilterBefore',
						label: 'Date',
						operatorText: 'is',
						type: FilterType.DateSelector,
						dateOptionType: 'full',
						minKey: 'dateAfter',
						maxKey: 'dateBefore',
					},
					{
						key: 'orderItemSkuFilter',
						label: 'Contains SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderItemOnlySkuFilter',
						label: 'ONLY contains SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderItemNoSkuFilter',
						label: 'Does NOT contain SKU',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderShipCodeFilter',
						label: 'Shipping Code',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
					{
						key: 'orderNoShipCodeFilter',
						label: 'Does NOT have Shipping Code',
						type: FilterType.TextField,
						textFieldType: 'text',
					},
				]}
			/>
		) : (
			''
		);
		return (
			<ResourceList
				resourceName={{ singular: 'Order', plural: 'Orders' }}
				loading={this.props.loading}
				items={this.props.orders}
				selectedItems={this.state.picked}
				filterControl={filterControl}
				showHeader={false}
				onSelectionChange={this.handleSelectionChange}
				renderItem={item => {
					if (item.group) {
						return (
							<ResourceList.Item>
								<div
									className={this.props.bulkSelecting ? 'noPrint groupHeader' : 'groupHeader'}
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<DisplayText size="large">{item.title}</DisplayText>
								</div>
							</ResourceList.Item>
						);
					}
					const {
						id,
						order_name,
						name,
						company,
						phone,
						address,
						discount,
						shipping_code,
						shipping,
						note,
						note_attributes,
						count,
						total_weight,
						total_price,
						total_tax,
						total_shipping,
						tags,
						customerTags,
						pick_list,
						promotions,
						prepacked,
						redacted,
						date,
						original_date,
						financial_status,
						fulfillment_locations,
					} = item;

					const renderTags =
						tags.length && this.props.preferences.displayOrderTags
							? tags.split(',').map(o => (
								<li key={o}>
									<Badge status="attention">{o}</Badge>
								</li>
							))
							: '';
					const renderCustomerTags =
						customerTags.length && this.props.preferences.displayCustomerTags
							? customerTags.split(',').map(o => (
								<li key={o}>
									<Badge status="success">{o}</Badge>
								</li>
							))
							: '';
					const orderUrl = `https://${sessionStorage.shopOrigin}/admin/orders/${id}`;
					const orderName = (
						<span>
							<TextStyle variation="strong">
								{this.props.preferences.displayContact && company && company !== '' ? company : name}
								{this.props.preferences.displayCompany && company && company !== ''
									? !this.props.preferences.displayContact
										? ` c\\o ${company}`
										: ''
									: ''}
								{this.props.preferences.customerSupport && this.props.preferences.displayOrderNumber ? "'s" : ''}
							</TextStyle>
							{this.props.preferences.customerSupport && this.props.preferences.displayOrderNumber ? ` ${helpers.ordinal_suffix_of(count)} order` : ''}
						</span>
					);

					const shortcutActions = [
						item.pick_status === 'flagged'
							? {
								content: 'Un-flag',
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updatePickedStatus(id, 'unpicked');
								},
							}
							: {
								content: 'Flag',
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updatePickedStatus(id, 'flagged');
								},
							},
						item.pick_status === 'picked'
							? {
								content: 'Un-Pick',
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updatePickedStatus(id, 'unpicked');
								},
							}
							: {
								content: 'Picked',
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updatePickedStatus(id, 'picked');
								},
							},

						{
							content: 'Select',
							onAction: e => {
								if (e !== undefined) e.preventDefault();
								this.props.updateBulkSelection('select', id);
							},
						},
						{
							content: 'Unselect',
							onAction: e => {
								if (e !== undefined) e.preventDefault();
								this.props.updateBulkSelection('unselect', id);
							},
						},
					];

					const isThisOrderSelected = _.find(this.props.bulkSelected, x => x === id) != null;
					const primaryAction = this.props.bulkSelecting
						? isThisOrderSelected
							? shortcutActions[3]
							: ''
						: shortcutActions[1];
					const secondaryActions = this.props.bulkSelecting
						? isThisOrderSelected
							? ''
							: [shortcutActions[2]]
						: [shortcutActions[0]];

					if (!this.props.bulkSelecting && this.props.preferences.orderMode === 'fulfillment') {
						let push = false;
						if (this.props.selectedTab === 'pick-list' && this.props.preferences.fulfillFromMain) push = true;
						else if (this.props.selectedTab === 'picked' && this.props.preferences.fulfillFromPickedAndFlagged)
							push = true;
						else if (this.props.selectedTab === 'picked' && this.props.preferences.fulfillFromPickedAndFlagged)
							push = true;
						else if (this.props.preferences.fulfillFromCustom) push = true;
						if (push)
							secondaryActions.unshift({
								content: 'Fulfilled',
								onAction: e => {
									if (e !== undefined) e.preventDefault();
									this.props.updatePickedStatus(id, 'fulfilled');
								},
							});
					}
					if (!this.props.bulkSelecting) {
						(this.props.customLists || []).forEach(list => {
							secondaryActions.push(
								this.props.selectedTab === list.id
									? {
										content: `Remove from ${list.content}`,
										onAction: e => {
											if (e !== undefined) e.preventDefault();
											this.props.updateCustomList(item.id, '');
										},
									}
									: {
										content: `Move to ${list.content}`,
										onAction: e => {
											if (e !== undefined) e.preventDefault();
											this.props.updateCustomList(item.id, list.id);
										},
									}
							);
						});
					}
					const contactInfo = `${name}${phone && phone !== '' ? ` - ${phone}` : ''}`;
					const renderContactInfo = (
						<tr key="contact">
							<td>
								<Badge status="default">Contact</Badge>
							</td>
							<td>{contactInfo}</td>
						</tr>
					);
					const renderOrderDate = (
						<tr key="orderDate">
							<td>
								<Badge status="default">
									<TextStyle
										variation={this.props.preferences.altDateRetainOriginalDisplay === true ? 'strong' : 'normal'}
									>
										{this.props.preferences.altDateLabel != null && this.props.preferences.altDateLabel.length > 0
											? this.props.preferences.altDateLabel
											: 'Order Date'}
									</TextStyle>
								</Badge>
							</td>
							<td>{moment(date).format('MMM Do YYYY')}</td>
						</tr>
					);

					const renderOriginalOrderDate = (
						<tr key="originalOrderDate">
							<td>
								<Badge>
									{this.props.preferences.altDateRetainOriginalLabel != null &&
										this.props.preferences.altDateRetainOriginalLabel.length > 0
										? this.props.preferences.altDateRetainOriginalLabel
										: 'Original Order Date'}
								</Badge>
							</td>
							<td>{moment(original_date).format('Do MMM, YYYY')}</td>
						</tr>
					);

					const renderNoteAddress = (
						<tr key="shipping-address">
							<td>
								<Badge status="default">Shipping Address</Badge>
							</td>
							<td>{address}</td>
						</tr>
					);
					const renderShippingCode = (
						<tr key="shipping-method">
							<td>
								<Badge status="default">Shipping method</Badge>
							</td>
							<td>{shipping_code}</td>
						</tr>
					);
					const renderShippingName = (
						<tr key="shipping">
							<td>
								<Badge status="default">Shipping</Badge>
							</td>
							<td>{shipping}</td>
						</tr>
					);
					const renderDisplayDiscount = (
						<li key={discount}>
							<Badge status="info">{discount}</Badge>
						</li>
					);
					const renderNoteInstructions = (
						<tr key="notes">
							<td>
								<Badge status="default">Notes</Badge>
							</td>
							<td><div dangerouslySetInnerHTML={{ __html: note }} /></td>
						</tr>
					);
					const renderFulfillmentLocations = (
						<tr key="fulfillment_locations">
							<td>
								<Badge status="default">Locations</Badge>
							</td>
							<td>{fulfillment_locations}</td>
						</tr>
					);
					const attributes = note_attributes.map((n, i) => {
						return (
							<tr key={n.toString() + i}>
								<td>
									<Badge status="default">{n.name}</Badge>
								</td>
								<td>{n.value}</td>
							</tr>
						);
					});

					const paymentStatus = (
						<tr key="payment">
							<td>
								<Badge status="default">Payment Status</Badge>
							</td>
							<td>{financial_status}</td>
						</tr>
					);
					const renderPaymentStatus = !this.props.preferences.displayPaymentStatus
						? ''
						: this.props.selectedOrder === id
							? paymentStatus
							: this.props.preferences.displayOnAllOrders
								? paymentStatus
								: '';

					let rows = [];
					if (this.props.preferences.displayOrderDate) rows.push(renderOrderDate);
					if (this.props.preferences.altDateRetainOriginalDisplay === true) rows.push(renderOriginalOrderDate);
					if (this.props.preferences.displayContact) rows.push(renderContactInfo);
					if (this.props.preferences.displayAddress) rows.push(renderNoteAddress);
					if (this.props.preferences.displayShippingDetails) rows.push(renderShippingCode);
					if (this.props.preferences.displayShippingDescription) rows.push(renderShippingName);
					if (this.props.preferences.displayPaymentStatus) rows.push(renderPaymentStatus);
					if (this.props.preferences.displayOrderNotes && note) rows.push(renderNoteInstructions);
					if (this.props.preferences.displayFulfillmentLocations && fulfillment_locations)
						rows.push(renderFulfillmentLocations);
					if (this.props.preferences.displayOrderProperties && note_attributes.length) rows.push(attributes);
					const detailDisplay = (
						<table className="line-detail-display">
							<tbody>{rows}</tbody>
						</table>
					);

					const shippingAndTaxes = (
						<div>
							<br />
							<Badge size="large">Shipping: {this.formatCurrency(total_shipping, item)}</Badge>
							<br />
							<Badge size="large">Taxes: {this.formatCurrency(total_tax, item)}</Badge>
						</div>
					);
					const orderTotal = (
						<div>
							<br />
							<Badge status="attention" size="large">
								<TextStyle variation="strong">Total: {this.formatCurrency(total_price, item)}</TextStyle>
							</Badge>
						</div>
					);
					const renderTotalWeight = this.props.preferences.displayTotalWeight ? (
						<div>
							<br />
							<Badge status="attention">
								<NumberFormat
									value={helpers.calculateWeight(total_weight, this.state.weightUnit)}
									displayType={'text'}
									thousandSeparator={true}
									prefix="Weight : "
									suffix={` ${this.state.weightUnit}`}
									decimalScale={2}
								/>
							</Badge>
						</div>
					) : (
						''
					);
					const renderShippingAndTaxes = !this.props.preferences.displayShippingAndTaxes
						? ''
						: this.props.selectedOrder === id
							? shippingAndTaxes
							: this.props.preferences.displayOnAllOrders
								? shippingAndTaxes
								: '';

					const renderOrderTotal = !this.props.preferences.displayOrderTotal
						? ''
						: this.props.selectedOrder === id
							? orderTotal
							: this.props.preferences.displayOnAllOrders
								? orderTotal
								: '';

					const spacer = this.props.selectedOrder === id ? <div style={{ marginTop: '40px' }}></div> : '';

					const renderNotes =
						this.props.selectedOrder === id
							? detailDisplay
							: this.props.preferences.displayOnAllOrders
								? detailDisplay
								: '';
					const rowClass = this.props.bulkSelecting && !isThisOrderSelected ? 'noPrint pickRow' : 'pickRow';

					const redactedOrder = (
						<CalloutCard
							title="You have reached the limit of unfulfilled orders"
							illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
							primaryAction={{
								content: 'Upgrade account',
								url: `/account${window.location.search}`,
							}}
						>
							<p>
								The free account can only display up to 25 orders. Either fulfill some of your orders or consider
								upgrading your account.
							</p>
						</CalloutCard>
					);
					return (
						<div key={id}>
							<Card secondaryFooterActions={secondaryActions} primaryFooterAction={primaryAction} key={id} sectioned>
								{redacted ? (
									redactedOrder
								) : (
									<div
										className={rowClass}
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<div className="mainContent">
											<div className="headerRow">
												<h3>
													<Link external="true" url={orderUrl}>
														<TextStyle variation="strong">{order_name}</TextStyle>
													</Link>{' '}
													{orderName}
												</h3>
											</div>
											{renderNotes}
										</div>
										<div>
											<ul>
												{renderTags}
												{renderCustomerTags}
											</ul>
										</div>
										<ul style={{ textAlign: 'right', flexGrow: 4, marginTop: '10px' }}>
											{spacer}
											{this.getPickList(
												prepacked,
												pick_list,
												this.props.preferences.displayProductProperties,
												this.props.preferences.propertyExclusion,
												this.props.preferences.displayOnAllOrders,
												this.props.selectedOrder === id,
												this.props.preferences.displayItemCharge,
												this.props.preferences.displayItemFullfillableQuantity,
												item,
												this.props.colorSkuList,
												this.props.preferences.displayFulfillmentLocation
											)}
											{this.props.preferences.displayDiscountCode && discount && discount.length
												? renderDisplayDiscount
												: ''}
											{promotions.map(o => (
												<li key={o}>
													<Badge status="info">{o}</Badge>
												</li>
											))}
											{renderTotalWeight}
											{renderShippingAndTaxes}
											{renderOrderTotal}
										</ul>
									</div>
								)}
							</Card>
						</div>
					);
				}}
			/>
		);
	}
}
