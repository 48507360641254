import { FETCH_PACKS, FETCH_PACK } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PACKS:
      return action.payload;
      case FETCH_PACK:
        return action.payload;
    default:
      return state;
  }
};
