import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'

import './index.css';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";
import { Provider } from "react-redux";
import { I18nContext, I18nManager } from '@shopify/react-i18n';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
const locale = 'en';
const i18nManager = new I18nManager({
    locale,
    onError(error) {
        console.error(error);
    },
});

ReactDOM.render(
    <Provider store={store}>
        <I18nContext.Provider value={i18nManager}>
            <App />
        </I18nContext.Provider>
    </Provider>, document.getElementById('root'));


serviceWorker.unregister();
