import { groupBy, sumBy, sum } from 'lodash';
import moment from 'moment';
import { SetupWizard } from '../components/settings/SetupWizard';

export class ExportService {

    config;
    headers = [];
    rows = [];

    constructor(_config) {
        this.config = _config || SetupWizard.exportConfigurationPreset;
    }

    exportPickListDataCSV(orders) {
        this.getHeader(orders, 'pick_list')
        this.getRows(orders)
        return { headers: this.headers, data: this.rows, filename: `PPP-Orders-${moment().format('YYYYMMDD')}.csv` }
    }

    exportSummaryDataCSV(orders) {
        this.getHeader(orders, 'summary')
        const data = this.getSummaryData(orders);
        this.getAggregatedRowData(data, this.headers);
        return { headers: this.headers, data: this.rows, filename: `PPP-OrderSummary-${moment().format('YYYYMMDD')}.csv` }
    }

    getHeader(orders, type) {
        this.headers = this.getHeaders(type, orders).map(header => {
            return { label: header, key: header }
        })
    }

    getSummaryData(orders) {
        return orders.map(({ pick_list }) => pick_list).flat();
    }

    getAggregatedRowData(data, headers) {
        const items = groupBy(data, 'display');
        this.rows = Object.values(items).map(subItems => {
            const data = headers.map(header => {
                const key = header.key
                const value = this.extractAggregatedData(key, subItems)
                return { [key]: value }
            })
            const row = data.reduce((obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {});
            return row
        })
    }

    getRows(orders) {
        this.rows = this.config.ordersExportMode === 'order'
            ? orders.map(order => this.getOrderRowData(order, this.headers))
            : orders.map(order => order.pick_list.map(item => this.getItemRowData(order, item, this.headers))).flat(1);
    }

    getOrderRowData(order, headers) {
        const data = headers.map(header => {
            const key = header.key
            const value = this.extractData(key, order)
            return { [key]: value }
        })
        const row = data.reduce((obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {});
        return row
    }

    getItemRowData(order, item, headers) {
        const data = headers.map(header => {
            const key = header.key
            const value = this.extractData(header.key, order, item)
            return { [key]: value }
        })
        const row = data.reduce((obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {});
        return row
    }

    extractAggregatedData(column, items) {
        switch (column) {
            case 'product_sku':
                return items[0].sku;
            case 'product_name':
                return items[0].display;
            case 'item_charge':
                return sum(items.map(item => parseFloat(item.price)));
            case 'quantity':
                return sumBy(items, 'quantity');
            default:
                break;
        }
    }

    extractData(column, order, line_item = null) {
        switch (column) {
            case 'order_name':
            case 'name':
            case 'first_name':
            case 'last_name':
            case 'company':
            case 'customer_email':
            case 'phone':
            case 'note':
            case 'shipping':
            case 'shipping_code':
            case 'total_tax':
            case 'total_weight':
            case 'date':
            case 'address':
            case 'discount':
                const value = order[column];
                return this.sanitizeForCsv(value);
            case 'total_price':
                return order['price'];
            case 'customerTags':
            case 'tags':
                const tags = order[column];
                return this.sanitizeForCsv(tags);
            case 'note_attributes':
                const attributes = order[column];
                if (attributes == null || attributes.length === 0) return '';
                return attributes.map(attr => `${this.sanitizeForCsv(attr.name)}: ${this.sanitizeForCsv(attr.value)}|`);

            default:
                break;
        }
        if (column.indexOf('shipping_address-') > -1) {
            // get info from shipping address
            const shipping_address = order['shipping_address'];
            if (shipping_address) {
                const field = column.split('shipping_address-')[1]
                console.log(field)
                return shipping_address[field]
            }
            else return ''
            
        }
        if (column.indexOf('attr-') > -1) {
            const attributes = order['note_attributes'];
            if (attributes == null || attributes.length === 0) return '';
            const attr = attributes.find(attr => attr.name === column.split('attr-')[1]);
            return attr == null ? '' : this.sanitizeForCsv(attr.value);
        }
        if (column.indexOf('item-') > -1) {
            const pick = order['pick_list'];
            if (pick == null || pick.length === 0) return '';
            const item = pick.find(i => i.display === column.split('item-')[1]);
            return item != null ? item.quantity : 0;
        }
        if (line_item != null) {
            switch (column) {
                case 'price':
                    return line_item.price;
                case 'display':
                    return line_item.display.split('<br />').join(' - ');
                case 'quantity':
                    return line_item.quantity;
                default:
                    break;
            }
            if (column.indexOf('prop-') > -1) {
                if (line_item == null) return '';
                const props = line_item['properties'];
                if (props == null || props.length === 0) return '';
                const item = props.find(i => i.name === column.split('prop-')[1]);
                return item == null ? '' : this.sanitizeForCsv(item.value);
            }
        }
    }


    getHeaders(mode, orders) {
        const headers = []
        if (mode === 'pick_list') {
            headers.push('order_name');
            if (this.config.displayOrderDate) { headers.push('date') };
            if (this.config.displayContact) { headers.push('name') };
            if (this.config.displayFirstName) { headers.push('first_name') };
            if (this.config.displayLastName) { headers.push('last_name') };
            if (this.config.displayCompany) { headers.push('company') };
            if (this.config.displayEmail) { headers.push('email') };

            if (this.config.ordersExportMode === 'line_item') { headers.push('display') };
            if (this.config.ordersExportMode === 'line_item') { headers.push('quantity') };
            // if (this.config.displayProductProperties) { headers.push('productProperties')};
            if (this.config.displayOrderNotes) { headers.push('note') };
            if (this.config.displayCustomerTags) { headers.push('customerTags') };
            if (this.config.displayOrderTags) { headers.push('tags') };
            if (this.config.displayShippingPhone) { headers.push('phone') };
            if (this.config.displayShippingDetails) { headers.push('shipping') };
            if (this.config.displayShippingDescription) { headers.push('shipping_code') };
            if (this.config.displayCustomerEmail) { headers.push('customer_email') };
            if (this.config.displayAddress) { headers.push('address') };
            if (this.config.displayAddressStreet1) { headers.push('shipping_address-address1') };
            if (this.config.displayAddressStreet2) { headers.push('shipping_address-address2') };
            if (this.config.displayAddressCity) { headers.push('shipping_address-city') };
            if (this.config.displayAddressProvince) { headers.push('shipping_address-province') };
            if (this.config.displayAddressCountry) { headers.push('shipping_address-country') };
            if (this.config.displayAddressZip) { headers.push('shipping_address-zip') };
            if (this.config.displayItemCharge) { headers.push('price') };
            if (this.config.displayItemFullfillableQuantity) { (headers.push('fullfillable_quantity')) };
            if (this.config.displayShippingAndTaxes) { headers.push('total_tax') };
            if (this.config.displayTotalWeight) { headers.push('total_weight') };
            if (this.config.displayOrderTotal) { headers.push('total_price') };
            if (this.config.displayDiscountCode) { headers.push('discount') };
            if (this.config.displayOrderProperties && orders.length) { headers.push(...this.getAttributeHeaders(orders.map(order => order.note_attributes))) };
            if (this.config.ordersExportMode === 'order' && orders.length) { headers.push(...this.getProductHeaders(orders.map(order => order.pick_list))) };
            if (this.config.ordersExportMode === 'line_item' && this.config.displayProductProperties && orders.length) { headers.push(...this.getItemPropHeaders(orders.map(order => order.pick_list))) };
        }

        if (mode === 'summary') {
            if (this.config.displaySummarySKU) { headers.push('product_sku') };
            if (this.config.displaySummaryProductName) { headers.push('product_name') };
            if (this.config.displaySummaryQuantity) { headers.push('quantity') };
            if (this.config.displaySummaryItemCharge) { headers.push('item_charge') };
        }
        return headers;
    }

    getAttributeHeaders(attributes) {
        return [...new Set(attributes.flat(1).map(attr => `attr-${attr.name}`))]
    }

    getProductHeaders(items) {
        return [...new Set(items.flat(1).map(item => `item-${item.display}`))]
    }
    getItemPropHeaders(items) {
        return [...new Set(items.flat(1).map(item => item.properties).flat().map(prop => `prop-${prop.name}`))]
    }

    sanitizeForCsv(value) {
        return (value == null ? '' : (value.toString() || '')).replace(/,/g, '.');
    }
}
