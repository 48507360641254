import React, { Component } from 'react';
import { DatePicker, TextField, FormLayout, Select, Stack, Button, Avatar, ResourceList, Banner, TextStyle } from '@shopify/polaris';
import { Context } from '@shopify/app-bridge-react';
import * as actions from '../../actions';
import {
	financialRules,
	metafieldRules,
	propertyRules,
	tagRules,
	fulfillmentLocationRules,
	locationRules,
	dayWeekRule,
	dateRules,
	orderSkuRules,
	ruleTypes,
	getRuleDescription,
	vendorRules
} from './custom.list.rule.types';

export default class CustomListRules extends Component {
	static contextType = Context;

	constructor(props) {
		super(props);
		if (props.customList.rules == null) props.customList.rules = [];

		this.state = {
			ruleType: 'date',
			dateRule: 'today',
			dayWeekRule: 'Sunday',
			locationRule: 'country',
			locationSpecification: '',
			fulfillmentLocationRule: 'all_lineitems',
			fulfillmentLocationSpecification: '',
			tagRule: 'order',
			tagSpecification: '',
			propertyRule: 'order',
			financialRule: 'paid',
			propertyNameSpecification: '',
			propertyValueSpecification: '',
			metafieldRule: 'only_metafield',
			metafieldSpecification: '',
			metafieldValueSpecification: '',
			orderSkuRule: 'contain_sku',
			orderSkuSpecification: '',
			customList: props.customList,
			vendorRule: '',
			vendorName: '',
			selectedDates: {
				start: new Date(),
				end: new Date(),
			},
			month: new Date().getMonth(),
			year: new Date().getFullYear(),
			preferences: props.preferences,
		};
	}



	async addRule() {
		const rule = {
			id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
			description: '',
			type: this.state.ruleType,
		};
		switch (this.state.ruleType) {
			case 'all':
				rule['rule'] = '';
				rule['title'] = '';
				break;
			case 'order_sku':
				rule['rule'] = this.state.orderSkuRule;
				rule['specification'] = this.state.orderSkuSpecification;
				rule['title'] = orderSkuRules.find(x => x.value === this.state.orderSkuRule).label;
				break;
			case 'date':
				rule['rule'] = this.state.dateRule;
				rule['day'] = this.state.dayWeekRule;
				rule['title'] =
					rule['rule'] === 'day_week'
						? `${dateRules.find(x => x.value === this.state.dateRule).label} - ${rule['day']}`
						: dateRules.find(x => x.value === this.state.dateRule).label;
				rule['range'] = {
					start: `${this.state.selectedDates.start.getFullYear()}-${
						this.state.selectedDates.start.getMonth() + 1
					}-${this.state.selectedDates.start.getDate()}`, //'YYYY-MM-DD'
					end: `${this.state.selectedDates.end.getFullYear()}-${
						this.state.selectedDates.end.getMonth() + 1
					}-${this.state.selectedDates.end.getDate()}`, //'YYYY-MM-DD'
				};
				break;
			case 'financial_status':
				rule['rule'] = this.state.financialRule;
				rule['specification'] = '';
				rule['title'] = financialRules.find(x => x.value === this.state.financialRule).label;
				break;
			case 'location':
				rule['rule'] = this.state.locationRule;
				rule['specification'] = this.state.locationSpecification;
				rule['title'] = locationRules.find(x => x.value === this.state.locationRule).label;
				break;
			case 'fulfillment_location':
				rule['rule'] = this.state.fulfillmentLocationRule;
				rule['specification'] = this.state.fulfillmentLocationSpecification;
				rule['title'] = fulfillmentLocationRules.find(x => x.value === this.state.fulfillmentLocationRule).label;
				break;
			case 'tags':
				rule['rule'] = this.state.tagRule;
				rule['specification'] = this.state.tagSpecification;
				rule['title'] = tagRules.find(x => x.value === this.state.tagRule).label;
				break;
			case 'property':
				rule['rule'] = this.state.propertyRule;
				rule['specification'] = `${this.state.propertyNameSpecification}:${this.state.propertyValueSpecification}`;
				rule['title'] = propertyRules.find(x => x.value === this.state.propertyRule).label;
				break;
			case 'metafields':
				rule['rule'] = this.state.metafieldRule;
				rule['specification'] = `${this.state.metafieldSpecification}:${this.state.metafieldValueSpecification}`;
				rule['title'] = metafieldRules.find(x => x.value === this.state.metafieldRule).label;
				break;
			case 'vendor':
				rule['rule'] = this.state.vendorRule;
				rule['specification'] = `${this.state.vendorName}`;
				rule['title'] = `${vendorRules.find(x => x.value === this.state.vendorRule).label} ${this.state.vendorName}`;
				break;

			default:
				break;
		}
		rule.description = getRuleDescription(rule.type, rule.rule, rule.day, rule.specification, this.state);
		const rules = [...this.state.customList.rules, rule];
		const customList = this.state.customList;
		customList['rules'] = rules;
		await actions.updateCustomList(customList)();
		this.setState({ customList, addingRule: false });
	}

	async handleRemoveRule(id) {
		const rules = this.state.customList.rules.filter(x => x.id !== id);
		const customList = this.state.customList;
		customList['rules'] = rules;
		await actions.updateCustomList(customList)();
		this.setState({ customList, addingRule: false });
	}

	render() {
		const ruleForm = (
			<FormLayout>
				<Select
					label="Add orders to this list based on"
					options={ruleTypes(this.props)}
					onChange={ruleType => {
						if (ruleType === 'metafields' && this.state.preferences?.metafields?.length > 0) {
							const field = this.state.preferences.metafields[0];
							this.setState({ metafieldSpecification: `${field.namespace}.${field.key}` });
						}
						this.setState({ ruleType });
					}}
					value={this.state.ruleType}
				/>
				{this.state.ruleType === 'date' ? (
					<Select
						label="Date configuration"
						helpText={dateRules.find(x => x.value === this.state.dateRule).info}
						options={dateRules}
						onChange={dateRule => {
							this.setState({ dateRule });
						}}
						value={this.state.dateRule}
					/>
				) : (
					''
				)}
				{this.state.dateRule === 'day_week' ? (
					<Select
						label="Day of the week"
						helpText={dayWeekRule.find(x => x.value === this.state.dayWeekRule).info}
						options={dayWeekRule}
						onChange={dayWeekRule => {
							this.setState({ dayWeekRule });
						}}
						value={this.state.dayWeekRule}
					/>
				) : (
					''
				)}
				{this.state.dateRule === 'date' ? (
					<DatePicker
						month={this.state.month}
						year={this.state.year}
						onChange={selectedDates => {
							this.setState({ selectedDates: selectedDates }, () => console.log(this.state.selectedDates));
						}}
						onMonthChange={(month, year) => {
							this.setState({ month: month, year: year });
						}}
						selected={this.state.selectedDates}
						allowRange={false}
					/>
				) : (
					''
				)}
				{this.state.dateRule === 'range_days' ? (
					<DatePicker
						month={this.state.month}
						year={this.state.year}
						onChange={selectedDates => {
							this.setState({ selectedDates: selectedDates }, () => console.log(this.state.selectedDates));
						}}
						onMonthChange={(month, year) => {
							this.setState({ month: month, year: year });
						}}
						selected={this.state.selectedDates}
						allowRange={true}
					/>
				) : (
					''
				)}
				{this.state.ruleType === 'location' ? (
					<Stack vertical>
						<Select
							label="Base location on"
							helpText={locationRules.find(x => x.value === this.state.locationRule).info}
							options={locationRules}
							onChange={locationRule => {
								this.setState({ locationRule });
							}}
							value={this.state.locationRule}
						/>
						<TextField
							label="Specify location"
							id="specify-location"
							onChange={locationSpecification => this.setState({ locationSpecification })}
							value={this.state.locationSpecification}
							helpText={locationRules.find(x => x.value === this.state.locationRule).help}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'fulfillment_location' ? (
					<Stack vertical>
						<Select
							label="Base fulfillment location on"
							helpText={fulfillmentLocationRules.find(x => x.value === this.state.fulfillmentLocationRule).info}
							options={fulfillmentLocationRules}
							onChange={fulfillmentLocationRule => {
								this.setState({ fulfillmentLocationRule });
							}}
							value={this.state.fulfillmentLocationRule}
						/>
						<TextField
							label="Specify location name"
							id="specify-location-name"
							onChange={fulfillmentLocationSpecification => this.setState({ fulfillmentLocationSpecification })}
							value={this.state.fulfillmentLocationSpecification}
							helpText={fulfillmentLocationRules.find(x => x.value === this.state.fulfillmentLocationRule).help}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'financial_status' ? (
					<Select
						label="Payment Status"
						helpText={financialRules.find(x => x.value === this.state.financialRule).info}
						options={financialRules}
						onChange={financialRule => {
							this.setState({ financialRule });
						}}
						value={this.state.financialRule}
					/>
				) : null}
				{this.state.ruleType === 'order_sku' ? (
					<Stack vertical>
						<Select
							label="Find SKU"
							helpText={orderSkuRules.find(x => x.value === this.state.orderSkuRule).info}
							options={orderSkuRules}
							onChange={orderSkuRule => {
								this.setState({ orderSkuRule });
							}}
							value={this.state.orderSkuRule}
						/>
						<TextField
							label="Specify SKU"
							id="specify-sku"
							onChange={orderSkuSpecification => this.setState({ orderSkuSpecification })}
							value={this.state.orderSkuSpecification}
							helpText={orderSkuRules.find(x => x.value === this.state.orderSkuRule).help}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'tags' ? (
					<Stack vertical>
						<Select
							label="Check tags for"
							helpText={tagRules.find(x => x.value === this.state.tagRule).info}
							options={tagRules}
							onChange={tagRule => {
								this.setState({ tagRule });
							}}
							value={this.state.tagRule}
						/>
						<TextField
							label="Specify tags"
							id="specify-tags"
							onChange={tagSpecification => this.setState({ tagSpecification })}
							value={this.state.tagSpecification}
							helpText={tagRules.find(x => x.value === this.state.tagRule).help}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'property' ? (
					<Stack vertical>
						<Select
							label="Check order"
							helpText={
								this.state.propertyRule != null
									? propertyRules.find(x => x.value === this.state.propertyRule).info
									: ''
							}
							options={propertyRules}
							onChange={propertyRule => {
								this.setState({ propertyRule });
							}}
							value={this.state.propertyRule}
						/>
						<TextField
							label="Property name"
							id="specify-property"
							onChange={propertyNameSpecification => this.setState({ propertyNameSpecification })}
							value={this.state.propertyNameSpecification}
							helpText={propertyRules.find(x => x.value === this.state.propertyRule).help}
						/>
						<TextField
							label="Value"
							id="specify-property-value"
							onChange={propertyValueSpecification => this.setState({ propertyValueSpecification })}
							value={this.state.propertyValueSpecification}
							helpText={propertyRules.find(x => x.value === this.state.propertyRule).valuehelp}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'metafields' ? (
					<Stack vertical>
						<Select
							label="Check line item metafields"
							helpText={
								this.state.metafieldRule != null
									? metafieldRules.find(x => x.value === this.state.metafieldRule).info
									: ''
							}
							options={metafieldRules}
							onChange={metafieldRule => {
								this.setState({ metafieldRule });
							}}
							value={this.state.metafieldRule}
						/>
						<Select
							label="Metafields"
							helpText="Configure which metafields are available in the preferences"
							options={this.state.preferences.metafields.map(metafield => {
								return {
									label: metafield.description
										? `${metafield.description} (${metafield.namespace}.${metafield.key})`
										: `${metafield.namespace}.${metafield.key}`,
									value: `${metafield.namespace}.${metafield.key}`,
								};
							})}
							onChange={metafieldSpecification => {
								this.setState({ metafieldSpecification });
							}}
							value={this.state.metafieldSpecification}
						/>
						<TextField
							label="Value"
							id="specify-metafield-value"
							onChange={metafieldValueSpecification => this.setState({ metafieldValueSpecification })}
							value={this.state.metafieldValueSpecification}
							helpText={
								(
									metafieldRules.find(x => x.value === this.state.metafieldSpecification) ||
									this.state.preferences.metafields[0]
								).help
							}
						/>
					</Stack>
				) : (
					''
				)}
				{this.state.ruleType === 'vendor' ? (
					<Stack vertical>
						<Select
							label="Base Vendor on"
							options={vendorRules}
							onChange={vendorRule => {
								this.setState({ vendorRule });
							}}
							value={this.state.vendorRule}
						/>
						<TextField
							label="Specify Vendor name"
							id="specify-vendor-name"
							onChange={vendorName => this.setState({ vendorName })}
							value={this.state.vendorName}
							helpText="Enter single value"
						/>
					</Stack>
				) : (
					''
				)}
				<Button onClick={() => this.addRule()}>Add Rule</Button>
			</FormLayout>
		);
		return (
			<Stack vertical>
				{this.state.addingRule ? ruleForm : <Button onClick={() => this.setState({ addingRule: true })}>Add Rule</Button>}
				{this.state.customList == null || !this.state.customList.rules.length ? (
					<Banner key="banner">There are no rules a dynamic list must have at least one rule to display orders.</Banner>
				) : (
					<ResourceList
						resourceName={{ singular: 'rule', plural: 'rules' }}
						items={this.state.customList.rules}
						renderItem={item => {
							const { id, type, title, description } = item;
							const media = <Avatar customer size="medium" name={type} />;
							const shortcutActions = id ? [{ content: 'Remove', onAction: () => this.handleRemoveRule(id) }] : null;
							return (
								<Stack vertical>
									<ResourceList.Item
										id={id}
										media={media}
										accessibilityLabel={`View details for ${type}`}
										shortcutActions={shortcutActions}
										persistActions
									>
										<h3>
											<TextStyle variation="strong">{title}</TextStyle>
										</h3>
										<div>{description}</div>
									</ResourceList.Item>
								</Stack>
							);
						}}
					/>
				)}
			</Stack>
		);
	}
}
