
import React, { Component } from 'react';
import { Page, Card, TextField, PageActions, FormLayout, Button, DataTable, Select, Link, Banner, Layout } from '@shopify/polaris';
import { helpers } from '../helpers'
import { connect } from 'react-redux'
import * as actions from '../actions';

export class Promotion extends Component {


    state = { id: '', name: '', code: '', conditionSubject: '', conditionOperator: '', conditionValue: '', conditions: [], invalid: false, conditionInvalid: false }

    constructor(props) {
        super(props);
        this.addCondition = this.addCondition.bind(this);
        this.removeCondition = this.removeCondition.bind(this);
    }

    UNSAFE_componentWillMount() {
        let pathParts = this.props.location.pathname.split('/')
        if (pathParts.length === 3) {
            let id = pathParts[2]
            this.props.fetchPromo(id);
            this.setState({ id })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.promo !== nextProps.promo) {
            this.setState(nextProps.promo);
        }
    }

    handleSubjectChange = conditionSubject => this.setState({ conditionSubject })
    handleOperatorChange = conditionOperator => this.setState({ conditionOperator });
    handleValueChange = conditionValue => this.setState({ conditionValue });
    handleNameChange = name => this.setState({ name });
    handleCodeChange = code => this.setState({ code });

    conditionIsValid() {
        return this.state.conditionSubject !== '' && this.state.conditionOperator !== '' && this.state.conditionValue !== '';
    }

    addCondition() {
        if (!this.conditionIsValid()) {
            this.setState({ conditionInvalid: true })
            return;
        }
        this.setState({ conditionInvalid: false })
        this.setState((state) => {
            const conditions = state.conditions.concat({ id: helpers.generateId(), subject: state.conditionSubject, operator: state.conditionOperator, value: state.conditionValue });
            return { conditions }
        });
    }

    removeCondition(id) {
        let conditions = this.state.conditions.filter((c) => c.id !== id)
        this.setState({ conditions })
    }

    getConditionsForDisplay() {
        return this.state.conditions.map((condition) => [helpers.subjects.find((s) => s.value === condition.subject).label, helpers.operators.find((o) => o.value === condition.operator).label, condition.value, <Link onClick={() => { this.removeCondition(condition.id) }}>Remove</Link>])
    }

    isValid() {
        return this.state.name !== '' && this.state.code !== ''
    }

    async save() {
        if (!this.isValid()) {
            this.setState({ invalid: true });
            return;
        }
        // Save
        if (this.state.id === '') {
            let id = await actions.addPromo({ name: this.state.name, code: this.state.code, conditions: this.state.conditions })()
            this.setState({ id })
            this.props.history.push(`/promotion/${id}`)
        } else {
            actions.updatePromo({ id: this.state.id, name: this.state.name, code: this.state.code, conditions: this.state.conditions })()
            this.props.history.push(`/promotions`)
        }

    }

    render() {
        let dataTable;
        let conditionError;
        let error;
        let type = this.state.conditionSubject ? helpers.subjects.find((s) => s.value === this.state.conditionSubject).type : ''
        let operators = helpers.filterOperator(type)
        if (this.getConditionsForDisplay().length > 0) {
            dataTable = <DataTable
                columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'text'
                ]}
                headings={[
                    'Subject',
                    'Condition',
                    'Value',
                    ''
                ]}
                rows={this.getConditionsForDisplay()}
            />
        }
        if (this.state.conditionInvalid) {
            conditionError = <Banner
                title="Select condition values"
                status="critical">
                <p>You must select a subject, operator and enter a value to add a condition to the promotion</p>
            </Banner>
        }

        if (this.state.invalid) {
            error = <Banner
                title="Promotion must have a name and a code"
                status="critical">
                <p>You must provide a name to identify the promotion and a code for tagging and pick lists.</p>
            </Banner>
        }
        const renderConditions = this.state.id === '' ? '' : <Layout.AnnotatedSection
            title="Promotion Conditions"
            description="Set up the conditions that, when all conditions are satisfied, this promotion is applied to an order">
            <Card sectioned>
                <FormLayout title="Rule configuration">
                    {dataTable}
                    {conditionError}
                    <FormLayout.Group condensed>
                        <Select
                            label="Subject"
                            id="subject"
                            options={helpers.subjects}
                            onChange={this.handleSubjectChange}
                            value={this.state.conditionSubject}
                        />
                        <Select
                            label="Operator"
                            id="operator"
                            options={operators}
                            onChange={this.handleOperatorChange}
                            value={this.state.conditionOperator}
                        />
                        <TextField
                            label="Value"
                            id="value"
                            onChange={this.handleValueChange}
                            value={this.state.conditionValue}
                        />
                    </FormLayout.Group>
                    <Button onClick={this.addCondition}>Add Condition</Button>
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
        return (
                <Page title="Promotion"
                    breadcrumbs={[{ content: 'Pick', url: `/pick${window.location.search}` }, { content: 'Promotions', url: `/promotions${window.location.search}` }]}>
                    <PageActions
                        primaryAction={{
                            content: 'Save',
                            onClick: () => { this.save() }
                        }}
                    />
                    {error}

                    <Layout>
                        <Layout.AnnotatedSection
                            title="Promotions settings"
                            description=""
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        label="Promotion name"
                                        type="text"
                                        value={this.state.name}
                                        onChange={this.handleNameChange}
                                        helpText="This is used to identify the rule in case you need to edit it later."
                                    />
                                    <TextField
                                        label="Promo code"
                                        type="text"
                                        value={this.state.code}
                                        onChange={this.handleCodeChange}
                                        helpText="This is a short code to identify when an order qualifies for a promotion."
                                    />
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                        {renderConditions}
                    </Layout>
                </Page>
        );
    }
}

const mapStateToProps = ({ promos }) => {
    return {
        promo: promos
    };
};

export default connect(mapStateToProps, actions)(Promotion);
