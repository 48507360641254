import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchColorSkuList,
  addColorSku,
  deleteColorSku,
  updateColorSku,
} from "../actions/ColorSku.actions";
import { selectColorSkuItems } from "../selectors/ColorSku.selectors";
import {
  Banner,
  Page,
  Layout,
  Card,
  TextField,
  TextStyle,
  Button,
  Stack,
  ResourceList,
} from "@shopify/polaris";
import { SketchPicker } from "react-color";
import { ColorBadge } from "./pick/ColorBadge";

const INIT_COLOR = "#000000";
const INIT_COLOR_NEW = "#003135";
const MESSAGE_LEVEL = {
  CRITICAL: "critical",
  WARNING: "warning",
};

const ColorSku = (props) => {
  const [color, setColor] = useState(INIT_COLOR);
  const [colorSecondary, setColorSecondary] = useState(INIT_COLOR);
  const [sku, setSku] = useState("");
  const [idColorSku, setIdColorSku] = useState("");
  const [message, setMessage] = useState({ message: "", level: "" });

  const dispatch = useDispatch();
  const getList = useCallback(() => dispatch(fetchColorSkuList()), [dispatch]);
  const addColorSkuToList = useCallback(
    (colorSku) => dispatch(addColorSku(colorSku)),
    [dispatch]
  );
  const deleteColorSkuFromList = useCallback(
    (item) => dispatch(deleteColorSku(item)),
    [dispatch]
  );
  const updateColorSkuToList = useCallback(
    (colorSku) => dispatch(updateColorSku(colorSku)),
    [dispatch]
  );
  const currentColorSkuList = useSelector(selectColorSkuItems);

  const handleColorChange = useCallback((color) => setColor(color.hex), []);
  const handleColorSecondaryChange = useCallback(
    (color) => setColorSecondary(color.hex),
    []
  );
  const handleSkuChange = useCallback((sku) => setSku(sku.toUpperCase()), []);
  const handleIdColorSkuChanage = useCallback((id) => setIdColorSku(id), []);
  const handleMessageChange = useCallback(
    (message = "", level = "") => setMessage({ message, level }),
    []
  );

  const isSaveSkuColorValid = () => {
    handleMessageChange();
    if (sku === "") {
      handleMessageChange("Please, fill out the SKU", MESSAGE_LEVEL.CRITICAL);
      return false;
    } else {
      const skuExists = currentColorSkuList.filter(
        (skuColor) => skuColor.sku === sku
      );
      if (skuExists.length > 0 && idColorSku === "") {
        handleMessageChange(
          `SKU ${skuExists.sku} has been already assigned to the color ${skuExists.color}. Please, fill out another SKU`,
          MESSAGE_LEVEL.CRITICAL
        );
        return false;
      }
    }
    if (color === "" || color === undefined) {
      handleMessageChange(
        "Please, choose a valid Main Color",
        MESSAGE_LEVEL.CRITICAL
      );
      return false;
    }
    if (colorSecondary === "" || colorSecondary === undefined) {
      handleMessageChange(
        "Please, choose a valid Secondary Color",
        MESSAGE_LEVEL.CRITICAL
      );
      return false;
    }

    return true;
  };

  const saveSkuColor = () => {
    handleMessageChange();
    if (isSaveSkuColorValid()) {
      if (idColorSku !== "") {
        updateColorSkuToList({
          id: idColorSku,
          sku: sku,
          color: color,
          colorSecondary: colorSecondary,
        });
      } else
        addColorSkuToList({
          sku: sku,
          color: color,
          colorSecondary: colorSecondary,
        });
      handleColorChange(INIT_COLOR);
      handleColorSecondaryChange(INIT_COLOR);
      handleSkuChange("");
      handleIdColorSkuChanage("");
      handleMessageChange();
    }
  };

  const editSkuColor = (item) => {
    handleIdColorSkuChanage(item.id);
    handleSkuChange(item.sku);
    handleColorChange({ hex: item.color || INIT_COLOR_NEW });
    handleColorSecondaryChange({ hex: item.colorSecondary || INIT_COLOR });
  };

  const removeSkuColor = (item) => deleteColorSkuFromList(item);

  useEffect(() => {
    getList();
  }, [getList]);

  const colorSkuLayout = (
    <Page
      title="SKU Color Picked"
      breadcrumbs={[
        {
          content: "Configuration",
          url: `/configuration${window.location.search}`,
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card
            title="Add new SKU and color"
            primaryFooterAction={{ content: "Save", onAction: saveSkuColor }}
            sectioned
          >
            <Card.Section>
              {message.message !== "" ? (
                <Banner
                  title={`${message.message}`}
                  status={`${message.level}`}
                ></Banner>
              ) : null}
              <TextField
                label="SKU"
                value={sku}
                onChange={handleSkuChange}
                inputMode="text"
                readOnly={idColorSku !== "" ? true : false}
              />
              <br />
              <TextStyle variation="default">Assign a color</TextStyle>
              <br />
              <br />
              <Stack>
                <div>
                  <h2>Main Color</h2>
                  <SketchPicker
                    color={color}
                    onChangeComplete={handleColorChange}
                  />
                </div>
                <div>
                  <h2>Secondary Color</h2>
                  <SketchPicker
                    color={colorSecondary}
                    onChangeComplete={handleColorSecondaryChange}
                  />
                </div>
              </Stack>
            </Card.Section>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Card title="List SKU colors" sectioned>
            <Card.Section>
              <ResourceList
                resourceName={{ singular: "SKU color", plural: "SKUs color" }}
                items={currentColorSkuList}
                selectable={false}
                renderItem={(item) => {
                  return (
                    <ResourceList.Item
                      onClick={() => {}}
                      id={`${item.sku}`}
                      key={item.sku}
                      accessibilityLabel={`Color for item ${item.sku}`}
                    >
                      <Stack spacing="extraTight" alignment="center">
                        <Stack.Item fill>
                          <Stack spacing="extraTight">
                            <div
                              style={{
                                width: "20px",
                                borderRadius: "100%",
                                backgroundColor: `${item.color}`,
                              }}
                            >
                              &nbsp;
                            </div>
                            <TextStyle variation="strong">
                              {`${item.sku}`}
                            </TextStyle>
                            <ColorBadge color={item} status="" progress={""}>
                              {item.sku}
                            </ColorBadge>
                          </Stack>
                        </Stack.Item>
                        <Stack.Item>
                          <Button
                            outline
                            size="slim"
                            onClick={() => {
                              editSkuColor(item);
                            }}
                          >
                            Edit
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <Button
                            destructive
                            outline
                            size="slim"
                            onClick={() => {
                              removeSkuColor(item);
                            }}
                          >
                            X
                          </Button>
                        </Stack.Item>
                      </Stack>
                    </ResourceList.Item>
                  );
                }}
              />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );

  return colorSkuLayout;
};

export default ColorSku;
