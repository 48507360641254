let operatorsList = [
	{ label: 'Contains tag', value: 'contains', type: 'collection' },
	{ label: 'Does not contain tag', value: 'not_contain', type: 'collection' },
	{ label: 'Is less than', value: 'less_than', type: 'numeric' },
	{ label: 'Equals', value: 'equals', type: 'numeric,string' },
	{ label: 'Does not equal', value: 'not_equals', type: 'numeric,string' },
	{ label: 'Is greater than', value: 'greater_than', type: 'numeric' },
];

module.exports.helpers = {
	dispatchAllFromCollection: (ref, type, dispatch) => {
		ref()
			.get()
			.then(snapshot => {
				let result = [];
				snapshot.forEach(doc => {
					let item = doc.data();
					item.id = doc.id;
					result.push(item);
				});
				dispatch({
					type: type,
					payload: result,
				});
			});
	},
	generateId: () => {
		var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
	},
	isAdmin: () => {
		return sessionStorage.getItem('shopOrigin') === process.env.REACT_APP_TEST_SHOP;
	},
	subjects: [
		{ label: '', value: '', type: '' },
		{ label: 'Order tags', value: 'order_tags', type: 'collection' },
		{ label: 'Customer tags', value: 'customer_tags', type: 'collection' },
		{ label: 'Order value', value: 'order_value', type: 'numeric' },
		{ label: 'Number of orders', value: 'order_number', type: 'numeric' },
		{ label: 'Total spent by Customer', value: 'total_spent', type: 'numeric' },
	],
	itemSubjects: [
		{ label: '', value: '', type: '' },
		// { label: 'Product tags', value: 'product_tags', type: 'collection' },
		{ label: 'SKU', value: 'sku', type: 'string' },
		{ label: 'Price', value: 'price', type: 'numeric' },
		{ label: 'Property', value: 'property', type: 'string' },
	],
	operators: operatorsList,
	labelLayouts: [
		{ label: 'One per page (for label printers)', value: '1' },
		{ label: 'Two per row', value: '2' },
		{ label: 'Three per row', value: '3' },
		{ label: 'Four per row', value: '4' },
	],
	filterOperator: type => {
		let operators = [{ label: '', value: '', type: '' }];
		if (!type || type.length === 0) {
			return operators;
		}
		return operators.concat(operatorsList.filter(o => o.type.indexOf(type) > -1));
	},
	ordinal_suffix_of: i => {
		var j = i % 10,
			k = i % 100;
		if (j === 1 && k !== 11) {
			return i + 'st';
		}
		if (j === 2 && k !== 12) {
			return i + 'nd';
		}
		if (j === 3 && k !== 13) {
			return i + 'rd';
		}
		return i + 'th';
	},
	objectClone: obj => {
		var copy;

		// Handle the 3 simple types, and null or undefined
		if (null == obj || 'object' != typeof obj) return obj;

		// Handle Date
		if (obj instanceof Date) {
			copy = new Date();
			copy.setTime(obj.getTime());
			return copy;
		}

		// Handle Array
		if (obj instanceof Array) {
			copy = [];
			for (var i = 0, len = obj.length; i < len; i++) {
				copy[i] = module.exports.helpers.objectClone(obj[i]);
			}
			return copy;
		}

		// Handle Object
		if (obj instanceof Object) {
			copy = {};
			for (var attr in obj) {
				if (obj.hasOwnProperty(attr)) copy[attr] = module.exports.helpers.objectClone(obj[attr]);
			}
			return copy;
		}

		throw new Error("Unable to copy obj! Its type isn't supported.");
	},
	getSummary: orders => {
		let clonedOrders = module.exports.helpers.objectClone(orders);
		if (clonedOrders.length === 0) {
			return {};
		}
		let result = [];
		clonedOrders
			.map(p => p.pick_list)
			.forEach(current => {
				current.forEach(item => {
					if (result[item.display] != null) {
						result[item.display].quantity += isNaN(parseInt(item.quantity)) ? 1 : item.quantity;
						result[item.display].fulfillable_quantity += isNaN(parseInt(item.fulfillable_quantity))
							? 0
							: item.fulfillable_quantity;
					} else {
						result[item.display] = {
							quantity: isNaN(parseInt(item.quantity)) ? 1 : item.quantity,
							fulfillable_quantity: isNaN(parseInt(item.fulfillable_quantity)) ? 1 : item.fulfillable_quantity,
							sku: item.sku,
						};
					}
				});
				return result;
			});
		let products = result;

		const merged = [].concat.apply(
			[],
			module.exports.helpers.objectClone(orders).map(p => p.promotions)
		);
		let initialValue = {};
		const promotions = merged.reduce((acc, curr) => {
			if (Object.hasOwnProperty.call(acc, curr)) {
				acc[curr]++;
			} else {
				acc[curr] = 1;
			}
			return acc;
		}, initialValue);

		let initialPackedValue = {};
		const packed = clonedOrders
			.map(p => p.prepacked)
			.reduce((acc, curr) => {
				if (!curr.length) return acc;
				if (Object.hasOwnProperty.call(acc, curr)) {
					acc[curr]++;
				} else {
					acc[curr] = 1;
				}
				return acc;
			}, initialPackedValue);
		return { promotions, products, packed };
	},
	getProductDisplayTitle: (preferences, line) => {
		let display = '';
		let itemTitle = '';

		const item = line.title;
		if (item != null) {
			itemTitle = item.variant_title ? `${item.title}:${item.variant_title}` : item.title || '';
			switch (preferences.displayMode) {
				case 'sku':
					display = item.sku ? item.sku : preferences.expectSkuCodes ? 'NO-SKU' : itemTitle;
					break;

				case 'barcode':
					display = item.barcode ? item.barcode : preferences.expectSkuCodes ? 'NO-BARCODE' : itemTitle;
					break;

				case 'sku_name':
					display = item.sku
						? `${item.sku}${itemTitle.length ? ` - ${itemTitle}` : ''}`
						: preferences.expectSkuCodes
						? `NO-SKU - ${itemTitle}`
						: itemTitle;
					break;

				case 'name_sku':
					display = item.sku
						? `${itemTitle} (${item.sku})`
						: preferences.expectSkuCodes
						? `${itemTitle} (NO-SKU)`
						: itemTitle;
					break;

				case 'vendor_name':
					display = item.vendor ? `${item.vendor} - ${itemTitle}` : itemTitle;
					break;

				case 'name_var_SKU_lines':
					itemTitle = item.variant_title ? `${item.title}<br />${item.variant_title}` : item.title || '';
					display = item.sku
						? `${itemTitle.length ? `${itemTitle}<br />${item.sku}` : item.sku}`
						: preferences.expectSkuCodes
						? `${itemTitle}<br />(NO-SKU)`
						: itemTitle.length
						? itemTitle
						: item.sku;
					break;

				case 'vendor_name_var_SKU_lines':
					itemTitle = item.variant_title ? `${item.title}<br />${item.variant_title}` : item.title || '';
					display = item.vendor
						? `${item.vendor}<br/>${itemTitle.length ? `${itemTitle}<br/>${item.sku}`: item.sku}`
						: item.sku ? `${itemTitle.length ? `${itemTitle}<br/>${item.sku}`: item.sku}` : preferences.expectSkuCodes
						? `${itemTitle}<br/>(NO-SKU)` : itemTitle.length
						? itemTitle : item.sku;
					break;

				default:
					display = itemTitle;
					break;
			}
            if (display.length === 0) {
                display = item.sku;
            }
		} else {
			itemTitle = line.display;
			switch (preferences.displayMode) {
				case 'vendor_name':
					display = line.vendor ? `${line.vendor} - ${line.display}` : itemTitle;
					break;
				default:
					display = itemTitle;
					break;
			}
		}
		return display;
	},

  calculateWeight(value, to){
    const weightMultiple = {
      oz: 0.035274,
      lb: 0.00220462,
      kg: 0.001,
    }
    
    if(Object.hasOwnProperty.call(weightMultiple, to)){
      return value * weightMultiple[to];
    }

    return value;
  }
};
