import { colorSkuRef } from '../config/firebase';

export const ColorSkuActionTypes = {
  ADD_COLOR_SKU: 'ADD_COLOR_SKU',
  EDIT_COLOR_SKU: 'EDIT_COLOR_SKU',
  DELETE_COLOR_SKU: 'DELETE_COLOR_SKU',
  GET_COLOR_SKU_LIST: 'GET_COLOR_SKU_LIST',
};

export const deleteColorSku = (colorSku) => async (dispatch) => {
  await colorSkuRef().doc(colorSku.id).delete();

  dispatch({
    type: ColorSkuActionTypes.DELETE_COLOR_SKU,
    payload: colorSku,
  });
};

export const updateColorSku = (colorSku) => async (dispatch) => {
  await colorSkuRef()
    .doc(colorSku.id)
    .set(
      {
        color: colorSku.color,
        colorSecondary: colorSku.colorSecondary || '#000000',
        updated: new Date(),
      },
      { merge: true }
    );

  dispatch({
    type: ColorSkuActionTypes.EDIT_COLOR_SKU,
    payload: colorSku,
  });
};

export const addColorSku = (colorSku) => async (dispatch) => {
  const snap = await colorSkuRef().add({
    sku: colorSku.sku,
    color: colorSku.color,
    colorSecondary: colorSku.colorSecondary || '#000000',
    created: new Date(),
  });
  colorSku.id = snap.id;

  dispatch({
    type: ColorSkuActionTypes.ADD_COLOR_SKU,
    payload: colorSku,
  });
};

export const fetchColorSkuList = () => async (dispatch) => {
  colorSkuRef()
    .get()
    .then((snapshot) => {
      let result = [];
      snapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        result.push(item);
      });
      dispatch({
        type: ColorSkuActionTypes.GET_COLOR_SKU_LIST,
        payload: result,
      });
    });
};
