module.exports = {
    isFeatureAvailableForPlan: (plan, feature) => {
        if (feature == null) return false;
        const planConfig = {
            goods: {
                altDate: false,
                fulfillmentMode: false,
                mergeSplitOrders: false,
                locationsSupport: false,
                productSupport: false,
                customerSupport: true,
            },
            greats: {
                altDate: true,
                fulfillmentMode: false,
                mergeSplitOrders: false,
                locationsSupport: false,
                productSupport: true,
                customerSupport: true,
            },
        };
        if (["free", "custom", "nuts"].indexOf(plan) > -1) return true;
        if (Object.keys(planConfig).indexOf(plan) === -1) return false;
        return planConfig[plan][feature];
    },
    getUnavailableMessage: (code) => {
        const planMessages = {
            nutsOnly: "Only available on 'The Nuts' account plan",
            greatsOnly: "Only available on 'The Greats' account plan and above",
        };
        return planMessages[code];
    },
};
