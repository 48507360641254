import { useI18n } from '@shopify/react-i18n';

const currencies = [
    { currency: 'USD', locale: 'en-US', form: 'explicit' },
    { currency: 'CAD', locale: 'en-CA', form: 'explicit' },
    { currency: 'CAD', locale: 'fr-CA', form: 'explicit' },
    { currency: 'EUR', locale: 'fr-FR', form: 'explicit' },
    { currency: 'EUR', locale: 'de-DE', form: 'explicit' },
    { currency: 'EUR', locale: 'en-IE', form: 'explicit' },
    { currency: 'EUR', locale: 'nl-NL', form: 'explicit' },
    { currency: 'EUR', locale: 'it-IT', form: 'explicit' },
    { currency: 'GBP', locale: 'en-GB', form: 'explicit' },
    { currency: 'YEN', locale: 'ja-JP', form: 'explicit' },
    { currency: 'NZD', locale: 'en-NZ', form: 'explicit' },
    { currency: 'HKD', locale: 'zh-HK', form: 'explicit' },
    { currency: 'SGD', locale: 'zh-SG', form: 'explicit' },
    { currency: 'DKK', locale: 'da-DK', form: 'explicit' },
    { currency: 'AUD', locale: 'en-AU', form: 'explicit' },
];

const CurrencyFormatter = ({ price, curr, locale = 'en', form = '' }) => {
    const [i18n] = useI18n();
    i18n.locale = 'en-US';
    let formCurr = form;

    let validCurrency = currencies.find(currency => currency.currency === curr && currency.locale.substr(0, 2) === locale);
    if (validCurrency === null || validCurrency === undefined) validCurrency = currencies.find(currency => currency.currency === curr);

    if (validCurrency !== null && validCurrency !== undefined) {
        i18n.locale = validCurrency.locale;
        formCurr = formCurr === '' ? validCurrency.form : formCurr;
    }
    const output = i18n.formatCurrency(price, {
        currency: curr,
        form: formCurr === '' ? 'explicit' : formCurr,
    });
    return output;
}

export default CurrencyFormatter;