import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Heading, Subheading, Stack, Modal, Layout } from '@shopify/polaris';
import * as actions from '../actions';

class ReleaseUpdate extends Component {

    state = {
        active: true,
        order: undefined,
        checked: false,
        quantity: 1
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            order: this.props.order
        });
    }


    componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            // active: nextProps["active"],
            order: nextProps["order"]
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        // your condition if you want to re-render every time on props change
        return true;
    }

    render() {
        const { active } = this.state;

        return (
            <div style={{ height: '500px' }}>
                <Modal
                    open={active}
                    onClose={this.handleChange}
                    title="Whats new with Pick, Pack and Promo"
                    primaryAction={{
                        content: 'Wow, Awesome!',
                        onAction: this.handleChange,
                    }}
                >
                    <Modal.Section>
                        <Layout>
                            <Layout.Section>
                                <Stack vertical>
                                    <Heading>Location support now available</Heading>
                                    <Subheading>v1.6.2</Subheading>
                                    <p>We now support filtering custom lists by fulfillment locations. This was our most requested feature - so if you have multiple fulfillment locations you can now create pick lists with just that warehouses orders. You can also display which locations an order or a line item is assigned to in the pick list.</p>
                                    <img alt="1.6.2" className="update-image" src="/images/1-6-2.png" />
                                    <Heading>Export orders and summaries to CSV</Heading>
                                    <Subheading>v1.6.1</Subheading>
                                    <p>We have put another feather in the cap of our top plan "The Nuts", now you can export your lists as CSV. You can choose to export orders either one row per order (with each line item product added as a column) or one row per line-item (with the option to create a column per line item property).</p>
                                    <img alt="1.6.1" className="update-image" src="/images/1-6-1.png" />
                                    <Heading>Fulfill orders directly from Pick, pack & promo</Heading>
                                    <Subheading>v1.6.0</Subheading>
                                    <p>You can now fulfill orders directly, either one at a time or in bulk. You can even restrict fulfillment to certain lists to ensure orders are picked or processed first. You can even configure if you'd like the customers to receive an email that their order is on its way (Shopify fulfillment email template is used).</p>
                                    <p>Order fulfillment is the first feature of our new full works plan 'The Nuts', with much more planned.</p>
                                    <img alt="1.6.0" className="update-image" src="/images/1-6-0.png" />
                                    <Heading>Custom pick lists are here</Heading>
                                    <Subheading>v1.5.9</Subheading>
                                    <p>You asked, we delivered. You can now create custom pick lists for segmenting processing your orders. You can create manual lists and assign orders to them from the main pick list or automatic ones that filter orders based on pre-defined rules.</p>
                                    <img alt="1.5.9" className="update-image" src="/images/1-5-9.png" />
                                    <Heading>Bulk selecting orders</Heading>
                                    <Subheading>v1.5.8</Subheading>
                                    <p>You can now bulk select orders for printing / picking / flagging a group of orders.</p>
                                    <img alt="1.5.8" className="update-image" src="/images/1-5-8.png" />
                                    <Heading>Compacted and detailed summary</Heading>
                                    <Subheading>v1.5.7</Subheading>
                                    <p>The summary of the pick list is useful in different ways to different people, so we now support multiple modes for the summary. Compact for those that just want a quick view of whats in the pick and detailed for a sortable printable summary, and for those that just don't care about the summary they can just disable it completely.</p>
                                    <img alt="1.5.7" className="update-image" src="/images/1-5-7.png" />
                                    <Heading>Pick list preview</Heading>
                                    <Subheading>v1.5.6</Subheading>
                                    <p>We found it pretty annoying when we were configuring how the pick list looks that each time we made a change we had to return to the pick screen to check what it looked like. So we have introduce a 'preview' option when configuring the pick list.</p>
                                    <img alt="1.5.6" className="update-image" src="/images/1-5-6.png" />
                                    <Heading>Company name &amp; contact</Heading>
                                    <Subheading>v1.5.5</Subheading>
                                    <p>If you get a lot of orders that need delivery to a business address, orders can now display the company or the contact name or both.</p>
                                    <Heading>Card mode branding</Heading>
                                    <Subheading>v1.5.4</Subheading>
                                    <p>For people using PPP to print packing slips for orders you can now add branding and information to each slip with Logo, Header text and/or Footer text.</p>
                                    <img alt="1.5.4" className="update-image" src="/images/1-5-4.png" />
                                </Stack>
                            </Layout.Section>
                        </Layout>
                    </Modal.Section>
                </Modal>
            </div>
        );
    }

    handleChange = () => {
        this.setState(({ active }) => ({ active: false, dismissedReleaseUpdate: true }));
        this.props.updatePreferences({ currentVersion: parseFloat(process.env.REACT_APP_CURRENT_VERSION) });
    };
}

const mapStateToProps = ({ preferences }) => {
    return {
        // preferences
    };
};

export default connect(mapStateToProps, actions)(ReleaseUpdate);
