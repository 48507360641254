import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { fetchScope, fetchPlan, fetchShop, fetchPreferences, fetchPromos, updatePreferences, updateLogo, syncProducts } from "../../actions";
import { helpers } from "../../helpers";
import { RadioButton, SettingToggle, Label, Page, Card, Checkbox, PageActions, FormLayout, Layout, Link, DataTable, Banner, Select, TextField, Button, ChoiceList, Stack, Subheading } from "@shopify/polaris";
import Theme from "./Theme";
import ScopePermission from "./ScopePermissions";
import { isFeatureAvailableForPlan } from "../../helpers/features";
import { pickBy } from "lodash";

export class Preferences extends Component {
    state = {
        showPreview: false,
        plan: "free",
        displayOrderNumber: false,
        displayCompany: false,
        displayContact: false,
        displayCustomerTags: true,
        displayOrderTags: true,
        displayProductProperties: false,
        displayOrderProperties: true,
        displayOrderNotes: true,
        displayOrderDate: true,
        displayShippingDetails: false,
        displayShippingDescription: false,
        displayItemCharge: false,
        displayDiscountCode: false,
        displayItemFullfillableQuantity: false,
        displayPaymentStatus: false,

        displayOrderTotal: false,
        displayShippingAndTaxes: false,
        displayTotalWeight: false,

        displayOnAllOrders: false,
        hideSummary: false,
        hidePrintedSummary: false,
        summaryDisplayMode: "compact",
        // detailed summary
        detailedSummary: {
            hidePriceColumn: false,
            hideFulfillmentColumn: true,
        },

        pickDisplayMode: "list",
        redirect: false,
        displayAddress: false,
        syncLatest: false,
        ruleInvalid: false,
        ruleSubject: "",
        ruleSubjectValue: "",
        ruleOperator: "",
        ruleValue: "",

        propertyName: "",
        propertyExclusion: [],

        metafieldNamespace: "",
        metafieldKey: "",
        metafieldDescription: "",
        metafields: [],

        orderMode: "pick",
        displayMode: "sku",
        sortMode: "date",
        labelLayout: "3",
        groupByMode: "",
        groupByRegionMode: "country",
        excludeOldOrders: false,
        olderThan: "1 week",
        altDateMode: "field",
        altDateFormat: "",
        altDateField: "",
        altTimeFormat: "",
        altTimeField: "",
        altDateFallbackToNotes: false,

        secondarySortMode: "date",
        secondaryGroupByMode: "",

        groupOrders: false,
        mergeOrders: false,
        mergeOrdersBy: "contact",
        splitOrders: false,
        splitOrdersBy: "property",

        includeImagesinPrint: false,

        enableFiltering: false,
        enableSecondarySorting: false,
        expectSkuCodes: true,
        exclusionRules: [],
        shippingMappingRules: [],
        logo: "",
        headerText: "",
        footerText: "",
        theme: {},

        fulfillFromMain: false,
        fulfillFromPickedAndFlagged: false,
        fulfillFromCustom: false,
        fulfillFromBulk: false,
        notifyFulfilledCustomers: false,

        altDateRetainOriginalDisplay: false,
        altDateLabel: "",
        altDateRetainOriginalLabel: "",

        productsConfigDirty: false,
        summaryItemMetafields: [],
    };

    constructor(props) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
        this.syncLatest = this.syncLatest.bind(this);
        this.addRule = this.addRule.bind(this);
        this.addPropertyExclusion = this.addPropertyExclusion.bind(this);
        this.removePropertyExclusion = this.removePropertyExclusion.bind(this);
        this.removeMetafield = this.removeMetafield.bind(this);
        this.addMetafield = this.addMetafield.bind(this);
        this.addShippingMapping = this.addShippingMapping.bind(this);
        this.removeRule = this.removeRule.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.fetchShop();
        this.props.fetchPreferences();
        this.props.fetchPromos();
        this.props.fetchPlan();
        this.props.fetchScope();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.preferences !== nextProps.preferences) {
            this.setState(nextProps.preferences);

            if (!nextProps.preferences.displayItemFullfillableQuantity) {
                const detailedSummary = nextProps.preferences.detailedSummary;
                this.setState({
                    detailedSummary: {
                        ...detailedSummary,
                        hideFulfillmentColumn: !nextProps.preferences.displayItemFullfillableQuantity,
                    },
                });
            }
        }
    }

    handleSummaryDisplayModeChange = (checked, summaryDisplayMode) => this.setState({ summaryDisplayMode, hideSummary: summaryDisplayMode === "hidden" });

    handlePickDisplayModeChange = (checked, pickDisplayMode) => this.setState({ pickDisplayMode });
    handleHideSummaryChanged = (hideSummary) => this.setState({ hideSummary });
    handleHidePrintedSummaryChanged = (hidePrintedSummary) => this.setState({ hidePrintedSummary });
    handleHidePriceColumnDetailSummaryChange = (hidePriceColumn) => {
        const detailsSummary = this.state.detailedSummary;
        this.setState({ detailedSummary: { ...detailsSummary, hidePriceColumn } });
    };
    handleHideFulfillmentColumnDetailSummaryChange = (hideFulfillmentColumn) => {
        const detailsSummary = this.state.detailedSummary;
        this.setState({ detailedSummary: { ...detailsSummary, hideFulfillmentColumn } });
    };
    handleEnableFiltering = (enableFiltering) => this.setState({ enableFiltering });
    handleEnableSecondarySorting = (enableSecondarySorting) => this.setState({ enableSecondarySorting });
    handleExcludeOldOrdersChanged = (excludeOldOrders) => this.setState({ excludeOldOrders });
    handleOlderThanChanged = (checked, olderThan) => this.setState({ olderThan });

    handleSubjectChange = (ruleSubject) => this.setState({ ruleSubject });
    handleSubjectValueChange = (ruleSubjectValue) => this.setState({ ruleSubjectValue });
    handleOperatorChange = (ruleOperator) => this.setState({ ruleOperator });
    handleValueChange = (ruleValue) => this.setState({ ruleValue });
    handlePropertyNameChange = (propertyName) => this.setState({ propertyName });

    handleMetafieldNamespaceChange = (metafieldNamespace) => this.setState({ metafieldNamespace });
    handleMetafieldKeyChange = (metafieldKey) => this.setState({ metafieldKey });
    handleMetafieldDescriptionChange = (metafieldDescription) => this.setState({ metafieldDescription });

    handleSourceChange = (shippingMappingSource) => this.setState({ shippingMappingSource });
    handleDestinationChange = (shippingMappingDestination) => this.setState({ shippingMappingDestination });

    handleAltDateFieldChange = (altDateField) => this.setState({ altDateField });
    handleAltDateFormatChange = (altDateFormat) => this.setState({ altDateFormat });
    handleAltDateLabelChange = (altDateLabel) => this.setState({ altDateLabel });
    handleAltDateRetainOriginalLabelChange = (altDateRetainOriginalLabel) => this.setState({ altDateRetainOriginalLabel });

    handleAltTimeFieldChange = (altTimeField) => {
        this.setState({ altTimeField });
        if (altTimeField === "") this.setState({ groupByMode: "date" });
    };
    handleAltTimeFormatChange = (altTimeFormat) => this.setState({ altTimeFormat });
    handleAltDateFallbackToNotes = (altDateFallbackToNotes) => this.setState({ altDateFallbackToNotes });
    handleAltDateRetainOriginalDisplay = (altDateRetainOriginalDisplay) => this.setState({ altDateRetainOriginalDisplay });

    handleGroupOrdersChange = (groupOrders) => this.setState({ groupOrders });
    handleMergeOrdersChange = (mergeOrders) => this.setState({ mergeOrders });
    handleMergeOrdersByChange = (mergeOrdersBy) => this.setState({ mergeOrdersBy: mergeOrdersBy[0] });
    handleSplitOrdersChange = (splitOrders) => this.setState({ splitOrders });
    handleSplitOrdersByChange = (splitOrdersBy) => this.setState({ splitOrdersBy: splitOrdersBy[0] });

    handleOrderModeChange = (orderMode) => this.setState({ orderMode: orderMode[0] });

    handleImagesinPrintChanged = (includeImagesinPrint) => this.setState({ includeImagesinPrint });

    handleSortModeChange = (sortMode) => {
        this.setState({ sortMode: sortMode[0] }, () => {
            if ((this.state.sortMode === "date" || this.state.sortMode === "altDate") && this.state.groupOrders && (this.state.groupByMode === "payment" || this.state.groupByMode === "location")) this.setState({ groupByMode: "date" });
            else if (this.state.sortMode === "name" && this.state.groupOrders) this.setState({ groupByMode: "contact" });
            else if (this.state.sortMode === "delivery" && this.state.groupOrders) this.setState({ groupByMode: "delivery" });
            else if (this.state.sortMode === "geo" && this.state.groupOrders) this.setState({ groupByMode: "location" });
            else if (this.state.sortMode === "payment" && this.state.groupOrders) this.setState({ groupByMode: "payment" });

            this.setState({ altDateField: "" });
            this.setState({ altDateFormat: "" });
            this.setState({ altTimeField: "" });
            this.setState({ altTimeFormat: "" });
        });
    };
    handleSecondarySortModeChange = (secondarySortMode) => {
        this.setState({ secondarySortMode: secondarySortMode[0] }, () => {
            if (this.state.secondarySortMode === "date" && this.state.groupOrders && (this.state.groupByMode === "payment" || this.state.groupByMode === "location")) this.setState({ secondaryGroupByMode: "date" });
            else if (this.state.secondarySortMode === "name" && this.state.groupOrders) this.setState({ secondaryGroupByMode: "name" });
            else if (this.state.secondarySortMode === "delivery" && this.state.groupOrders) this.setState({ secondaryGroupByMode: "delivery" });
            else if (this.state.secondarySortMode === "geo" && this.state.groupOrders) this.setState({ secondaryGroupByMode: "geo" });
            else if (this.state.secondarySortMode === "payment" && this.state.groupOrders) this.setState({ secondaryGroupByMode: "payment" });
        });
    };
    handleAltDateModeChange = (altDateMode) => {
        this.setState({ altDateMode: altDateMode[0] });
        this.setState({ altDateField: "" });
        this.setState({ altDateFormat: "" });
        this.setState({ altTimeField: "" });
        this.setState({ altTimeFormat: "" });
    };
    handleGroupByModeChange = (groupByMode) => this.setState({ groupByMode: groupByMode[0] });
    handleGroupByRegionModeChange = (groupByRegionMode) => this.setState({ groupByRegionMode: groupByRegionMode[0] });

    handleFulfillFromMain = (fulfillFromMain) => this.setState({ fulfillFromMain });
    handleFulfillFromPickedAndFlagged = (fulfillFromPickedAndFlagged) => this.setState({ fulfillFromPickedAndFlagged });
    handleFulfillFromCustom = (fulfillFromCustom) => this.setState({ fulfillFromCustom });
    handleFulfillFromBulk = (fulfillFromBulk) => this.setState({ fulfillFromBulk });
    handleNotifyFulfilledCustomers = (notifyFulfilledCustomers) => this.setState({ notifyFulfilledCustomers });

    async syncLatest() {
        await this.props.updatePreferences({
            syncLatest: true,
        });
        this.setState({ redirect: true });
    }

    ruleIsValid() {
        return this.state.ruleSubject !== "" && this.state.ruleOperator !== "" && this.state.ruleValue !== "";
    }

    setOrderMode(mode) {
        if (this.props.scope === "read") {
            this.setState({ upgradeScopeRequired: true });
            return;
        }
        if (mode) {
            this.setState({ orderMode: "fulfillment" });
        } else {
            this.setState({ orderMode: "pick" });
        }
    }

    setLocationSupport(enabled) {
        if (this.props.scope === "read") {
            this.setState({ upgradeScopeRequired: true });
            return;
        }
        if (enabled) {
            this.setState({ locationsSupport: true });
        } else {
            this.setState({ locationsSupport: false });
        }
    }

    setProductSupport(enabled) {
        if (this.props.scope === "read") {
            this.setState({ upgradeScopeRequired: true });
            return;
        }
        if (enabled) {
            this.setState({ productSupport: true, productsConfigDirty: true });
        } else {
            this.setState({ productSupport: false });
        }
    }

    setCustomerSupport(enabled) {
        if (!this.props.scope.includes("customer")) {
            this.setState({ upgradeScopeRequired: true });
        }
        if (enabled) {
            this.setState({ customerSupport: true });
        } else {
            this.setState({ customerSupport: false });
        }
    }

    addShippingMapping() {
        this.setState((state) => {
            const shippingMappingRules = state.shippingMappingRules.concat({
                id: helpers.generateId(),
                source: state.shippingMappingSource,
                destination: state.shippingMappingDestination,
            });
            return { shippingMappingRules };
        });
    }

    addRule() {
        if (!this.ruleIsValid()) {
            this.setState({ ruleInvalid: true });
            return;
        }
        this.setState({ ruleInvalid: false });
        this.setState((state) => {
            const exclusionRules = state.exclusionRules.concat({
                id: helpers.generateId(),
                subject: state.ruleSubject,
                operator: state.ruleOperator,
                value: state.ruleValue,
                subjectValue: state.ruleSubjectValue,
            });
            return { exclusionRules };
        });
    }

    addPropertyExclusion() {
        if (this.state.propertyName !== "") {
            this.setState((state) => {
                const propertyExclusion = state.propertyExclusion.concat({
                    id: helpers.generateId(),
                    propertyName: state.propertyName,
                });
                return { propertyExclusion };
            });
        }
    }

    addMetafield() {
        if (this.state.metafield !== "") {
            this.setState((state) => {
                const metafields = state.metafields.concat({
                    id: state.metafieldKey,
                    namespace: state.metafieldNamespace,
                    key: state.metafieldKey,
                    description: state.metafieldDescription,
                });
                return { metafields, productsConfigDirty: true, metafieldKey: "", metafieldNamespace: "", metafieldDescription: "" };
            });
        }
    }

    removePropertyExclusion(id) {
        let propertyExclusion = this.state.propertyExclusion.filter((property) => property.id !== id);
        this.setState({ propertyExclusion });
    }

    removeMetafield(id) {
        let metafields = this.state.metafields.filter((namespace) => namespace.id !== id);
        this.setState({ metafields, productsConfigDirty: true });
    }

    removeRule(id) {
        let exclusionRules = this.state.exclusionRules.filter((c) => c.id !== id);
        this.setState({ exclusionRules });
    }

    removeShippingMethodMapping(id) {
        let shippingMappingRules = this.state.shippingMappingRules.filter((c) => c.id !== id);
        this.setState({ shippingMappingRules });
    }

    getRulesForDisplay() {
        return this.state.exclusionRules.map((rule) => [
            helpers.itemSubjects.find((s) => s.value === rule.subject).label,
            helpers.operators.find((o) => o.value === rule.operator).label,
            rule.value,
            <Link
                onClick={() => {
                    this.removeRule(rule.id);
                }}
            >
                Remove
            </Link>,
        ]);
    }

    getPropertiesForDisplay() {
        return this.state.propertyExclusion.map((exclusion) => [
            exclusion.propertyName,
            <Link
                onClick={() => {
                    this.removePropertyExclusion(exclusion.id);
                }}
            >
                Remove
            </Link>,
        ]);
    }

    getMetafieldsForDisplay() {
        return this.state.metafields.map((metafield) => [
            metafield.namespace,
            metafield.key,
            <Link
                onClick={() => {
                    this.removeMetafield(metafield.id);
                }}
            >
                Remove
            </Link>,
        ]);
    }

    getShippingMethodMappingForDisplay() {
        return this.state.shippingMappingRules.map((mapping) => [
            mapping.source,
            mapping.destination,
            <Link
                onClick={() => {
                    this.removeShippingMethodMapping(mapping.id);
                }}
            >
                Remove
            </Link>,
        ]);
    }

    handleSave() {
        const cleanTheme = pickBy(this.state.theme, (v) => v !== undefined);
        this.props.updatePreferences({
            ...cleanTheme,
            hideSummary: this.state.hideSummary,
            hidePrintedSummary: this.state.hidePrintedSummary,
            summaryDisplayMode: this.state.summaryDisplayMode,

            detailedSummary: this.state.detailedSummary,

            excludeOldOrders: this.state.excludeOldOrders,
            olderThan: this.state.olderThan,

            exclusionRules: this.state.exclusionRules,
            propertyExclusion: this.state.propertyExclusion,
            shippingMappingRules: this.state.shippingMappingRules,
            orderMode: this.state.orderMode,
            sortMode: this.state.sortMode,
            secondarySortMode: this.state.secondarySortMode,
            enableFiltering: this.state.enableFiltering,
            enableSecondarySorting: this.state.enableSecondarySorting,
            groupByMode: this.state.groupByMode,
            secondaryGroupByMode: this.state.secondaryGroupByMode,
            groupByRegionMode: this.state.groupByRegionMode,
            altDateMode: this.state.altDateMode,
            altDateFormat: this.state.altDateFormat,
            altDateField: this.state.altDateField,
            altTimeFormat: this.state.altTimeFormat,
            altTimeField: this.state.altTimeField,
            altDateFallbackToNotes: this.state.altDateFallbackToNotes || false,

            altDateRetainOriginalDisplay: this.state.altDateRetainOriginalDisplay,
            altDateLabel: this.state.altDateLabel,
            altDateRetainOriginalLabel: this.state.altDateRetainOriginalLabel,

            groupOrders: this.state.groupOrders,
            mergeOrders: this.state.mergeOrders,
            mergeOrdersBy: this.state.mergeOrdersBy,
            splitOrders: this.state.splitOrders,
            splitOrdersBy: this.state.splitOrdersBy,

            fulfillFromMain: this.state.fulfillFromMain,
            fulfillFromPickedAndFlagged: this.state.fulfillFromPickedAndFlagged,
            fulfillFromCustom: this.state.fulfillFromCustom,
            fulfillFromBulk: this.state.fulfillFromBulk,
            notifyFulfilledCustomers: this.state.notifyFulfilledCustomers,
            locationsSupport: this.state.locationsSupport || false,
            productSupport: this.state.productSupport || false,
            customerSupport: this.state.customerSupport || false,
            metafields: this.state.metafields || [],
            summaryItemMetafields: this.state.summaryItemMetafields || [],
            includeImagesinPrint: this.state.includeImagesinPrint,
        });
        if (this.state.productsConfigDirty === true) {
            this.props.syncProducts();
        }
        this.setState({ redirect: true });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`/configuration${window.location.search}`} />;
        }

        let propertyTable;
        if (this.getPropertiesForDisplay().length > 0) {
            propertyTable = <DataTable columnContentTypes={["text", "text"]} headings={["Property Name", ""]} rows={this.getPropertiesForDisplay()} />;
        }

        let metafieldsTable;
        if (this.getMetafieldsForDisplay().length > 0) {
            metafieldsTable = <DataTable columnContentTypes={["text", "text", "text"]} headings={["Namespace", "Key", ""]} rows={this.getMetafieldsForDisplay()} />;
        }

        let dataTable;
        let ruleError;
        let subjectValue;
        let type = this.state.ruleSubject ? helpers.itemSubjects.find((s) => s.value === this.state.ruleSubject).type : "";
        let operators = helpers.filterOperator(type);
        if (this.getRulesForDisplay().length > 0) {
            dataTable = <DataTable columnContentTypes={["text", "numeric", "numeric", "text"]} headings={["Subject", "Condition", "Value", ""]} rows={this.getRulesForDisplay()} />;
        }
        if (this.state.ruleSubject === "property") {
            subjectValue = <TextField label="Key" id="subjectValue" onChange={this.handleSubjectValueChange} value={this.state.ruleSubjectValue} />;
        }
        if (this.state.ruleInvalid) {
            ruleError = (
                <Banner title="Select rule values" status="critical">
                    <p>You must select a subject, operator and enter a value (and a key if a property) to add an exclusion rule</p>
                </Banner>
            );
        }
        const renderOlderThan = !this.state.excludeOldOrders ? (
            ""
        ) : (
            <Stack vertical>
                <Stack>
                    <Label title="Exclude orders older than:"></Label>
                    <RadioButton label="Today" checked={this.state.olderThan == null || this.state.olderThan === "today"} id="today" name="today" onChange={this.handleOlderThanChanged} />
                    <RadioButton label="One Day" checked={this.state.olderThan == null || this.state.olderThan === "1 day"} id="1 day" name="1 day" onChange={this.handleOlderThanChanged} />
                    <RadioButton label="One Week" checked={this.state.olderThan == null || this.state.olderThan === "1 week"} id="1 week" name="1 week" onChange={this.handleOlderThanChanged} />
                    <RadioButton label="One Month" checked={this.state.olderThan == null || this.state.olderThan === "1 month"} id="1 month" name="1 month" onChange={this.handleOlderThanChanged} />
                </Stack>
                <br />
            </Stack>
        );
        let sortingOptions;
        if (this.state.sortMode === "altDate" && isFeatureAvailableForPlan(this.props.plan, "altDate")) {
            let fieldNameDate;
            let fieldNameTime = null;
            let fieldFormatTime = null;
            let fallbackToNotes = null;
            if (this.state.altDateMode === "field" || this.state.altDateMode === "line_item") {
                fieldNameDate = <TextField label="Date fieldname" id="altDateFieldName" onChange={this.handleAltDateFieldChange} value={this.state.altDateField} />;

                fallbackToNotes = <Checkbox label="Fall-back to notes field" id="altDateFallbackToNotes" onChange={this.handleAltDateFallbackToNotes} checked={this.state.altDateFallbackToNotes || false} />;

                fieldNameTime = <TextField label="Time/Timeslot field name (optional)" id="altTimeFieldName" onChange={this.handleAltTimeFieldChange} value={this.state.altTimeField} />;

                fieldFormatTime = (
                    <TextField
                        label="Time/Timeslot format (optional)"
                        id="altTimeFormat"
                        onChange={this.handleAltTimeFormatChange}
                        value={this.state.altTimeFormat}
                        helpText="Formatting for the time used on the orders, i.e. 'HH:mm:ss' or 'HH:mm A' where H is the hour, m is the minute, and s is the second."
                    />
                );
            }

            sortingOptions = (
                <Stack vertical>
                    <br />
                    <ChoiceList
                        title="Alternative Date Location"
                        choices={[
                            { label: "Order attribute", value: "field" },
                            { label: "Line item property", value: "line_item" },
                            { label: "Notes field", value: "notes" },
                        ]}
                        selected={[this.state.altDateMode]}
                        onChange={this.handleAltDateModeChange}
                    />
                    {fieldNameDate}
                    {fallbackToNotes}
                    <TextField
                        label="Date format (optional)"
                        id="altDateFormat"
                        onChange={this.handleAltDateFormatChange}
                        value={this.state.altDateFormat}
                        helpText="Formatting for the date used on the orders, must include YYYY or YY to indicate year, M, MM or MMM to indicate month and DD or D to indicate day of the month. use square brackets to escape text i.e [delivery:]MM-DD-YYYY"
                    />
                    <TextField
                        label="Primary Date Display label (optional)"
                        id="altDateLabel"
                        onChange={this.handleAltDateLabelChange}
                        value={this.state.altDateLabel}
                        helpText="Overridden dates will be displayed as 'Order date', if you would like to use something else, provide the label here"
                    />
                    <Checkbox label="Retain original date display" id="altDateRetainOriginalDisplay" onChange={this.handleAltDateRetainOriginalDisplay} checked={this.state.altDateRetainOriginalDisplay || false} />
                    {this.state.altDateRetainOriginalDisplay === true ? (
                        <TextField
                            label="Original Date Display label (optional)"
                            id="altDateRetainOriginalLabel"
                            onChange={this.handleAltDateRetainOriginalLabelChange}
                            value={this.state.altDateRetainOriginalLabel}
                            helpText="The original order date will be displayed as 'Original Order date', if you would like to use something else, provide the label here"
                        />
                    ) : (
                        <></>
                    )}
                    {fieldNameTime}
                    {fieldFormatTime}
                </Stack>
            );
        }

        let groupingOptions;
        if (this.state.sortMode === "geo" && this.state.groupByMode === "location") {
            groupingOptions = (
                <Stack vertical>
                    <ChoiceList
                        title="Group region by"
                        choices={[
                            { label: "Country", value: "country" },
                            { label: "State", value: "province" },
                            { label: "City", value: "city" },
                            { label: "Zip Code", value: "zip" },
                        ]}
                        selected={[this.state.groupByRegionMode]}
                        onChange={this.handleGroupByRegionModeChange}
                    />
                </Stack>
            );
        }
        // let defaultGroupByMode = this.state.sortMode === 'geo' ? { label: 'Order location', value: 'location' } :
        let groupByModesOptions = this.state.groupOrders ? (
            <ChoiceList
                title="Group by"
                choices={[
                    { label: "Order location", value: "location", disabled: this.state.sortMode !== "geo" },
                    { label: "Date", value: "date", disabled: this.state.sortMode === "geo" || this.state.sortMode === "name" },
                    {
                        label: "Date & Time/Timeslot",
                        value: "timeslot",
                        disabled: this.state.sortMode === "geo" || this.state.sortMode === "name" || this.state.altTimeField === "",
                    },
                    { label: "Contact", value: "contact", disabled: this.state.sortMode === "geo" },
                    {
                        label: "Delivery method",
                        value: "delivery",
                        disabled: this.state.sortMode === "geo" || this.state.sortMode === "name",
                    },
                    { label: "Payment Status", value: "payment", disabled: this.state.sortMode === "geo" },
                ]}
                selected={this.state.groupByMode}
                onChange={this.handleGroupByModeChange}
            />
        ) : (
            ""
        );

        let shippingMethodMappingsDatatable;
        if (this.getShippingMethodMappingForDisplay().length > 0) {
            shippingMethodMappingsDatatable = <DataTable columnContentTypes={["text", "text", "text"]} headings={["Source", "Destination", ""]} rows={this.getShippingMethodMappingForDisplay()} />;
        }

        let shippingMethodMappings =
            this.state.groupByMode === "delivery" ? (
                <Stack vertical>
                    <p>Shopify sometimes lists shipping methods as something other than what you'd want to group by. Use mappings to map them to how you actually send things out.</p>
                    {shippingMethodMappingsDatatable}
                    <Stack>
                        <TextField label="Source" id="source" onChange={this.handleSourceChange} value={this.state.shippingMappingSource} />
                        <TextField label="Destination" id="destination" onChange={this.handleDestinationChange} value={this.state.shippingMappingDestination} />
                    </Stack>
                    <Button onClick={this.addShippingMapping}>Add Shipping Method Mapping</Button>
                </Stack>
            ) : (
                ""
            );
        const toastScopesMarkup = this.state.upgradeScopeRequired ? <ScopePermission /> : null;
        return (
            <Page title="Configure Pick List" breadcrumbs={[{ content: "Configuration", url: `/configuration${window.location.search}` }]}>
                <PageActions
                    primaryAction={{
                        content: "Save",
                        onClick: this.handleSave,
                    }}
                    secondaryActions={[
                        {
                            onClick: this.syncLatest,
                            content: "Sync active orders",
                        },
                    ]}
                />
                <Layout>
                    <Layout.AnnotatedSection title="Display options" description="Control how the pick list is displayed.">
                        {this.props.preferences != null && Object.keys(this.props.preferences).length ? (
                            <Theme
                                preferences={this.props.preferences}
                                update={(theme) => this.setState({ theme })}
                                updateLogo={this.props.updateLogo}
                                plan={this.props.plan}
                                metafields={this.state.metafields}
                                isProductSupport={this.state.productSupport}
                                isCustomerSupport={this.state.customerSupport}
                            />
                        ) : (
                            ""
                        )}
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Pick list summary" description="Configure the summary of all products in the current order run.">
                        <Card title="Choose how you want the summary to be displayed" sectioned>
                            <RadioButton
                                label="Disabled"
                                helpText="Hide the summary if you don't use it."
                                checked={this.state.summaryDisplayMode == null || this.state.summaryDisplayMode === "hidden"}
                                id="hidden"
                                name="hidden"
                                onChange={this.handleSummaryDisplayModeChange}
                            />
                            <RadioButton
                                label="Compact summary"
                                helpText="Summary is displayed at the top as a consolidated list of product x quantities."
                                checked={this.state.summaryDisplayMode == null || this.state.summaryDisplayMode === "compact"}
                                id="compact"
                                name="compact"
                                onChange={this.handleSummaryDisplayModeChange}
                            />
                            <RadioButton
                                label="Detailed summary"
                                helpText="The summary is presented as a sortable data-table as an additional tab - displayed by default."
                                id="detailed"
                                name="detailed"
                                // disabled={true}
                                checked={this.state.summaryDisplayMode === "detailed"}
                                onChange={this.handleSummaryDisplayModeChange}
                            />
                            <br />
                            {this.state.summaryDisplayMode === "compact" || this.state.summaryDisplayMode === "detailed" ? (
                                <Checkbox checked={this.state.hidePrintedSummary} onChange={this.handleHidePrintedSummaryChanged} label="Hide summary when printed" />
                            ) : null}
                        </Card>
                        {this.state.summaryDisplayMode === "detailed" ? (
                            <Card title="Select what fields you want to hide in the Detailed Summary" sectioned>
                                <FormLayout>
                                    <FormLayout.Group>
                                        <Checkbox checked={this.state.detailedSummary.hidePriceColumn} onChange={this.handleHidePriceColumnDetailSummaryChange} label="Total Price" />
                                        <Checkbox
                                            checked={this.state.detailedSummary.hideFulfillmentColumn}
                                            onChange={this.handleHideFulfillmentColumnDetailSummaryChange}
                                            label="Fulfillable Quantity"
                                            helpText='Flag "Item Fulfillable Quantity" needs to be checked'
                                        />
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        ) : null}
                    </Layout.AnnotatedSection>
                    {this.state.productSupport && this.state.metafields.length >= 1 && (
                        <Layout.AnnotatedSection title="Summary Item Metafields" description="Select which Metafields you want to show in the Detailed Summary">
                            <Card title="Select Metafields" sectioned>
                                <FormLayout>
                                    <FormLayout.Group>
                                        {this.state.metafields.map((metafield) => {
                                            const key = `${metafield.namespace}.${metafield.key}`;
                                            return (
                                                <Checkbox
                                                    key={key}
                                                    checked={this.state.summaryItemMetafields.indexOf(key) >= 0}
                                                    onChange={(checked) => {
                                                        const summaryItemMetafields = [...this.state.summaryItemMetafields];

                                                        if (checked) {
                                                            summaryItemMetafields.push(key);
                                                        } else {
                                                            summaryItemMetafields.splice(summaryItemMetafields.indexOf(key), 1);
                                                        }

                                                        this.setState({ summaryItemMetafields });
                                                    }}
                                                    label={`${metafield.namespace}.${metafield.key}`}
                                                />
                                            );
                                        })}
                                    </FormLayout.Group>
                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>
                    )}
                    {this.state.productSupport && (
                        <Layout.AnnotatedSection title="Print options" description="Select whether to include Product images in printed pick lists.">
                            <Card sectioned>
                                <Checkbox checked={this.state.includeImagesinPrint} onChange={this.handleImagesinPrintChanged} label="Include product images when printing picking lists" />
                            </Card>
                        </Layout.AnnotatedSection>
                    )}

                    <Layout.AnnotatedSection title="Order sorting and filtering" description="Select how the pick list is ordered.">
                        <Card sectioned>
                            <ChoiceList
                                name="sort_list"
                                choices={[
                                    { label: "Date", value: "date" },
                                    { label: "Geographically", value: "geo", disabled: false },
                                    {
                                        label: "Alternate Date",
                                        value: "altDate",
                                        disabled: !isFeatureAvailableForPlan(this.props.plan, "altDate"),
                                    },
                                    { label: "Name and Company Name", value: "name", disabled: false },
                                    { label: "Delivery method", value: "delivery", disabled: false },
                                    { label: "Payment Status", value: "payment", disabled: false },
                                ]}
                                selected={[this.state.sortMode]}
                                onChange={this.handleSortModeChange}
                            />
                            {!isFeatureAvailableForPlan(this.props.plan, "altDate") ? (
                                <Banner key="banner">
                                    Alternative dates (such as delivery dates) are only available on <Link url={`/account${window.location.search}`}>The Greats</Link> plan or greater.
                                </Banner>
                            ) : (
                                ""
                            )}
                            {sortingOptions}
                            <br />
                            <Checkbox checked={this.state.excludeOldOrders} onChange={this.handleExcludeOldOrdersChanged} label="Exclude older orders" />
                            {renderOlderThan}
                            <Checkbox label="Enable filtering" onChange={this.handleEnableFiltering} checked={this.state.enableFiltering} helpText="Pick list filtering enables displaying orders from a particular date range or geographic region." />
                            <br />
                            <hr />
                            <Checkbox label="Enable secondary sorting" onChange={this.handleEnableSecondarySorting} checked={this.state.enableSecondarySorting} />
                            <br />
                            <br />
                            {this.state.enableSecondarySorting ? (
                                <ChoiceList
                                    title="Secondary Sorting"
                                    name="secondary_sort_list"
                                    choices={[
                                        { label: "Date", value: "date" },
                                        { label: "Geographically", value: "geo", disabled: false },
                                        { label: "Name and Company Name", value: "name", disabled: false },
                                        { label: "Delivery method", value: "delivery", disabled: false },
                                        { label: "Payment Status", value: "payment", disabled: false },
                                    ]}
                                    selected={[this.state.secondarySortMode]}
                                    onChange={this.handleSecondarySortModeChange}
                                />
                            ) : (
                                ""
                            )}
                            <br />
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Premium Features" description="Update the fulfillment status of orders in Shopify and enable Locations support">
                        <Card sectioned>
                            <Stack vertical>
                                {isFeatureAvailableForPlan(this.props.plan, "fulfillmentMode") ? (
                                    this.state.orderMode !== "fulfillment" ? (
                                        <Stack vertical>
                                            <SettingToggle
                                                action={{
                                                    content: "Enable",
                                                    onAction: () => this.setOrderMode(true),
                                                }}
                                            >
                                                Enable fulfillment mode to allow updating shopify order status directly.
                                            </SettingToggle>
                                        </Stack>
                                    ) : (
                                        <Stack vertical>
                                            <Subheading>Fulfillment Mode</Subheading>
                                            <SettingToggle
                                                enabled={true}
                                                action={{
                                                    content: "Disable",
                                                    onAction: () => this.setOrderMode(false),
                                                }}
                                            >
                                                Disable fulfillment mode so no updates are made to orders in Shopify.
                                            </SettingToggle>
                                            <Banner key="banner">When an order is marked as 'fulfilled' in the pick list the status will also be updated in Shopify.</Banner>
                                            <p>Enable fulfillment from:</p>
                                            <Checkbox checked={this.state.fulfillFromMain} onChange={this.handleFulfillFromMain} label="Main pick list" />
                                            <Checkbox checked={this.state.fulfillFromPickedAndFlagged} onChange={this.handleFulfillFromPickedAndFlagged} label="Picked & Flagged lists" />
                                            <Checkbox checked={this.state.fulfillFromCustom} onChange={this.handleFulfillFromCustom} label="Custom lists" />
                                            <Checkbox checked={this.state.fulfillFromBulk} onChange={this.handleFulfillFromBulk} label="Enable bulk fulfillment" />
                                            <Checkbox checked={this.state.notifyFulfilledCustomers} onChange={this.handleNotifyFulfilledCustomers} label="Send email notification to fulfilled customers (from Shopify)" />
                                        </Stack>
                                    )
                                ) : (
                                    <Banner key="banner">
                                        Fulfillment mode is only available on <Link url={`/account${window.location.search}`}>The Nuts</Link> plan.
                                    </Banner>
                                )}
                                {isFeatureAvailableForPlan(this.props.plan, "locationsSupport") ? (
                                    this.state.locationsSupport !== true ? (
                                        <Stack vertical>
                                            <SettingToggle
                                                action={{
                                                    content: "Enable",
                                                    onAction: () => this.setLocationSupport(true),
                                                }}
                                            >
                                                Enable locations support to determine available locations for line item products.
                                            </SettingToggle>
                                        </Stack>
                                    ) : (
                                        <Stack vertical>
                                            <Subheading>Fulfillment Location Support</Subheading>
                                            <SettingToggle
                                                enabled={true}
                                                action={{
                                                    content: "Disable",
                                                    onAction: () => this.setLocationSupport(false),
                                                }}
                                            >
                                                Disable locations support.
                                            </SettingToggle>
                                            <Banner key="banner2">
                                                When orders are synchronized a request will be sent to the Fulfillment Orders API to determine available locations for all order products. To refresh existing orders you'll need to resync unfulfilled
                                                orders.
                                            </Banner>
                                        </Stack>
                                    )
                                ) : (
                                    <Banner key="banner">
                                        Locations support is only available on <Link url={`/account${window.location.search}`}>The Nuts</Link> plan.
                                    </Banner>
                                )}
                                {isFeatureAvailableForPlan(this.props.plan, "productSupport") ? (
                                    this.state.productSupport !== true ? (
                                        <Stack vertical>
                                            <SettingToggle
                                                action={{
                                                    content: "Enable",
                                                    onAction: () => this.setProductSupport(true),
                                                }}
                                                helpText="This will begin a sync of product information, depending on how many products you have this can take some time."
                                            >
                                                Enable product syncing to use product information in your list.
                                            </SettingToggle>
                                        </Stack>
                                    ) : (
                                        <Stack vertical>
                                            <Subheading>Product syncing</Subheading>
                                            <FormLayout>
                                                <p>Metafields:</p>
                                                {metafieldsTable}
                                                <FormLayout.Group condensed>
                                                    <TextField label="Namespace" id="metafield" onChange={this.handleMetafieldNamespaceChange} value={this.state.metafieldNamespace} />
                                                    <TextField label="Key" id="metafield" onChange={this.handleMetafieldKeyChange} value={this.state.metafieldKey} />
                                                    <TextField label="Description" id="metafield" onChange={this.handleMetafieldDescriptionChange} value={this.state.metafieldDescription} helpText="Optional" />
                                                </FormLayout.Group>
                                                <Button onClick={this.addMetafield}>Add Metafield</Button>
                                            </FormLayout>
                                            <SettingToggle
                                                enabled={true}
                                                action={{
                                                    content: "Disable",
                                                    onAction: () => this.setProductSupport(false),
                                                }}
                                            >
                                                Disable product syncing.
                                            </SettingToggle>
                                            <Banner key="product-banner">Modifying these settings will require the products to be resynchronized, this can take some time.</Banner>
                                        </Stack>
                                    )
                                ) : (
                                    <Banner key="banner">
                                        Product support is only available on <Link url={`/account${window.location.search}`}>The Goods</Link> plan and higher.
                                    </Banner>
                                )}
                                {isFeatureAvailableForPlan(this.props.plan, "customerSupport") ? (
                                    this.state.customerSupport !== true ? (
                                        <Stack vertical>
                                            <SettingToggle
                                                action={{
                                                    content: "Enable",
                                                    onAction: () => this.setCustomerSupport(true),
                                                }}
                                            >
                                                Enable customer data support to use customer order number in your list.
                                            </SettingToggle>
                                        </Stack>
                                    ) : (
                                        <Stack vertical>
                                            <Subheading>Customer data support</Subheading>
                                            <SettingToggle
                                                enabled={true}
                                                action={{
                                                    content: "Disable",
                                                    onAction: () => this.setCustomerSupport(false),
                                                }}
                                            >
                                                Disable customer data support.
                                            </SettingToggle>
                                        </Stack>
                                    )
                                ) : (
                                    <Banner key="banner">
                                        Customer support is only available on <Link url="/account">The Greats</Link> plan and higher.
                                    </Banner>
                                )}
                            </Stack>
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Order Grouping" description="Group orders by date, location or shipping method.">
                        <Card sectioned>
                            <FormLayout>
                                <Checkbox label="Group orders" onChange={this.handleGroupOrdersChange} checked={this.state.groupOrders} />
                                {groupByModesOptions}
                                {groupingOptions}
                                {shippingMethodMappings}
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection title="Line item exclusion" description="Set up rules for excluding line items from the pick list - in those cases where an item appears in the order but doesn't make sense to send.">
                        <Card sectioned>
                            <FormLayout title="Rule configuration">
                                {dataTable}
                                {ruleError}
                                <FormLayout.Group condensed>
                                    <Select label="Subject" id="subject" options={helpers.itemSubjects} onChange={this.handleSubjectChange} value={this.state.ruleSubject} />
                                    {subjectValue}
                                    <Select label="Operator" id="operator" options={operators} onChange={this.handleOperatorChange} value={this.state.ruleOperator} />
                                    <TextField label="Value" id="value" onChange={this.handleValueChange} value={this.state.ruleValue} />
                                </FormLayout.Group>
                                <Button onClick={this.addRule}>Add Exclusion Rule</Button>
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>

                    <Layout.AnnotatedSection title="Properties exclusion" description="Set up rules for excluding some properties from your line items display.">
                        <Card sectioned>
                            <FormLayout title="Rule configuration">
                                {propertyTable}
                                <FormLayout.Group condensed>
                                    <TextField label="Property name" id="property_name" onChange={this.handlePropertyNameChange} value={this.state.propertyName} />
                                </FormLayout.Group>
                                <Button onClick={this.addPropertyExclusion}>Add Property Exclusion</Button>
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
                <Stack alignment="right">
                    <Button primary onClick={this.handleSave}>
                        Save
                    </Button>
                    {toastScopesMarkup}
                </Stack>
            </Page>
        );
    }
}

const mapStateToProps = ({ preferences, plan, scope }) => {
    return {
        preferences,
        plan,
        scope,
    };
};

export default connect(mapStateToProps, {
    fetchScope,
    fetchPlan,
    fetchShop,
    fetchPreferences,
    fetchPromos,
    updatePreferences,
    updateLogo,
    syncProducts,
})(Preferences);
