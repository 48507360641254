import React, { useEffect, useState } from "react";
import { Card, DataTable, Link, Banner } from "@shopify/polaris";
import CurrencyFormatter from "../CurrencyFormatter";
import classNames from "classnames";

const DetailedSummary = ({
  orders,
  displayItemFullfillableQuantity,
  printSummary,
  detailedSummary,
  preferences,
}) => {
  const [sortedRows, setSortedRows] = useState([]);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [dataTableProps, setDataTableProps] = useState({
    columnContentTypes: [],
    headings: [],
    sortable: [],
  });

  useEffect(() => {
    const summaryOrdersObj = {};

    const metafieldsObj = {};

    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      for (let n = 0; n < order.pick_list.length; n++) {
        const line = order.pick_list[n];
        const key = line.display;

        metafieldsObj[key] = line.product_info?.metafields || [];

        if (Object.keys(summaryOrdersObj).indexOf(key) < 0) {
          summaryOrdersObj[key] = {
            total: 0,
            quantity: 0,
            fulfillable_quantity: 0,
          };
        }

        if (!line.bundleItem) {
          summaryOrdersObj[key].total +=
            (line.price.length > 0 ? parseFloat(line.price).toFixed(2) : 0) *
            parseInt(line.quantity);
        } else {
          summaryOrdersObj[key].total +=
            (line.price.length > 0 ? parseFloat(line.price).toFixed(2) : 0) *
            parseInt(line.bundleQuantity);
        }

        summaryOrdersObj[key].quantity += parseInt(line.quantity);

        if (displayItemFullfillableQuantity) {
          summaryOrdersObj[key].fulfillable_quantity += parseInt(
            line.fulfillable_quantity
          );
        }
      }
    }

    const metafieldsAvailable = [];

    const metafieldsKeys = Object.keys(metafieldsObj);
    for (let i = 0; i < metafieldsKeys.length; i++) {
      if (metafieldsObj[metafieldsKeys[i]].length >= 1) {
        for (let n = 0; n < metafieldsObj[metafieldsKeys[i]].length; n++) {
          const metafieldObj = metafieldsObj[metafieldsKeys[i]][n];
          const metafieldKey = `${metafieldObj.namespace}.${metafieldObj.key}`;
          if (
            preferences.productSupport &&
            preferences.summaryItemMetafields.includes(metafieldKey) &&
            metafieldsAvailable.indexOf(metafieldKey) < 0
          ) {
            metafieldsAvailable.push(metafieldKey);
          }
        }
      }
    }

    const localSortedOrdersKeys = Object.keys(summaryOrdersObj).map((key) => {
      const sortedKeys = [];
      let textDisplayLayout = key.split("<br />").join(" - "); //.replaceAll('<br />', ' - ');

      sortedKeys.push(textDisplayLayout);

      if (!detailedSummary || !detailedSummary.hidePriceColumn) {
        sortedKeys.push(
          formatCurrency(summaryOrdersObj[key]["total"], summaryOrdersObj[key])
        );
      }

      if (metafieldsAvailable.length >= 1) {
        for (let i = 0; i < metafieldsAvailable.length; i++) {
          const metafieldObj = metafieldsObj[key].find(
            (elm) => `${elm.namespace}.${elm.key}` === metafieldsAvailable[i]
          );

          sortedKeys.push(metafieldObj ? metafieldObj.value : "");
        }
      }

      sortedKeys.push(summaryOrdersObj[key]["quantity"]);

      if (
        (!detailedSummary || !detailedSummary.hideFulfillmentColumn) &&
        displayItemFullfillableQuantity
      ) {
        sortedKeys.push(summaryOrdersObj[key]["fulfillable_quantity"]);
      }

      return sortedKeys;
    });

    const localDataTableProps = {
      columnContentTypes: [],
      headings: [],
      sortable: [],
    };

    localDataTableProps.columnContentTypes.push("text");
    localDataTableProps.headings.push("Product");
    localDataTableProps.sortable.push(true);

    if (metafieldsAvailable.length >= 1) {
      for (let i = 0; i < metafieldsAvailable.length; i++) {
        localDataTableProps.columnContentTypes.push("text");
        const currentMetafield = metafieldsAvailable[i]

        const metafield = preferences.metafields.find(
          x => x.namespace === currentMetafield.split('.')[0] && x.key === currentMetafield.split('.')[1]
        );
        
        localDataTableProps.headings.push(metafield?.description || currentMetafield);
      
        localDataTableProps.sortable.push(false);
      }
    }

    if (!detailedSummary || !detailedSummary.hidePriceColumn) {
      localDataTableProps.columnContentTypes.push("numeric");
      localDataTableProps.headings.push("Total");
      localDataTableProps.sortable.push(true);
    }

    localDataTableProps.columnContentTypes.push("numeric");
    localDataTableProps.headings.push("Quantity");
    localDataTableProps.sortable.push(true);
    if (
      (!detailedSummary || !detailedSummary.hideFulfillmentColumn) &&
      displayItemFullfillableQuantity
    ) {
      localDataTableProps.columnContentTypes.push("numeric");
      localDataTableProps.headings.push("Fulfillable");
      localDataTableProps.sortable.push(true);
    }

    setDataTableProps(localDataTableProps);

    setSortedRows(localSortedOrdersKeys, 0, "descending", localDataTableProps);
  }, [orders, displayItemFullfillableQuantity, detailedSummary, preferences]);

  const formatCurrency = (number, order) => {
    if (
      number >= 0 &&
      order !== undefined &&
      order.currency != null &&
      order.currency.length > 0
    )
      return (
        <CurrencyFormatter
          price={number}
          curr={order.currency}
          locale={order.locale}
        />
      );
    else return number;
  };

  const sort = (rows, index, direction, dataTableProps) => {
    const column = dataTableProps ? dataTableProps.headings[index] : "Product";

    switch (column) {
      case "Product":
        return [...rows].sort((a, b) => {
          if (direction === "descending") {
            if (a[index].toUpperCase() < b[index].toUpperCase()) return -1;
            if (a[index].toUpperCase() > b[index].toUpperCase()) return 1;
          } else {
            if (a[index].toUpperCase() < b[index].toUpperCase()) return 1;
            if (a[index].toUpperCase() > b[index].toUpperCase()) return -1;
          }
          return 0;
        });
      case "Total":
        return [...rows].sort((rowA, rowB) => {
          const amountA = parseFloat(rowA[index].props.price);
          const amountB = parseFloat(rowB[index].props.price);

          return direction === "descending"
            ? amountB - amountA
            : amountA - amountB;
        });
      case "Quantity":
      case "Fulfillable":
      default:
        return [...rows].sort((rowA, rowB) => {
          const amountA = parseInt(rowA[index]);
          const amountB = parseInt(rowB[index]);

          return direction === "descending"
            ? amountB - amountA
            : amountA - amountB;
        });
    }
  };

  const handleSort = (index, direction) => {
    setSortedRows(sort(sortedRows, index, direction, dataTableProps));
  };

  const rows = sortedRows;

  const datatable = (
    <>
      <div
        style={{
          display: displaySummary ? "block" : "none",
        }}
        className={classNames("", printSummary ? "yesPrint" : "")}
      >
        <Card sectioned title="Summary">
          <DataTable
            columnContentTypes={dataTableProps.columnContentTypes}
            headings={dataTableProps.headings}
            sortable={dataTableProps.sortable}
            defaultSortDirection="descending"
            hideScrollIndicator={false}
            onSort={handleSort}
            rows={rows}
          />
          <div style={{ marginTop: "10px" }} className="noPrint">
            <Link onClick={() => setDisplaySummary(false)}>
              Hide the summary
            </Link>
          </div>
          <div className="printBreak"></div>
        </Card>
      </div>
      <div
        style={{
          display: !displaySummary ? "block" : "none",
        }}
        className="noPrint"
      >
        <Banner key="banner">
          Display the{" "}
          <Link onClick={() => setDisplaySummary(true)}>summary</Link> for this
          list
        </Banner>
      </div>
    </>
  );

  return <div className="detailed-summary">{datatable}</div>;
};

export { DetailedSummary };
