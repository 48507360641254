import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppProvider, TopBar } from "@shopify/polaris";
import { Provider } from "@shopify/app-bridge-react";

import { connect } from "react-redux";

import Packs from "./components/Packs";
import Pack from "./components/Pack";
import queryString from "query-string";
import Bundle from "./components/Bundle";
import Pick from "./components/Pick";
import Account from "./components/account/Account";
import Preferences from "./components/settings/Preferences";
import Configuration from "./components/settings/Configuration";
import CustomLists from "./components/settings/CustomLists";
import ColorSku from "./components/ColorSku";
import Promotion from "./components/Promotion";
import Promotions from "./components/Promotions";
import Privacy from "./components/Privacy";
import Contact from "./components/account/Contact";
import { fetchShop, impersonate } from "./actions";
import Exports from "./components/settings/Exports";

const customHistory = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);
        this.toggleIsUserMenuOpen = this.toggleIsUserMenuOpen.bind(this);
        this.props.fetchShop();
    }

    theme = {
        colors: {
            topBar: {
                background: "#ffce55",
            },
        },
        logo: {
            width: 54,
            topBarSource: "/images/ppp.png",
            url: `/${window.location.search}`,
            accessibilityLabel: "Pick, Pack & Promo",
        },
    };

    state = {
        shop: "",
        isUserMenuOpen: false,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.shop !== nextProps.shop) {
            this.setState({ shop: nextProps.shop });
        }
    }

    toggleIsUserMenuOpen() {
        this.setState({ isUserMenuOpen: !this.state.isUserMenuOpen });
    }

    componentDidMount() {
        if (window.analytics != null) window.analytics.page();
    }

    render() {
        const userMenuMarkup = (
            <TopBar.UserMenu
                avatar={false}
                actions={[
                    {
                        items: [
                            {
                                content: "Exit impersonation",
                                onAction: () => {
                                    this.props.impersonate("");
                                },
                            },
                        ],
                    },
                ]}
                name="Impersonating"
                detail={sessionStorage.impersonatingShopOrigin}
                open={this.state.isUserMenuOpen}
                onToggle={this.toggleIsUserMenuOpen}
            />
        );

        if (this.state.shop.length === 0) {
            return <p>Loading</p>;
        }
        const queryParams = queryString.parse(window.location.search);
        const config = { apiKey: process.env.REACT_APP_SHOPIFY_KEY, host: queryParams.host, shopOrigin: this.state.shop };
        return (
            <AppProvider theme={this.theme}>
                <Provider config={config}>
                    <div style={{ paddingBottom: "54px" }}>
                        {sessionStorage.impersonating === "true" ? <TopBar userMenu={userMenuMarkup} /> : ""}
                        <Router history={customHistory}>
                            <Switch>
                                <Route path="/pick" component={Pick} />
                                <Route path="/packs" component={Packs} />
                                <Route path="/pack/:id?" component={Pack} />
                                <Route path="/bundle/:id?" component={Bundle} />
                                <Route path="/promotions" component={Promotions} />
                                <Route path="/promotion/:id?" component={Promotion} />
                                <Route path="/preferences" component={Preferences} />
                                <Route path="/exports" component={Exports} />
                                <Route path="/configuration" component={Configuration} />
                                <Route path="/custom_lists" component={CustomLists} />
                                <Route path="/color_sku/:id?" component={ColorSku} />
                                <Route path="/account" component={Account} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/contact" component={Contact} />
                                <Redirect from="/" to={`/pick${window.location.search}`} />
                            </Switch>
                        </Router>
                    </div>
                </Provider>
            </AppProvider>
        );
    }
}

function mapStateToProps(state) {
    return { shop: state.shop };
}

export default connect(mapStateToProps, { fetchShop, impersonate })(App);
