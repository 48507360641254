import React, { Component } from 'react';
import { Page, Card, Button, Select } from '@shopify/polaris';
import { Context } from '@shopify/app-bridge-react';
import { connect } from 'react-redux'
import * as actions from '../../actions';
import { Redirect as RouterRedirect } from 'react-router'
import { helpers } from '../../helpers'

class Configuration extends Component {

    state = {
        redirect: false,
        redirectHome: false,
        working: false,
        impersonateShop: ''
    }

    constructor(props) {
        super(props);
        if (helpers.isAdmin()) props.fetchShops();
        props.fetchPlan();
    }

    static contextType = Context;

    handleImpersonateShopChange = impersonateShop => this.setState({ impersonateShop });

    async handleSave() {
        await this.props.impersonate(this.state.impersonateShop);
    }

    render() {
        if (this.state.redirect) {
            return (
                <RouterRedirect to={`/account${window.location.search}`} />
            )
        }
        if (this.state.redirectHome) {
            return (
                <RouterRedirect to={`/${window.location.search}`} />
            )
        }
        return (
            <Page title="Configuration"
                breadcrumbs={[{ content: 'Pick', url: `/pick${window.location.search}` }]}>
                <div className="settings-items">
                    <div className="item">
                        <Card
                            title="Display options"
                            secondaryFooterActions={[{ content: 'Configure', url: `/preferences${window.location.search}` }]}>
                            <Card.Section>
                                Set the pick list up to work best to suit your workflow.
                            </Card.Section>
                        </Card>
                        <Card
                            title="Custom pick lists"
                            secondaryFooterActions={[{ disabled: this.props.plan === 'goods', content: 'Manage', url: `/custom_lists${window.location.search}` }]}>
                            <Card.Section>
                                Create custom pick lists for segmenting your lists of orders either manually or automatically.
                                {this.props.plan === 'goods' ? <div ><br />Custom pick lists not available on your current plan, <Button onClick={() => this.setState({ redirect: true })} plain monochrome>upgrade plan</Button> to access this feature.</div> : ''}
                            </Card.Section>
                        </Card>
                        <Card
                            title="Color list SKU identification"
                            secondaryFooterActions={[{ content: 'Manage', url: `/color_sku${window.location.search}` }]}>
                            <Card.Section>
                                Make easier to identify your preferred SKUs assigning a color to them.
                            </Card.Section>
                        </Card>
                    </div>
                    <div className="item">
                        <Card
                            title="Packs"
                            secondaryFooterActions={[{ content: 'Manage', url: `/packs${window.location.search}` }]}>
                            <Card.Section>
                                Configure prepacked orders and bundles.
                            </Card.Section>
                        </Card>
                        <Card
                            title="Exports"
                            secondaryFooterActions={[{ disabled: this.props.plan === 'goods' || this.props.plan === 'greats', content: 'Configure', url: `/exports${window.location.search}` }]}>
                            <Card.Section>
                                Configure what information to include in the list exports function.
                                {this.props.plan === 'goods' || this.props.plan === 'greats' ? <div ><br />Exports are not available on your plan, <Button onClick={() => this.setState({ redirect: true })} plain monochrome>upgrade plan</Button> to access this feature.</div> : ''}
                            </Card.Section>
                        </Card>
                    </div>
                    <div className="item">
                        <Card
                            title="Promotions"
                            secondaryFooterActions={[{ content: 'Manage', url: `/promotions${window.location.search}` }]}>
                            <Card.Section>
                                Create rules to add items to orders to engage with your customers.
                            </Card.Section>
                        </Card>
                        {helpers.isAdmin() &&
                            <Card
                                title="Admin tools"
                                secondaryFooterActions={[{ content: 'Impersonate', onClick: () => this.handleSave() }]}>
                                <Card.Section>
                                    <p>Impersonate Shopify store</p>
                                    <Select
                                        label="Date range"
                                        options={this.props.shops != null ? [{ label: 'No shop impersonated', value: '' }, ...this.props.shops] : [{ label: 'Loading shops', value: '' }]}
                                        onChange={this.handleImpersonateShopChange}
                                        value={this.state.impersonateShop}
                                    />
                                </Card.Section>
                            </Card>
                        }
                    </div>
                </div>
            </Page>
        );
    }
}
const mapStateToProps = ({ plan, shops }) => {
    return {
        shops,
        plan
    };
};

export default connect(mapStateToProps, actions)(Configuration);
