import { isFeatureAvailableForPlan, getUnavailableMessage } from '../../helpers/features';

const getRuleDescription = (type, rule, dayRule, specification, state) => {
	const desc = 'Orders will appear in this list if ';
	let expandedDesc = '';
	switch (type) {
		case 'all':
			expandedDesc = `paid and unfulfilled`;
			break;
		case 'date':
			switch (rule) {
				case 'today':
				case 'tomorrow':
					expandedDesc = `due for fulfillment ${dateRules.find(x => x.value === rule).label}`;
					break;
				case 'next_week':
					expandedDesc = `due for fulfillment over the ${dateRules.find(x => x.value === rule).label}`;
					break;
				case 'yesterday':
					expandedDesc = `was placed ${dateRules.find(x => x.value === rule).label}`;
					break;
				case 'last_week':
					expandedDesc = `was placed over the ${dateRules.find(x => x.value === rule).label}`;
					break;
				case 'date':
					const orderDate = `${state.selectedDates.start.getFullYear()}-${
						state.selectedDates.start.getMonth() + 1
					}-${state.selectedDates.start.getDate()}`;
					expandedDesc = `was placed on ${orderDate}`;
					break;
				case 'range_days':
					const startDate = `${state.selectedDates.start.getFullYear()}-${
						state.selectedDates.start.getMonth() + 1
					}-${state.selectedDates.start.getDate()}`;
					const endDate = `${state.selectedDates.end.getFullYear()}-${
						state.selectedDates.end.getMonth() + 1
					}-${state.selectedDates.end.getDate()}`;
					expandedDesc = `was placed over the ${
						dateRules.find(x => x.value === rule).label
					} from ${startDate} to ${endDate}`;
					break;
				case 'last_month':
				case 'current_month':
				case 'next_month':
					expandedDesc = `was placed over the ${dateRules.find(x => x.value === rule).label}`;
					break;
				case 'day_week':
					expandedDesc = `due for fulfillment on ${dayWeekRule.find(x => x.value === dayRule).label}`;
					break;
				default:
					break;
			}
			break;

		case 'financial_status':
			expandedDesc = `Payment Status is "${financialRules.find(x => x.value === rule).label}"`;
			break;

		case 'location':
			expandedDesc = `delivery ${locationRules.find(x => x.value === rule).label} is ${specification}`;
			break;

		case 'fulfillment_location':
			expandedDesc = `${fulfillmentLocationRules.find(x => x.value === rule).label} location named ${specification}`;
			break;

		case 'tags':
			expandedDesc = `${tagRules.find(x => x.value === rule).label} ${specification}`;
			break;

		case 'property':
			expandedDesc = ` order ${propertyRules.find(x => x.value === rule).label} "${
				specification.split(':')[0]
			}" with a value of "${specification.split(':')[1]}"`;
			break;

		case 'order_sku':
			expandedDesc = ` order ${orderSkuRules.find(x => x.value === rule).label} "${specification}"`;
			break;

		case 'metafields':
			expandedDesc = ` ${metafieldRules.find(x => x.value === rule).label} "${specification.split(':')[0]}" with the value "${
				specification.split(':')[1]
			}"`;
			break;
		case 'vendor':
			expandedDesc = ` ${vendorRules.find(x => x.value === rule).label} "${specification}"`;
			break;
		default:
			break;
	}
	return `${desc}${expandedDesc}`;
};

const ruleTypes = props => {
  const rules = [
		{ label: 'Date', value: 'date' },
		{ label: 'Delivery Address', value: 'location' },
		{
			label: `Fulfillment Location${
				!isFeatureAvailableForPlan(props.plan, 'locationsSupport') ? ` - ${getUnavailableMessage('nutsOnly')}` : ''
			}`,
			value: 'fulfillment_location',
			disabled: !isFeatureAvailableForPlan(props.plan, 'locationsSupport'),
		},
		{ label: 'Order Products', value: 'order_sku' },
		{ label: 'Payment Status', value: 'financial_status' },
		{ label: 'Vendors', value: 'vendor' }
	];

  if(props.preferences.productSupport && props.preferences.metafields && props.preferences.metafields.length>=1){
    rules.push(	{
			label: `Product Metafields${
				!isFeatureAvailableForPlan(props.plan, 'productSupport') ? ` - ${getUnavailableMessage('goodsOnly')}` : ''
			}`,
			value: 'metafields',
		})
  }
  
  rules.push({ label: 'Properties', value: 'property' });
	rules.push({ label: 'Tags', value: 'tags' });
	rules.push({ label: 'Display all open orders', value: 'all' });

	return rules
};

const orderSkuRules = [
	{ label: 'Contains SKU', info: 'Orders which contains SKU', help: 'Enter single sku or comma separated', value: 'contain_sku' },
	{
		label: 'Only contains SKU',
		info: 'Orders which contains ONLY SKU',
		help: 'Enter single sku or comma separated',
		value: 'only_sku',
	},
	{
		label: 'Does not contain SKU',
		info: 'Orders which does not contain SKU',
		help: 'Enter single sku or comma separated',
		value: 'no_sku',
	},
	{
		label: 'Contains product named',
		info: 'Orders which contains products matching name',
		help: 'Enter single product name, or comma separated',
		value: 'contain_product',
	},
	{
		label: 'Contains product partially named',
		info: 'Orders which contains products partially matching name',
		help: 'Enter part of product name, or comma separated',
		value: 'contain_partially_product',
	},
];

const dateRules = [
	{ label: 'Today', info: 'Display orders with todays date', value: 'today' },
	{ label: 'Yesterday', info: 'Orders with yesterdays date', value: 'yesterday' },
	{
		label: 'Tomorrow',
		info: 'Orders with tomorrows date, this requires having set up an alternative date in pick list preferences',
		value: 'tomorrow',
	},
	{ label: 'Day of the week', info: 'Display orders for a specific day of the week', value: 'day_week' },
	{ label: 'Date', info: 'Orders placed on a particular date', value: 'date' },
	{ label: 'Date Range', info: 'Orders placed over the date range', value: 'range_days' },
	{ label: 'Last week', info: 'Orders placed over the last seven days', value: 'last_week' },
	{
		label: 'Coming week',
		info: 'Orders for the coming seven days, this requires having set up an alternative date in pick list preferences',
		value: 'next_week',
	},
	{ label: 'Last month', info: 'Orders placed over the last month', value: 'last_month' },
	{ label: 'Current month', info: 'Orders placed over the current month', value: 'current_month' },
	{ label: 'Next month', info: 'Orders placed over the next month', value: 'next_month' },
];

const dayWeekRule = [
	{ label: 'Monday', info: 'Orders placed for Monday', value: 'Monday' },
	{ label: 'Tuesday', info: 'Orders placed for Tuesday', value: 'Tuesday' },
	{ label: 'Wednesday', info: 'Orders placed for Wednesday', value: 'Wednesday' },
	{ label: 'Thursday', info: 'Orders placed for Thursday', value: 'Thursday' },
	{ label: 'Friday', info: 'Orders placed for Friday', value: 'Friday' },
	{ label: 'Saturday', info: 'Orders placed for Saturday', value: 'Saturday' },
	{ label: 'Sunday', info: 'Orders placed for Sunday', value: 'Sunday' },
];

const locationRules = [
	{
		label: 'Country',
		info: 'Filter orders for delivery in one or more countries',
		help: 'Enter single value or comma separated',
		value: 'country',
	},
	{ label: 'State', info: 'Orders for one or more states', help: 'Enter single value or comma separated', value: 'state' },
	{ label: 'City', info: 'Orders for one or more cities', help: 'Enter single value or comma separated', value: 'city' },
	{
		label: 'Zip code',
		info: 'Filter orders for zip codes',
		help: 'Enter single value, comma separated or a range separated with hyphen (ie 2344-2356)',
		value: 'zip',
	},
];

const fulfillmentLocationRules = [
	{
		label: 'All line items fulfilled are from',
		info: 'Filter orders where all line items are fulfilled from location, Note locations must be enabled in the display preferences for this option to work',
		help: 'Enter single value',
		value: 'all_lineitems',
	},
	{
		label: 'Any line items fulfilled are from',
		info: 'Filter orders where any line items are fulfilled from location, Note locations must be enabled in the display preferences for this option to work',
		help: 'Enter single value',
		value: 'any_lineitems',
	},
];

const tagRules = [
	{
		label: 'Has customer tag',
		info: 'Check for the presence of customer tag(s)',
		help: 'Enter single value or comma separated',
		value: 'customer',
	},
	{
		label: 'Has order tag',
		info: 'Check for the presence of order tag(s)',
		help: 'Enter single value or comma separated',
		value: 'order',
	},
	{
		label: 'Has product tag',
		info: 'Check for the presence of product tag(s)',
		help: 'Enter single value or comma separated',
		value: 'product',
	},
	{
		label: "Doesn't have customer tag",
		info: 'Check for the absence of customer tag(s)',
		help: 'Enter single value or comma separated',
		value: 'not_customer',
	},
	{
		label: "Doesn't have order tag",
		info: 'Check for the absence of order tag(s)',
		help: 'Enter single value or comma separated',
		value: 'not_order',
	},
	{
		label: "Doesn't have product tag",
		info: 'Check for the absence of product tag(s)',
		help: 'Enter single value or comma separated',
		value: 'not_product',
	}
];

const propertyRules = [
	{
		label: 'has order property',
		info: 'Check for the presence of a specific property on the order',
		help: 'Provide the name of the property',
		valuehelp: 'Value of the selected property',
		value: 'order',
	},
	{
		label: "doesn't have order property",
		info: 'Check for the absence of a specific order property',
		help: 'Provide the name of the property',
		valuehelp: 'Value of the selected property',
		value: 'not_order',
	},
];

const metafieldRules = [
	{
		label: 'ALL line item products contain metafield',
		info: 'Orders which all line items contain the metafield',
		help: 'The value of the selected metafield',
		value: 'only_metafield',
	},
	{
		label: 'ANY line item products contain metafield',
		info: 'Orders which any of the line items contain the metafield',
		help: 'The value of the selected metafield',
		value: 'any_metafield',
	},
];

const financialRules = [
	{ label: 'Paid', info: 'Paid', value: 'paid' },
	{ label: 'Partially Paid', info: 'Partially Paid', value: 'partially_paid' },
	{ label: 'Partially Refunded', info: 'Partially Refunded', value: 'partially_refunded' },
	{ label: 'Pending', info: 'Pending', value: 'pending' },
];

const vendorRules = [
	{ label: 'All line items are supplied by', value: 'all_items_supplied_by' },
	{ label: 'Any line items are supplied by', value: 'any_items_supplied_by' },
	{ label: 'No line items are supplied by', value: 'no_items_supplied_by' }
];

export {
	financialRules,
	metafieldRules,
	propertyRules,
	tagRules,
	fulfillmentLocationRules,
	locationRules,
	dayWeekRule,
	dateRules,
	orderSkuRules,
	ruleTypes,
	getRuleDescription,
	vendorRules
};
