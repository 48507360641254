import { FETCH_SHOP, FETCH_PLAN, FETCH_SUBSCRIBE_URL, FETCH_ERROR, FETCH_SCOPE, FETCH_SUPPORT } from "./types";

import queryString from "query-string";
import { ApiService } from "../helpers/api.service";

const getShopOrigin = () => {
    const queryParams = queryString.parse(window.location.search);
    const testshop = process.env.REACT_APP_TEST_SHOP;

    const shopOrigin = queryParams.shop != null ? queryParams.shop : queryParams.test ? testshop : sessionStorage.shopOrigin;

    if (shopOrigin == null) throw new Error("Shop not found");

    /*
     * (Re)cache the shop origin in sessionStorage in case the user refreshes
     * on a page where the shop is unavailable as a query string parameter.
     */
    try {
        sessionStorage.shopOrigin = shopOrigin;
        if (queryParams.hmac !== undefined) {
            sessionStorage.hmac = queryParams.hmac;
            sessionStorage.query = window.location.search.substr(1);
        }
        if (window.analytics != null) window.analytics.identify(shopOrigin);
    } catch (error) {
        console.error("Couldnt set session storage");
    }

    return shopOrigin;
};

export const fetchShop = () => async (dispatch) => {
    let shopOrigin = getShopOrigin();
    dispatch({
        type: FETCH_SHOP,
        payload: shopOrigin,
    });
};

export const fetchSubscriptionUrl = (plan) => async (dispatch) => {
    const service = new ApiService();
    try {
        const response = await service.get("subscribe", { shop: getShopOrigin(), plan });
        dispatch({
            type: FETCH_SUBSCRIBE_URL,
            payload: response,
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: FETCH_ERROR,
            payload: { message: error },
        });
    }
};

export const fetchPlan = () => async (dispatch) => {
    const service = new ApiService();
    try {
        const response = await service.get("plan", { shop: getShopOrigin() });

        dispatch({
            type: FETCH_PLAN,
            payload: response,
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: FETCH_ERROR,
            payload: { message: error },
        });
    }
};

export const fetchScope = () => async (dispatch) => {
    const service = new ApiService();
    try {
        const response = await service.get("scope", { shop: getShopOrigin() });
        dispatch({
            type: FETCH_SCOPE,
            payload: response,
        });
    } catch (error) {
        console.error(error);
        dispatch({
            type: FETCH_ERROR,
            payload: { message: error },
        });
    }
};
